import React, {useState, useEffect, useRef} from 'react';
import {HashRouter, Routes, Route} from "react-router-dom";
import TopPage from './Head/TopPage';
import LoadingPage from './Home/LoadingPage';
import ProfilPage from './Profile/ProfilPage';
import ContactPage from './Contact/ContactPage';
import AVRPage from './AVR/AVRPage';
import ATcmdPage from './ATcommand/ATcmdPage';
import LiveChatPage from './LiveChat/LiveChatPage';
import DynamicMapPage from './DynamicMap/DynamicMapPage';
import HomePage from './Home/HomePage';
import changePage from './functions/changePage';
import cardData from './functions/cardData';
import sendNavigationInfo from './functions/sendNavigationInfo';
import './App.css';
import './styles/loadingPage.css';
import './styles/menu_1.css';

function App() {
  const url = 'http://localhost:3000';
  const imageUrl = 'https://servtome.ddns.net/full_stack_api/images/';
  const downloadUrl = 'https://servtome.ddns.net/full_stack_api/download/';
  const videoUrl = 'https://servtome.ddns.net/full_stack_api/videos/';
  const homePageData = cardData(url, imageUrl);
  const [loadingPage, setLoadingPage] = useState(false);
  const [menuBut, setMenuBut] = useState('pagePython');
  const [activePage, setActivePage] = useState('home');
  const [profilePage, setProfilePage] = ('pagePython');
  const background_move = useRef({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    sendNavigationInfo('openpage_portfolio_' + activePage);
    setLoadingPage(true);
    
    return;
  }, [activePage])
  function logMousePosition(event) {
    background_move.current.x = 5 * event.clientX / 1500;
    background_move.current.y = 10 * event.clientY / 900 - 5;
    var backImg = document.querySelector(".background_app");
    backImg.style = 
      'left: calc(' + background_move.current.x + 'vw - 5vw);' +
      'top: calc(' + background_move.current.y + 'vh + 5vh);';
  }

  return (
    <div className="App" onMouseMove={(e) => logMousePosition(e)}>
      <div className="background_app"></div>
      <LoadingPage loadingPage={loadingPage} />
      <HashRouter>
        <TopPage 
          url={url}
          activePage={activePage}
          setActivePage={setActivePage}
          changePage={changePage}
          imageUrl={imageUrl} />
        <Routes>
          <Route
            path="/"
            element={
              <HomePage
                homePageData={homePageData}
                setActivePage={setActivePage}
                changePage={changePage} />
              } />
          <Route
            path="/AVR-FLASHER"
            element={
              <AVRPage 
                imageUrl={imageUrl}
                downloadUrl={downloadUrl} />
              } />
          <Route
            path="/profil"
            element={
              <ProfilPage
                imageUrl={imageUrl}  
                menuBut={menuBut}
                setMenuBut={setMenuBut}
                profilePage={profilePage}
                setProfilePage={setProfilePage} />
              }/>
          <Route
            path="/contact"
            element={
              <ContactPage
                imageUrl={imageUrl}  
                menuBut={menuBut}
                setActivePage={setActivePage}
                profilePage={profilePage}
                setProfilePage={setProfilePage} />
              }/>
          <Route
            path="/AT-MANAGER"
            element={
              <ATcmdPage
                downloadUrl={downloadUrl} 
                imageUrl={imageUrl}
                videoUrl={videoUrl}
                menuBut={menuBut}
                setMenuBut={setMenuBut}/>
              }/>
          <Route
            path="/LiveChat"
            element={
              <LiveChatPage
                downloadUrl={downloadUrl} 
                imageUrl={imageUrl}
                videoUrl={videoUrl}
                menuBut={menuBut}
                setMenuBut={setMenuBut} />
              }/>
          <Route
            path="/dynamicMap"
            element={
              <DynamicMapPage
                downloadUrl={downloadUrl} 
                imageUrl={imageUrl}
                videoUrl={videoUrl}
                menuBut={menuBut}
                setMenuBut={setMenuBut} />
              }/>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
