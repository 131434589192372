import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import sendNavigationInfo from '../functions/sendNavigationInfo';
import './contactStyle.css';

export default function ContactPage(props) {
    useEffect(() => {
        setDispValidation(false);
        return;
    }, [])
    const [emailContent, setEmailContent] = useState({
        'firstname': '',
        'lastname': '',
        'company': '',
        'email': '',
        'phone': '',
        'comment': ''
    });
    const [dispValidation, setDispValidation] = useState(false);
    const controlText = inputText => {
        var surText = inputText
            .replace(/&/g, "")
            .replace(/\$/g, "")
            .replace(/\|/g, "")
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/;/g, "")
            .replace(/"/g, "")
            .replace(/{/g, "")
            .replace(/}/g, "")
            .replace(/\[/g, "")
            .replace(/\]/g, "")
        return surText;
    };
    const onChangeInput = () => {
        setEmailContent({
            'firstname': controlText(document.querySelector('#firstname').value),
            'lastname': controlText(document.querySelector('#lastname').value),
            'company': controlText(document.querySelector('#company').value),
            'email': controlText(document.querySelector('#email').value),
            'phone': controlText(document.querySelector('#phone').value),
            'comment': controlText(document.querySelector('#comment').value),
        })
    };
    const sendContactElement = () => {
        if (emailContent.firstname !== ''
            && emailContent.lastname !== ''
            && emailContent.company !== ''
            && emailContent.email !== ''
            && emailContent.comment !== '') {

                sendNavigationInfo(
                    'sendEmail[firstname:' + emailContent.firstname + ';' + 
                    'lastname:' + emailContent.lastname + ';' + 
                    'company:' + emailContent.company + ';' + 
                    'email:' + emailContent.email + ';' + 
                    'comment:' + emailContent.comment + ';]' 
                );

                var xhttpMsg = new XMLHttpRequest();
                xhttpMsg.open("POST", "https://servtome.ddns.net/YZYapi/api-action.php?scal_MAS=messageSent");
                xhttpMsg.send(JSON.stringify(emailContent));

                document.querySelector('#firstname').value = '';
                document.querySelector('#lastname').value = '';
                document.querySelector('#company').value = '';
                document.querySelector('#email').value = '';
                document.querySelector('#phone').value = '';
                document.querySelector('#comment').value = '';
                setEmailContent({
                    'firstname': '',
                    'lastname': '',
                    'company': '',
                    'email': '',
                    'phone': '',
                    'comment': '',
                })
                setDispValidation(true);
                setTimeout(() => {
                    props.setActivePage('home');
                    window.location.href = '/';
                }, 2000);
        }
    };
  return (
    <article className="selectedContainer centrCenter noselect mask" id="contactView">
      <div className="selectedContent">
        <div className="title centrCenter">
          <h2>CONTACT</h2>
        </div>
        <div id="contact">
            <form>
                <div
                    className="returnToHome"
                    onClick={() => {
                        props.setActivePage('home');
                    }}>
                <Link to="/" style={{textDecoration: 'none', color: 'red'}}>Retour</Link>
                </div>
                {dispValidation && (
                    <div className="confirmationMessage">
                        <span>Merci, votre messsage est bien envoyé.</span>
                    </div>
                )}
                {!dispValidation && (
                    <>
                    <div className="phoneNb">
                        <span>06 87 24 12 19</span>
                    </div>
                        
                        <div className="inputBox">
                            <span style={{color: 'black'}}>Votre prénom<span style={{color: 'red'}}> *</span></span>
                            <input type="text" name="firstname" id="firstname" size="20" maxLength="40" onChange={() => onChangeInput()} required/>
                        </div>
                        <div className="inputBox">
                            <span style={{color: 'black'}}>Votre nom<span style={{color: 'red'}}> *</span></span>
                            <input type="text" name="lastname" id="lastname" size="20" maxLength="40" onChange={() => onChangeInput()} required/>
                        </div>
                        <div className="inputBox">
                            <span style={{color: 'black'}}>Entreprise<span style={{color: 'red'}}> *</span></span>
                            <input type="text" name="company" id="company" size="20" maxLength="40" onChange={() => onChangeInput()} required/>
                        </div>
                        <div className="inputBox">
                            <span style={{color: 'black'}}>Votre email<span style={{color: 'red'}}> *</span></span>
                            <input type="email" name="email" id="email" size="20" maxLength="120" onChange={() => onChangeInput()} required/>
                        </div>
                        <div className="inputBox">
                            <span style={{color: 'black'}}>Votre Téléphone</span>
                            <input type="tel" name="phone" id="phone" size="20" maxLength="15" onChange={() => onChangeInput()} />
                        </div>
                        {/* <div className="inputBox">
                            <label htmlFor="cgu" id="labCgu">Connaissance des CGU<span style={{color: 'red'}}> *</span></label>
                            <input type="checkbox" id="cgu" name="cgu" required/>
                        </div> */}
                        <br></br>
                        <div className="areaBoxContainer">
                            <textarea className="areaBox" id="comment" name="comment" 
                                rows="4" maxLength="256" placeholder="Votre message?" onChange={() => onChangeInput()}></textarea>
                        </div>
                        <div
                            className="bottomButton_1"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                color: 'red',
                                position: 'relative',
                                left: '35px',
                                // top: '-80px',
                                }}>
                            {/* <span style={{fontSize: '20px', fontWeight: '700', maxWidth: '80vw'}}>La page contact est malheureusement indisponible.</span>
                            <span style={{fontSize: '16px', fontWeight: '700', maxWidth: '80vw'}}>N'hésitze pas à utiliser le numéro de téléphone afficher plus haut...</span>
                            <span style={{fontSize: '16px', fontWeight: '700'}}></span> */}
                        </div>
                        <div className="bottomButton_1">
                            <span style={{fontSize: '12px'}}>Vous recevrez un email de confirmation.</span>
                            <button name="validation" id="subButton" onClick={() => sendContactElement()}>Envoyer</button>
                        </div>
                        <div className="bottomButton_2">
                            <span style={{fontSize: '12px'}}>Vérifiez vos spam ...</span><br/>
                            <span style={{fontSize: '14px', }}>Expéditeur: <i style={{color: 'blue'}}>Pascal C - manager-système</i></span>
                        </div>
                    </>
                )}
            </form>
        </div>
    </div>
</article>
  )
}
