import React from 'react';
import './profileStyle.css';
import sendNavigationInfo from '../functions/sendNavigationInfo';

export default function PageHardware(props) {
    const mobilImages = [
      [
          process.env.PUBLIC_URL + '/images/competence/hardware/hardware_img2.png',
          'Programmation d\'écrans tactiles, design des interfaces utilisateurs',
          'Programmation d\'écrans tactiles',
      ],
      [
          process.env.PUBLIC_URL + '/images/competence/hardware/hardware_img3.png',
          'Programmation d\'équipements médicaux / paramédicaux',
          'Programmation d\'équipements médicaux / paramédicaux',
      ],
      [
          process.env.PUBLIC_URL + '/images/competence/hardware/hardware_img1.jpg',
          'Création de cartes électroniques',
          'Création de cartes électroniques',
      ],
      [
          process.env.PUBLIC_URL + '/images/competence/hardware/hardware_img4_800x368.png',
          'Elaboration d\'algorythmes complexes',
          'Elaboration d\'algorythmes complexes',
      ],
    ];
    return (
      <div className="noselect">
        <div className="centerH2">
          <h2 className="h2Title">Programmation d'équipements embarqués <i>(langage C & Python)</i></h2>
        </div>
        <div className="selectorToolContainer">
          <div onClick={() => {
            props.getPageBefore(mobilImages.length);
            sendNavigationInfo('visit_skills_pagehardware_beforebutton');
            }}>
            {props.icons.arrow_to_left}
          </div>
          <div onClick={() => {
            props.getPageAfter(mobilImages.length);
            sendNavigationInfo('visit_skills_pagehardware_afterbutton');
            }}>
            {props.icons.arrow_to_right}
          </div>
        </div>
        <div className="imageContainer">
          <div className="pythonContent">
            <img
              src={mobilImages[props.staticSelector][0]}
              className="profilImage"
              alt={mobilImages[props.staticSelector][2]} />
          </div>
          <div className="pythonContent description">
            <p>{mobilImages[props.staticSelector][1]}</p>
          </div>
        </div>
      </div>
    )
  }