const cardData = (url, imageUrl) => {
    return {
        item_1: {
            title: 'Projet AVR-FLASHER',
            pageLink: 'avr',
            description: 'AVR-FLASHER est un interface construit avec Python et PyQt. C\'est un outil destiné à flasher des microcontrôleurs ' + 
                'ATMEGA 2560 par le bus SPI en utilisant les commandes de AVRDUDE. Vous trouverez ici le développement complet de cette solution.',
                image_larg: process.env.PUBLIC_URL + '/images/AVR-FLASHER/avrflasher_img_200x200.png',
                image_small: process.env.PUBLIC_URL + '/images/AVR-FLASHER/avrflasher_img_120x120.png',
                image_alt: 'Logo de l\'outil AVR-FLASHER',
            redirection: url + '?page=AVR',
        },
            item_2: {
            title: 'Mes compétences',
            pageLink: 'profile',
            description: 'Découvrez mon profil, les technos que j\'apprécie et mon intérêt pour ce métier.',
            image_larg: process.env.PUBLIC_URL + '/images/competence/cvImage_200x200.png',
            image_small: process.env.PUBLIC_URL + '/images/competence/cvImage_120x120.png',
            image_alt: 'Logo de la page des compétences',
            redirection: url + '?page=profile',
        },
        item_3: {
            title: 'Le site de l\'artiste',
            description: 'Découvrez un modèle de site regroupant les outils de présentation et de management nécessaires à une activité artistique, médicale ou autre. ' +
                'Présentation, galerie photo, booking, itinéraires des rendez-vous et bien sûr, une page pour les commentaires.',
            image: process.env.PUBLIC_URL + '/images/logo_clown.png',
            image_larg: process.env.PUBLIC_URL + '/images/artistWebsite/logo_clown_200x200.png',
            image_small: process.env.PUBLIC_URL + '/images/artistWebsite/logo_clown_120x120.png',
            image_alt: 'Logo de la page du site de l\'artiste',
            redirection: 'https://servtome.ddns.net/artistwebsite/',
        },
        item_4: {
            title: 'Projet AT-MANAGER',
            pageLink: 'ATcmd',
            description: 'AT-MANAGER est un terminal construit avec Python et PyQt. C\'est un outil adapté pour le paramètrage des modules Bluetooth. ' +
                'Vous trouverez ici le développement complet de cette solution.',
            image_larg: process.env.PUBLIC_URL + '/images/AT-MANAGER/atmanager_img_200x200.png',
            image_small: process.env.PUBLIC_URL + '/images/AT-MANAGER/atmanager_img_120x120.png',
            image_alt: 'Logo de l\'outil AT-MANAGER',
            redirection: url + '?page=ATcmd',
        },
        item_5: {
            title: 'Live chat avec REACTjs',
            pageLink: 'livechat',
            description: 'Assurez la hotline de votre plateforme en intégrant un live chat à vos applications React. ' +
                'Ici une solution simple et rapide à mettre en oeuvre.',
            image_larg: process.env.PUBLIC_URL + '/images/livechat/livechat_img_200x200.png',
            image_small: process.env.PUBLIC_URL + '/images/livechat/livechat_img_120x120.png',
            image_alt: 'Logo du livechat',
            redirection: url + '?page=livechat',
        },
        item_6: {
            title: 'Carte interactive',
            pageLink: 'dynamicMap',
            description: 'Une carte interactive permet de présenter ses points de vente et leurs répartitions sur le territoire. ' +
                'Voici un exemple.',
            image_larg: process.env.PUBLIC_URL + '/images/carteInteractive/dynamicMapLogo_200x200.png',
            image_small: process.env.PUBLIC_URL + '/images/carteInteractive/dynamicMapLogo_120x120.png',
            image_alt: 'Logo de la crate interactive',
            redirection: url + '?page=dynamicMap',
        },
    }
  }

  export default cardData;