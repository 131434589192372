import React from 'react';
import './ATcmdStyle.css';
import '../AVR/AVRStyle.css';

export default function ATcmdPage(props) {
  return (
    <section className="AVRsection">
        <div className="ttHeader" id="ATmanager_questcequecestAT-MANAGER">
            <h2>Qu'est-ce que le projet AT-MANAGER</h2>
        </div>
        <div className="ttText">
            <p className="flash_p">
                <span style={{marginLeft:'30px'}}></span><i>Qui ne s'est jamais amusé avec quelques fils volants, une platine Arduino et un module HC-05 pour paramétrer ce 
                module en commandes AT! On peut se satisfaire de ce genre de montage en considérant le mauvais contact de ces fils et l'équilibre de ce montage sur le coin d'une table.</i><br></br>
                <span style={{marginLeft:'30px'}}></span><i>Lorsqu'on veut changer la vitesse de communication, le test implique de redémarrer un port série avec la vitesse adaptée. 
                Donc on re téléverse notre code de communication et inversement pour le prochain module.</i><br></br><br></br>
            </p>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <img
                    src={process.env.PUBLIC_URL + "/images/AT-MANAGER/filVolant_200x150.png"}
                    style={{width: '200px', maxWidth: '200px'}}
                    alt="Montage d'un module HC-05 sur une carte Arduino en fil volant"/>
            </div>
            <br></br>
            <p>
                <span style={{marginLeft:'30px'}}></span><i>Nous allons voir comment construire un interface graphique adapté pour le paramétrage d'un module HC-05. Des fonctions 
                permettront de consulter les valeurs du module sans aucune saisie clavier. Pour faciliter encore ce processus, nous fabriquerons un connecteur USB adapté pour connecter le module 
                Bluetooth au PC sans avoir besoin d'une platine Arduino.</i><br></br>
                <span style={{marginLeft:'30px'}}></span><i>Nous disposerons d'un process de paramétrage qui aura de loin dépassé le statut d'amateur...</i><br></br><br></br>
            </p>
            <hr></hr>
            <div style={{textAlign: 'center'}}>
                <span style={{marginLeft:'30px'}}></span><i style={{fontSize: '20px'}}>Sont fournis dans ce document:</i>
                <ul style={{listStyle: 'none'}}>
                    <li><a href={process.env.PUBLIC_URL + "/download/AT-MANAGER_3D.zip"} target="_blank" rel="noreferrer"><i>Les fichiers 3D de la prise CP2102 & support module</i></a></li><br></br>
                    <li><a href={process.env.PUBLIC_URL + "/download/AT-MANAGER/AT-MANAGER.zip"} target="_blank" rel="noreferrer"><i>L'exécutable AT-MANAGER</i></a></li><br></br>
                    <li><a href="https://github.com/PasccalFullStack/AT-MANAGER" target="_blank" rel="noreferrer" style={{color: 'blue'}}><i>Le lien dépôt public sur GITHUB</i></a></li><br></br>
                </ul>
            </div>
            <hr></hr>
        </div>
        <div className="ttHeader" id="ATmanager_pourquoiutiliseruneplatinearduino">
            <h2>Pourquoi utiliser une platine Arduino</h2>
        </div>
        <div className="ttText">
            <div className="shadowHorizontalSpace">
                <div className="insertImageLeft">
                    <img
                        src={process.env.PUBLIC_URL + "/images/AT-MANAGER/atmanager_img_120x120.png"}
                        alt="Logo de l'outil AT-MANAGER"/>
                </div>
                <div>
                    <br></br>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Le transfert d'informations via un port USB ne se fait pas de la même façon que sur un port série. 
                        Une platine Arduino est équipée d'un convertisseur TTL qui permet de transformer le signal USB en signal de type UART exploitable par les 
                        microcontrôleurs.<br></br>
                    </p>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Lorsqu'on utilise une platine Arduino pour paramétrer un module Bluetooth, le seul intérêt de 
                        celle-ci est la présence de son convertisseur TTL. Le plus souvent, les platines Arduino sont équipée d'un CH340.
                    </p>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Si on utilise uniquement un convertisseur TTL, on peut faire le même travail qu'avec la platine Arduino.
                    </p>
                    <br></br>
                </div>
            </div>
          <div className="ttHeader" id="ATmanager_lesbasespourcomprendre">
              <h2>Quelles bases faut-il avoir pour comprendre ce tutoriel?</h2>
          </div>
          <div className="ttText">
              <p className="flash_p">
                  <span style={{marginLeft:'30px'}}></span>Ce tutoriel s'adresse à un public initié sur l'IDE Arduino, sachant écrire un code fonctionnel 
                  et régler les paramètres pour le téléverser sur une carte Arduino.<br></br><br></br>
                  <span style={{marginLeft:'30px'}}></span>Avoir déjà paramétré un module Bluetooth est un plus.<br></br><br></br>
                  <span style={{marginLeft:'30px'}}></span>Il sera également nécessaire d'avoir de bonnes bases en langage Python et savoir utiliser l'outil 
                  PIP.<br></br><br></br>
                  <span style={{marginLeft:'30px'}}></span>Quelques connaissances de la très connue librairie PyQt sont avantageuses.<br></br><br></br>
                  <span style={{marginLeft:'30px'}}></span>Enfin, être intéressé par la fabrication d'outils adaptés aussi bien au paramétrage qu'aux tests.<br></br><br></br>
              </p>
          </div>
          <div className="ttHeader" id="ATmanager_commentutiliserunconvertisseurTTL">
              <h2>Comment utiliser un convertisseur TTL</h2>
          </div>
          <div className="shadowHorizontalSpace">
              <div>
                  <br></br>
                  <p className="flash_p">
                      <span style={{marginLeft:'30px'}}></span>Plusieurs convertisseurs TTL existent et on les trouve déjà montés sur des platines avec une prise USB mâle.
                  </p>
                  <ul>
                    <li className="flash_p"><b>FT232RL - FTDI</b> : 2000000 bauds max, attention au copies, elles fonctionnent moins bien</li>
                    <li className="flash_p"><b>CH340 - WCH</b> : 2000000 bauds max, très répandue sur les platines Arduino</li>
                    <li className="flash_p"><b>CP2102 - SILABS</b>  : 2000000 bauds max, un très bon choix</li>
                  </ul>
                  <p className="flash_p">
                      <span style={{marginLeft:'30px'}}></span>C'est donc le CP2102 qu'on utilisera, il y avait des problèmes de 
                      <span> </span>
                      <a href="https://www.silabs.com/developers/usb-to-uart-bridge-vcp-drivers?tab=downloads" target="_blank" rel="noreferrer"><b>pilote</b></a> 
                      <span> </span>
                      à ses débuts, mais c'est un problème résolu aujourd'hui. Ci-dessous un CP2102 que j'ai commandé pour quelques pièces sur une plateforme web 
                      connue avec un boitier fabriqué pour ce tutoriel. Le CP2102 est équipé d'un voyant LED qui indique la communication en cours, 
                      le boitier en PETG transparent permettra de visualiser ce voyant.
                  </p>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/AT-MANAGER/CP2102_380x285.png"}
                        style={{width: '90vw', maxWidth: '380px'}}
                        alt="Convertisseur CP2102 et le boitier en PETG translucide créé pour cet article"/>
                  </div>
                  <p className="flash_p">
                      <span style={{marginLeft:'30px'}}></span>Pour faire simple, lorsqu'on branche un convertisseur TTL dans un port USB d'un PC, 
                      on peut connecter directement Rx et Tx du convertisseur au Tx et Rx d'un microcontrôleur sans oublier de mettre les masses en commun. 
                      Ainsi, vous pouvez communiquer depuis le PC avec votre montage à travers un terminal port série. On trouve plusieurs terminaux 
                      série gratuit sur le web.<br></br>
                      Ici, nous allons concevoir un terminal de communication adapté avec le langage Python.
                  </p>
                  <br></br>
                </div>
            </div>
            <div className="ttHeader" id="ATmanager_commandeAT">
                <h2>Les commandes AT</h2>
            </div>
            <div className="shadowHorizontalSpace">
                <div>
                    <br></br>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Voici venu le temps de parler des commandes AT. Ces commandes permettent de paramétrer les 
                        modules Bluetooth, mais pas que. Les commandes AT sont aussi utilisées pour le paramétrage de divers équipements, pour exemple 
                        les modules GSM, les modules WiFi et bien d'autres.
                    </p>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Il ne faut pas s'attendre à un lexique formel, selon la version ou le fabriquant de 
                        votre module Bluetooth, la syntaxe peut varier. Par exemple, pour changer le code d'appairage d'un HC-05, certains montages répondront à : 
                        "<b>AT+PIN=1234</b>" et d'autres à : "<b>AT+PSWD=1234</b>". De même, certains montages ne répondront que si la fin de la commande est : 
                        "<b>\r\n</b>" alors que d'autres ne répondront pas si on les inscrit. Dans tous les cas, si vous rencontrez un jour des difficultés que 
                        vous n'expliquez pas, n'hésitez pas à parcourir le web à la recherche de syntaxe.
                    </p>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Ci-dessous une liste des commandes que j'utilise le plus souvent, ce sont celles-là que 
                        j'automatiserai dans notre interface.
                    </p>
                    <ul>
                        <li className="flash_p"><b>AT</b> : Demander la confirmation du mode AT</li>
                        <li className="flash_p"><b>AT+NAME?</b> : Demander le nom actuel du module</li>
                        <li className="flash_p"><b>AT+PSWD?</b> : Demander le code d'appairage actuel</li>
                        <li className="flash_p"><b>AT+ADDR?</b> : Demander l'adresse du module</li>
                        <li className="flash_p"><b>AT+VERSION?</b>  : Demander la version du module</li>
                        <li className="flash_p"><b>AT+UART?</b>  : Demander la vitesse de communication actuelle</li>
                    </ul>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>D'autres préfixes de commande sont systématiquement réutilisés, j'automatiserai 
                        leur écriture.
                    </p>
                    <ul>
                        <li className="flash_p"><b>AT+NAME=</b> : Paramétrer le nom du module</li>
                        <li className="flash_p"><b>AT+PSWD=</b> : Paramétrer le code d'appairage</li>
                        <li className="flash_p"><b>AT+UART=</b>  : Paramétrer la vitesse de communication</li>
                    </ul>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Il existe de nombreuses autres commandes, vous les trouverez dans la 
                        <span> </span><a href="https://s3-sa-east-1.amazonaws.com/robocore-lojavirtual/709/HC-05_ATCommandSet.pdf" target="_blank" rel="noreferrer"><b>documentation</b></a><span> </span>.
                    </p>
                    <br></br>
                </div>
            </div>
            <div className="ttHeader" id="ATmanager_boitierconnexionHC05">
                <h2>Un boitier de connexion pour le HC-05</h2>
            </div>
            <div className="shadowHorizontalSpace">
                <div>
                    <br></br>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Avec objectif de faire les choses proprement, j'ai pris le temps de modéliser un boitier 
                        pour connecter le module HC-05. Le module HC-05 nécessite que sa broche notée "<b>EN</b>" soit à la masse pour pouvoir utiliser son mode paramétrage, 
                        le fameux mode AT. 
                        Mais cela ne suffit pas, sur les versions 2 et 3, un micro bouton poussoir a été placé près des broches, après mise sous tension, il faut appuyer dessus 
                        plus de 2 secondes pour passer en mode AT. 

                    </p>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <img
                        src={process.env.PUBLIC_URL + "/images/AT-MANAGER/boitierDeProg_400x400.png"}
                        style={{width: '60vw', maxWidth: '400px'}}
                        alt="Boitier de programmation en 3D"/>
                    </div>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Pour que cela reste confortablement faisable, j'ai logé un interrupteur à levier 3 positions (PROG - OFF - RUN) 
                        pour pouvoir alimenter le module en mode test (sans "EN" à la masse) ou en mode programmation. J'ai également prévu un logement à tarauder M4 pour 
                        utiliser une vis afin de maintenir l'appui sur le micro bouton poussoir.
                    </p>
                </div>
            </div>
            <div className="ttText">
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span><b>Un coup de pouce pour les branchements:</b>
                    <br></br>
                </p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <img
                            src={process.env.PUBLIC_URL + "/images/AT-MANAGER/convTTL_branchement_750x375.png"}
                            style={{width: '80vw', maxWidth: '750px'}}
                            alt="Schéma de branchement de l'ensemble convertisseur et boitier de programmation" />
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Voici notre matériel prêt.
                    <br></br>
                </p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <img
                        src={process.env.PUBLIC_URL + "/images/AT-MANAGER/AT-MANAGER_hardware_400x400.png"}
                        style={{width: '60vw', maxWidth: '400px'}}
                        alt="Ensemble fini et prêt à servir"/>
                </div>
            </div>
            <div className="ttHeader" id="ATmanager_confectionducode">
                <h2>Réalisation de l'interface graphique</h2>
            </div>
            <br></br>
            <div>
                <p style={{textAlign: 'center'}}>
                    <a href="https://github.com/PasccalFullStack/AT-MANAGER" target="_blank" rel="noreferrer" style={{color: 'blue'}}><i>Le lien dépôt public sur GITHUB</i></a>
                </p>
            </div>
            <div>
                <div>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>La base de cet interface est un terminal de communication par port série. 
                        La première fonctionnalité sera de trouver un port COM, sélectionner une vitesse de communication et ouvrir la connexion.
                        <br></br>
                    </p>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Nous aurons besoin de pouvoir saisir des commandes et aussi de lire les retours 
                        du module.
                    </p>
                    <p className="flash_p">
                        <br></br>
                        <span style={{marginLeft:'30px'}}></span>Enfin, pour assurer une rapidité d'exécution de nos paramétrages, l'interface 
                        devra disposer d'une série de boutons pour envoyer des commandes prédéfinies, d'une autre série pour accélérer l'écriture 
                        de textes que nous réutilisons systématiquement. Et pour finir, nous devrons pouvoir sélectionner les conditions de fin de ligne:
                    </p>
                    <ul className="flash_p">
                        <li>"<b>\r</b>" pour le retour chariot</li>
                        <li>"<b>\n</b>" pour le retour à la ligne</li>
                    </ul>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Voici l'interface tel que je l'ai codé. Je vais maintenant en décrire les grandes lignes.
                    </p>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/AT-MANAGER/ATinterface_600x432.png"}
                            style={{width: '60vw', maxWidth: '600px'}}
                            alt="Interface utilisateur du logiciel AT-MANAGER tel que créé ici" />
                    </div>
                    <br></br>
                    <div className="ttHeader" id="ATmanager_confectionducode">
                        <h3>Réalisation de la partie "connexion"</h3>
                    </div>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>La librairie Python qui convient à la communication série est "serial". La méthode "serial.tools.list_ports.comports()" 
                        est pratique pour trouver un port COM, elle renvoie la description des ports COM. Puisque nous utilisons un convertisseur TTL CP2102, 
                        on sait que la description contiendra au minimum la chaine "CP21".
                        <br></br>
                    </p>
                    <br></br>
                </div>
                <div>
                    <div className="dispCode">
                        <code>
                            <span style={{marginLeft:'15px', color: 'lightgreen'}}># Méthode déclenchée suite à l'appui sur START</span><br></br>
                            <span style={{marginLeft:'15px'}}></span>def search_port_com(self):<br></br>
                            <span style={{marginLeft:'30px', color: 'lightgreen'}}># Recherche et lecture des ports COM disponibles</span><br></br>
                            <span style={{marginLeft:'30px'}}></span>for p in serial.tools.list_ports.comports():<br></br>
                            <span style={{marginLeft:'45px', color: 'lightgreen'}}># Recherche de la chaine "CP21"</span><br></br>
                            <span style={{marginLeft:'45px'}}></span>if 'CP21' in p.description:<br></br>
                            <span style={{marginLeft:'60px'}}></span>self.port = p.device<br></br>
                            <span style={{marginLeft:'60px'}}></span>self.connection_status_label.setText("CONNECTED!")<br></br>
                            <span style={{marginLeft:'60px', color: 'lightgreen'}}># Mise à jour de la table graphique</span><br></br>
                            <span style={{marginLeft:'60px'}}></span>self.start_com.setStyleSheet(<br></br>
                            <span style={{marginLeft:'75px'}}></span>'font-size: 14px;'<br></br>
                            <span style={{marginLeft:'75px'}}></span>'color: black;'<br></br>
                            <span style={{marginLeft:'75px'}}></span>'background-color: lightgreen;'<br></br>
                            <span style={{marginLeft:'60px'}}></span>)<br></br>
                            <span style={{marginLeft:'60px'}}></span>self.stop_com.setStyleSheet(<br></br>
                            <span style={{marginLeft:'75px'}}></span>'font-size: 14px;'<br></br>
                            <span style={{marginLeft:'75px'}}></span>'color: black;'<br></br>
                            <span style={{marginLeft:'75px'}}></span>'background-color: grey;'<br></br>
                            <span style={{marginLeft:'60px'}}></span>)<br></br>
                            <span style={{marginLeft:'60px'}}></span>self.connection_status_label.setStyleSheet(<br></br>
                            <span style={{marginLeft:'75px'}}></span>'font-size: 20px;'<br></br>
                            <span style={{marginLeft:'75px'}}></span>'color: lightgreen;'<br></br>
                            <span style={{marginLeft:'75px'}}></span>'background-color: black;'<br></br>
                            <span style={{marginLeft:'60px'}}></span>)
                            <span style={{marginLeft:'60px'}}></span>tp = str(datetime.now())<br></br>
                            <span style={{marginLeft:'60px', color: 'lightgreen'}}># Création d'un message de connexion et affichage dans la boite de reception</span><br></br>
                            <span style={{marginLeft:'60px'}}></span>self.receive_box.setText(<br></br>
                            <span style={{marginLeft:'75px'}}></span>tp[11:-7] + \<br></br>
                            <span style={{marginLeft:'75px'}}></span>" &#8250;&#8250;&#8250; Connected to " + self.port + \<br></br>
                            <span style={{marginLeft:'75px'}}></span>", baudrate = " + str(self.baudrate) + " bauds"<br></br>
                            <span style={{marginLeft:'60px'}}></span>)<br></br>
                            <span style={{marginLeft:'45px'}}></span>else:<br></br>
                            <span style={{marginLeft:'75px', color: 'lightgreen'}}># En cas de non connexion, on initialise la variable self.port</span><br></br>
                            <span style={{marginLeft:'75px'}}></span>self.port = 'NONE'<br></br>
                                    <br></br>
                            <span style={{marginLeft:'30px'}}></span>self.port_com_label.setText(self.port)<br></br>
                        </code>
                    </div>
                    <div>
                        <p className="flash_p">
                            <span style={{marginLeft:'30px'}}></span>Ci-dessous l'état de la table graphique mise à jour après connexion.
                            <br></br>
                        </p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/AT-MANAGER/interfaceConnectionPart_500x133.png"}
                            style={{width: '60vw', maxWidth: '500px'}}
                            alt="Agrandissement de la partie connection de l'interface utilisateur" />
                    </div>
                    <div>
                        <p className="flash_p">
                            <span style={{marginLeft:'30px'}}></span>Ce n'est pas tout. La méthode ci-dessus n'est qu'une partie de la connexion. 
                            On va maintenant voir comment se créé la connexion du port série et l'objet qui nous permettra de lire et d'écrire sur 
                            le port série sélectionné.
                            <br></br>
                        </p>
                    </div>
                    <br></br>
                </div>
                <div>
                    <div className="dispCode">
                        <code>
                            <span style={{marginLeft:'15px', color: 'lightgreen'}}># Méthode déclenchée suite à l'appui sur START</span><br></br>
                            <span style={{marginLeft:'15px'}}></span>def start_loop(self):<br></br>
                            <span style={{marginLeft:'30px', color: 'lightgreen'}}># C'est ici qu'on fait appel à la méthode décrite ci-dessus</span><br></br>
                            <span style={{marginLeft:'30px'}}></span>self.search_port_com()<br></br>
                            <span style={{marginLeft:'30px', color: 'lightgreen'}}># Une fois le port COM déterminé, on peu déclarer la connexion avec "serial.Serial"</span><br></br>
                            <span style={{marginLeft:'30px'}}></span>try:<br></br>
                            <span style={{marginLeft:'45px'}}></span>mytext = "\n"<br></br>
                            <span style={{marginLeft:'45px'}}></span>self.ser = serial.Serial(self.port, self.baudrate, timeout=1)<br></br>
                            <span style={{marginLeft:'45px'}}></span>self.ser.write(mytext.encode())<br></br>
                            <span style={{marginLeft:'30px', color: 'lightgreen'}}># Au cas de non connexion, on émet une alerte à l'aide du composant "messageBox"</span><br></br>
                            <span style={{marginLeft:'30px'}}></span>except:<br></br>
                            <span style={{marginLeft:'45px'}}></span>msgBox = QMessageBox()<br></br>
                            <span style={{marginLeft:'45px'}}></span>msgBox.setWindowTitle("COM Port Error!")<br></br>
                            <span style={{marginLeft:'45px'}}></span>msgBox.setIcon(QMessageBox.Warning)<br></br>
                            <span style={{marginLeft:'45px'}}></span>msgBox.setText("No port COM available")<br></br>
                            <span style={{marginLeft:'45px'}}></span>msgBox.exec()<br></br>
                            <span style={{marginLeft:'45px', color: 'lightgreen'}}># Et on met à jour la table graphique</span><br></br>
                            <span style={{marginLeft:'45px'}}></span>self.connection_status_label.setText("Not Connected")<br></br>
                            <span style={{marginLeft:'45px'}}></span>self.start_com.setStyleSheet(<br></br>
                            <span style={{marginLeft:'60px'}}></span>'font-size: 14px;'<br></br>
                            <span style={{marginLeft:'60px'}}></span>'color: black;'<br></br>
                            <span style={{marginLeft:'60px'}}></span>'background-color: grey;'<br></br>
                            <span style={{marginLeft:'45px'}}></span>)<br></br>
                            <span style={{marginLeft:'45px'}}></span>self.stop_com.setStyleSheet(<br></br>
                            <span style={{marginLeft:'60px'}}></span>'font-size: 14px;'<br></br>
                            <span style={{marginLeft:'60px'}}></span>'color: black;'<br></br>
                            <span style={{marginLeft:'60px'}}></span>'background-color: #F66D62;'<br></br>
                            <span style={{marginLeft:'45px'}}></span>)<br></br>
                            <span style={{marginLeft:'45px'}}></span>self.connection_status_label.setStyleSheet(<br></br>
                            <span style={{marginLeft:'60px'}}></span>'font-size: 20px;'<br></br>
                            <span style={{marginLeft:'60px'}}></span>'color: red;'<br></br>
                            <span style={{marginLeft:'60px'}}></span>'background-color: black;'<br></br>
                            <span style={{marginLeft:'45px'}}></span>)<br></br>
                            <span style={{marginLeft:'45px'}}></span>self.port_com_label.setText('COM ???')<br></br>
                            <span style={{marginLeft:'45px', color: 'lightgreen'}}># En cas de non connexion, le "return" provoquera la fin de la méthode.</span><br></br>
                            <span style={{marginLeft:'45px', color: 'lightgreen'}}># Ainsi, la suite avec la création des threads ne sera exécutée qu'en cas de connexion réussie</span><br></br>
                            <span style={{marginLeft:'45px'}}></span>return<br></br>
                            <br></br>
                            <span style={{marginLeft:'30px', color: 'lightgreen'}}># Après le succès de la connexion, on va instancier la classe</span><br></br>
                            <span style={{marginLeft:'30px', color: 'lightgreen'}}># SerialReader que je décrirai juste après.</span><br></br>
                            <span style={{marginLeft:'30px'}}></span>self.serialReader = SerialReader(self.ser)<br></br>
                            <span style={{marginLeft:'30px', color: 'lightgreen'}}># Grâce à QThread, on pourrat effectuer la lecture du port série en</span><br></br>
                            <span style={{marginLeft:'30px', color: 'lightgreen'}}># parallèle des fonctionnalités de notre interface</span><br></br>
                            <span style={{marginLeft:'30px'}}></span>self.thread = QThread()<br></br>
                            <span style={{marginLeft:'30px'}}></span>self.serialReader.moveToThread(self.thread)<br></br>
                            <span style={{marginLeft:'30px', color: 'lightgreen'}}># C'est la méthode "work" qui gére la lecture du port série</span><br></br>
                            <span style={{marginLeft:'30px'}}></span>self.thread.started.connect(self.serialReader.work)<br></br>
                            <span style={{marginLeft:'30px'}}></span>self.serialReader.intReady.connect(self.emit_data)<br></br>
                            <span style={{marginLeft:'30px'}}></span>self.serialReader.finished.connect(self.thread.quit)<br></br>
                            <span style={{marginLeft:'30px'}}></span>self.serialReader.finished.connect(self.serialReader.deleteLater)<br></br>
                            <span style={{marginLeft:'30px'}}></span>self.thread.finished.connect(self.thread.deleteLater)<br></br>
                            <span style={{marginLeft:'30px'}}></span>self.thread.start()<br></br>
                        </code>
                    </div>
                    <div>
                        <p className="flash_p">
                            <span style={{marginLeft:'30px'}}></span>Je ne détaillerai pas le fonctionnement des threads, ce n'est pas le but de cet article. 
                            Il faut simplement retenir que cela permet d'effectuer la lecture du port série sans impacter le fonctionnement de l'interface par 
                            des temps de latence.
                        </p>
                        <p className="flash_p">
                            <span style={{marginLeft:'30px'}}></span>Voyons maintenant comment coder la lecture du port série avec la classe SerialReader
                        </p>
                    </div>
                </div>
                <div className="dispCode">
                    <code>
                        <span style={{marginLeft:'15px', color: 'lightgreen'}}># Méthode jouée en boucle chargée de la lecture du port série</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>class SerialReader(QObject):<br></br>
                        <span style={{marginLeft:'30px', color: 'lightgreen'}}># Raccordement des signaux</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>finished = pyqtSignal()<br></br>
                        <span style={{marginLeft:'30px'}}></span>intReady = pyqtSignal(str)<br></br>
                        <br></br>
                        <span style={{marginLeft:'30px'}}></span>def __init__(self, ser):<br></br>
                        <span style={{marginLeft:'45px'}}></span>super(SerialReader, self).__init__()<br></br>
                        <span style={{marginLeft:'45px'}}></span>self.working = True<br></br>
                        <span style={{marginLeft:'45px'}}></span>self.ser = ser<br></br>
                        <br></br>
                        <span style={{marginLeft:'30px'}}></span>def work(self):<br></br>
                        <span style={{marginLeft:'45px', color: 'lightgreen'}}># Entrée dans la boucle permanente de lecture du port série</span><br></br>
                        <span style={{marginLeft:'45px'}}></span>while self.working:<br></br>
                        <span style={{marginLeft:'60px', color: 'lightgreen'}}># Voici la lecture du port série après avoir contrôlé qu'il est bien ouvert</span><br></br>
                        <span style={{marginLeft:'60px'}}></span>if self.ser.isOpen():<br></br>
                        <span style={{marginLeft:'75px'}}></span>try:<br></br>
                        <span style={{marginLeft:'90px'}}></span>line = self.ser.readline().decode('utf-8')<br></br>
                        <span style={{marginLeft:'75px'}}></span>except:<br></br>
                        <span style={{marginLeft:'90px'}}></span>pass<br></br>
                        <span style={{marginLeft:'60px'}}></span>else:<br></br>
                        <span style={{marginLeft:'75px'}}></span>line = ''<br></br>
                        <br></br>
                        <span style={{marginLeft:'60px', color: 'lightgreen'}}># Avec time.sleep(0.1), on laisse 100 millisecondes entre deux lectures</span><br></br>
                        <span style={{marginLeft:'60px'}}></span>if line != '':<br></br>
                        <span style={{marginLeft:'75px'}}></span>time.sleep(0.1)<br></br>
                        <span style={{marginLeft:'75px'}}></span>if line[-1] != 'n':<br></br>
                        <span style={{marginLeft:'90px'}}></span>line += '\n'<br></br>
                        <span style={{marginLeft:'60px', color: 'lightgreen'}}># En cas de données disponibles, on les affichent dans l'éditeur de réception</span><br></br>
                        <span style={{marginLeft:'75px'}}></span>self.intReady.emit(line)<br></br>
                        <br></br>
                        <span style={{marginLeft:'45px'}}></span>self.finished.emit()<br></br>
                    </code>
                </div>
                <div>
                    <div>
                        <br></br>
                        <p className="flash_p">
                            <span style={{marginLeft:'30px'}}></span>Voyons maintenant les méthodes déclenchées par les boutons de raccourci. 
                            Je décrirais ici le bouton qui permet de lire le nom du module Bluetooth et celui qui permet d'écrire rapidement 
                            la commande pour changer le nom du module.
                        </p>
                        <p className="flash_p">
                            <span style={{marginLeft:'30px'}}></span>Commençons par la lecture du nom du module (commande: AT+NAME?).
                        </p>
                    </div>
                </div>
                <div className="dispCode">
                    <code>
                        <span style={{marginLeft:'15px'}}></span>def send_ATNAME(self):<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.text_in_prepa = 'AT+NAME?'<br></br>
                        <span style={{marginLeft:'30px', color: 'lightgreen'}}># Une fois la syntaxe prête, on l'incrémente dans la saisie du messages à envoyer</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>self.new_data_value.setPlainText('AT+NAME?')<br></br>
                        <span style={{marginLeft:'30px', color: 'lightgreen'}}># Ensuite, on sollicite la méthode send_data()</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>self.send_data()<br></br>
                    </code>
                </div>
                <div>
                    <div>
                        <br></br>
                        <p className="flash_p">
                            <span style={{marginLeft:'30px'}}></span>Et voici une des méthodes qui permettent d'écrire automatiquement.
                        </p>
                    </div>
                </div>
                <div className="dispCode">
                    <code>
                        <span style={{marginLeft:'15px', color: 'lightgreen'}}># Simplement, on incrémente un texte dans la zone de saisie</span><br></br>
                        <span style={{marginLeft:'15px', color: 'lightgreen'}}># L'utilisateur pourra compléter ce texte et l'envoyer en cliquant sur "SEND"</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>def write_ATNAME(self):<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.new_data_value.setPlainText('AT+NAME=')<br></br>
                    </code>
                </div>
                <div>
                    <div>
                        <br></br>
                        <p className="flash_p">
                            <span style={{marginLeft:'30px'}}></span>La méthode send_data() récupère le contenu du message à envoyer, l'envoie par le port série 
                            et vide la zone de saisie
                        </p>
                    </div>
                </div>
                <div className="dispCode">
                    <code>
                    <span style={{marginLeft:'15px'}}></span>def send_data(self):<br></br>
                    <span style={{marginLeft:'30px'}}></span>message = self.new_data_value.toPlainText()<br></br>
                    <span style={{marginLeft:'30px'}}></span>if self.text_in_prepa != '':<br></br>
                    <span style={{marginLeft:'45px'}}></span>message = self.text_in_prepa<br></br>
                    <span style={{marginLeft:'30px', color: 'lightgreen'}}># Si on a sélectionné "\r", alors la fin de chaine est augmentée de "\r"</span><br></br>
                    <span style={{marginLeft:'30px'}}></span>if self.ret_carr:<br></br>
                    <span style={{marginLeft:'45px'}}></span>message += "\r"<br></br>
                    <span style={{marginLeft:'30px', color: 'lightgreen'}}># Si on a sélectionné "\n", alors la fin de chaine est augmentée de "\n"</span><br></br>
                    <span style={{marginLeft:'30px'}}></span>if self.ret_line:<br></br>
                    <span style={{marginLeft:'45px'}}></span>message += "\n"<br></br>
                    <span style={{marginLeft:'30px', color: 'lightgreen'}}># On peut confirmer l'envoie du message en affichant ce dernier dans le terminal</span><br></br>
                    <span style={{marginLeft:'30px'}}></span>self.emit_data('Send : ' + message +  "\n")<br></br>
                    <span style={{marginLeft:'30px', color: 'lightgreen'}}># Envoie du message et nettoyage du champ de saisie</span><br></br>
                    <span style={{marginLeft:'30px'}}></span>try:<br></br>
                    <span style={{marginLeft:'45px'}}></span>self.ser.write(message.encode())<br></br>
                    <span style={{marginLeft:'45px'}}></span>self.new_data_value.clear()<br></br>
                    <span style={{marginLeft:'45px'}}></span>self.text_in_prepa = ''<br></br>
                    <span style={{marginLeft:'30px'}}></span>except:<br></br>
                    <span style={{marginLeft:'45px'}}></span>self.emit_data('Erreur, envoi impossible!' + "\n")<br></br>
                    </code>
                </div>
                <div>
                    <div>
                        <br></br>
                        <p className="flash_p">
                            <span style={{marginLeft:'30px'}}></span>Pour finir ce descriptif, je vais vous parler de l'ordre de la zone de saisie. Si on ajoute des 
                            lignes dans un QTextEdit avec la méthode "append()", les lignes sont ajoutées à la suite des autres nous obligeant à faire défiler la 
                            scrollbar pour les lire. C'est vraiment pas pratique, nous allons donc ajouter ces lignes au début de la zone QTextEdit en utilisant le 
                            curseur (QTextCursor).
                        </p>
                    </div>
                </div>
                <div className="dispCode">
                    <code>
                    <span style={{marginLeft:'15px'}}></span>def emit_data(self, i):<br></br>
                    <span style={{marginLeft:'30px'}}></span>if i != '':<br></br>
                    <span style={{marginLeft:'45px'}}></span>tp = str(datetime.now())<br></br>
                    <span style={{marginLeft:'45px', color: 'lightgreen'}}># On instancie un curseur </span><br></br>
                    <span style={{marginLeft:'45px'}}></span>cursor = QtGui.QTextCursor(self.receive_box.document())<br></br>
                    <span style={{marginLeft:'45px', color: 'lightgreen'}}># On specifie le curseur de la boite de réception en position 0</span><br></br>
                    <span style={{marginLeft:'45px'}}></span>self.receive_box.setTextCursor(cursor)<br></br>
                    <span style={{marginLeft:'45px', color: 'lightgreen'}}># On utilise la méthode "insertPlainText()" pour insérer la ligne à la position du curseur</span><br></br>
                    <span style={{marginLeft:'45px'}}></span>self.receive_box.insertPlainText(<br></br>
                    <span style={{marginLeft:'60px'}}></span>tp[11:-7] + ' &#8250;&#8250;&#8250; ' + "{}".format(i)<br></br>
                    <span style={{marginLeft:'45px'}}></span>)<br></br>
                    </code>
                </div>
            </div>
        </div>
        <br></br>
        <div className="ttHeader" id="ATmanager_creationdelexecutable">
            <h2>Création de l'exécutable</h2>
        </div>
        <div className="ttText">
            <p className="flash_p">
                <span style={{marginLeft:'30px'}}></span>Pour créer l'exécutable, j'utilise "pyinstaller", n'hésitez pas à l'installer avec "PIP".<br></br>
                <span style={{marginLeft:'30px'}}></span>Voici maintenant la commande "pyinstaller" qui permet de créer l'exécutable:<br></br>
                <br></br>
            </p>
            <div className="dispCode">
                <code>
                pyinstaller --noconsole --noconfirm --icon favicon.ico --name "AT-MANAGER" --add-data="favicon.ico;." --add-data="ATmanag.ui;." --add-data="ButtonsMethod.py;."  --add-data="SerialReader.py;."  main.py
                </code>
            </div>
            <p className="flash_p">
                <span style={{marginLeft:'30px'}}></span>Vous pouvez l'exécuter dans la console à la racine de votre projet sans oubliez pas votre icon... (favicon.ico)<br></br>
                <span style={{marginLeft:'30px'}}></span>Libre à vous de créer un installateur Windows, j'ai utilisé 
                <span> </span><a href="https://jrsoftware.org/isinfo.php" target="_blank" rel="noreferrer">Inno Setup</a> pour créer l'exécutable ci-dessous, 
                <span> </span><a href="https://installforge.net/" target="_blank" rel="noreferrer">InstallForge</a> est facile à prendre en main, il en existe d'autres.
            </p>
            <br></br>
            <p className="flash_p" style={{border: '1px solid blue', borderRadius: '10px', background: 'rgba(141, 234, 223, 0.2)',textAlign: 'center', fontSize: '16px'}}>
                Veuillez trouver ci-dessous le setup de AT-MANAGER que j'ai construit avec Inno Setup.<br></br>
                <span> <a href={process.env.PUBLIC_URL + "/download/AT-MANAGER/AT-MANAGER.zip"} target="_blank" rel="noreferrer"><b>AT-MANAGER.zip</b></a></span><br></br>
                Après l'avoir décompressé, vous pourrez l'installer, ne soyez pas surpris si Defender émet une alerte, c’est qu’il travaille bien…
            </p>
            <br></br>
        </div>
        <div className="ttHeader" id="ATmanager_utilisation">
            <h2>Utiliser AT-MANAGER</h2>
        </div>
        <div>
            <p className="flash_p">
                <span style={{marginLeft:'30px'}}></span>Pour comprendre comment utiliser le terminal AT-MANAGER, je vous invite à visionner cette vidéo.
                <br></br><br></br>
            </p>
            <div style={{width: '100%', textAlign: 'center'}}>
                <video style={{width: '60vw'}} controls>
                    <source
                        src={process.env.PUBLIC_URL + '/video/AT-MANAGER_utilisation.mp4'}
                        type="video/mp4" />
                </video>
            </div>
            <br></br>
        </div>
        <div className="ttHeader" id="ATmanager_evolutionspossibles">
            <h2>Evolutions possibles de cette application</h2>
        </div>
        <div className="ttText">
            <p className="flash_p">
                <span style={{marginLeft:'30px'}}></span>Cette application est évolutive, plusieurs fonctionnalités pourraient s'y ajouter.<br></br>
            </p>
        </div>
        <div style={{margin: '0 10vw'}}>
            <ul>
                <li>Pouvoir renseigner et utiliser des listes de commandes en fonction de la version du module et de sa version.<br></br>
                    <span style={{fontSize: '12px'}}>
                        <i>En effet, l'interface tel que présenté est fait pour paramétrer un HC-05 de version 2 ou 3. Il existe de nombreux 
                        autres modules en Bluetooth Classique, et beaucoup sont bien plus fiables que le HC05 et surtout, ils sont certifiés CE. </i>
                    </span>
                </li>
                <br></br>
                <li>On pourrez créer une séquence de paramétrage automatique.<br></br>
                    <span style={{fontSize: '12px'}}>
                        <i>Imaginons une entreprise qui doit produire des cartes équipées d'un Bluetooth. Tous les modules vont être paramétrés de la même façon. 
                        Au lieu de cliquer sur une série de boutons, on pourrait déclencher une séquence de paramétrage contrôlant les retours console entre chaque action. 
                        Cette séquence automatique serait déclenchée juste par un clic.</i>
                    </span>
                </li>
                <br></br>
                <li>D'autres idées!!!<br></br>
                    <span style={{fontSize: '12px'}}>
                        <i>...</i>
                    </span>
                </li>
                <br></br>
            </ul>
        </div>
        <div className="ttHeader">
            <h3>A vos claviers...</h3>
        </div>
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <div className="AVRmenu">
            <span className="arrowMenu">
                <ion-icon name="arrow-redo-outline"></ion-icon>
            </span>
            <div className="showAVRMenu">
                <ul>
                    <li style={{textAlign: 'center', paddingRight: '35px'}}><b>SOMMAIRE</b></li>
                    <hr style={{width: '50px', position: 'relative', right: '20px'}}></hr>
                    <li>
                        <a
                            href="#/AT-MANAGER#ATmanager_questcequecestAT-MANAGER"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#ATmanager_questcequecestAT-MANAGER').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Quest-ce que AT-MANAGER?
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AT-MANAGER#ATmanager_pourquoiutiliseruneplatinearduino"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#ATmanager_pourquoiutiliseruneplatinearduino').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Pourquoi utiliser une platine Arduino
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AT-MANAGER#ATmanager_lesbasespourcomprendre"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#ATmanager_lesbasespourcomprendre').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Quelles bases faut-il avoir pour comprendre ce tutoriel?
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AT-MANAGER#ATmanager_commentutiliserunconvertisseurTTL"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#ATmanager_commentutiliserunconvertisseurTTL').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Comment utiliser un convertisseur TTL
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AT-MANAGER#ATmanager_commandeAT"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#ATmanager_commandeAT').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Les commandes AT
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AT-MANAGER#ATmanager_boitierconnexionHC05"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#ATmanager_boitierconnexionHC05').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Un boitier de connexion pour le HC-05
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AT-MANAGER#ATmanager_confectionducode"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#ATmanager_confectionducode').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Réalisation de l'interface graphique
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AT-MANAGER#ATmanager_creationdelexecutable"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#ATmanager_creationdelexecutable').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Création de l'exécutable
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AT-MANAGER#ATmanager_utilisation"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#ATmanager_utilisation').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Utiliser AT-MANAGER
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AT-MANAGER#ATmanager_evolutionspossibles"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#ATmanager_evolutionspossibles').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Evolutions possibles de ce terminal
                        </a>
                    </li>
                </ul>
            </div>
        </div>
      </section>
  )
}
