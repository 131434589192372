import React, {useState} from 'react';
import Menu1Button from './Menu1Button';
import PagePython from './PagePython';
import WebStatique from './WebStatique';
import WebDynamique from './WebDynamique';
import PageViewer from './PageViewer';
import PageMobil from './PageMobil';
import PageHardware from './PageHardware';

export default function ProfilPage(props) {
  const icons = {
    arrow_to_left: (
      <svg width="60px" height="60px" viewBox="0 0 24 24" fill="lightgrey">
        <path d="M11 9L8 12M8 12L11 15M8 12H16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="blue" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      ),
    arrow_to_right: (
      <svg width="60px" height="60px" viewBox="0 0 24 24" fill="lightgrey">
        <path d="M13 15L16 12M16 12L13 9M16 12H8M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="blue" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      ),
  }

  const [staticSelector, setStaticSelector] = useState(0);
  const changeImage = (action, imgNb) => {
    if (action === 'before') {
      if (staticSelector > 0) {
        setStaticSelector(staticSelector - 1);
      } else {
        setStaticSelector(imgNb - 1);
      }
    }
    if (action === 'after') {
        if (staticSelector < imgNb - 1) {
          setStaticSelector(staticSelector + 1);
        } else {
          setStaticSelector(0);
        }
    }
  }
  const getPageBefore = imagesCount => {
    document.querySelector('.description').style.animation = 'hideText 0.5s ease-in-out';
    setTimeout(() => changeImage('before', imagesCount), 200);
    document.querySelector('.profilImage').style = 'opacity: 0;transform: scaleY(0);';
    setTimeout(() => {
        document.querySelector('.description').style.animation = 'dispText 0.5s ease-in-out';
        document.querySelector('.profilImage').style = 'opacity: 1;transform: scaleY(1);';
      },
      400,
    );
  }
  const getPageAfter = imagesCount => {
    document.querySelector('.description').style.animation = 'hideText 0.5s ease-in-out';
    setTimeout(() => changeImage('after', imagesCount), 200);
    document.querySelector('.profilImage').style = 'opacity: 0;transform: scaleY(0);';
    setTimeout(() => {
        document.querySelector('.description').style.animation = 'dispText 0.5s ease-in-out';
        document.querySelector('.profilImage').style = 'opacity: 1;transform: scaleY(1);'
      },
      400,
    );
  }
  return (
    <section>
      <div className="menuContainer centrAround">
        <div className="demiMenu centrAround">
          <Menu1Button 
            titleBut="Logiciel"
            visitLog={"visit_skills_pagepython"}
            pageName="pagePython"
            menuBut={props.menuBut}
            setMenuBut={props.setMenuBut}
          />
          <Menu1Button 
            titleBut="Web statique"
            visitLog={"visit_skills_webstatique"}
            pageName="pageStatique"
            menuBut={props.menuBut}
            setMenuBut={props.setMenuBut}
          />
          <Menu1Button 
            titleBut="Web dynamique"
            visitLog={"visit_skills_dynamique"}
            pageName="pageDynamique"
            menuBut={props.menuBut}
            setMenuBut={props.setMenuBut}
          />
        </div>
        <div className="demiMenu centrAround">
          <Menu1Button 
            titleBut="Viewer 3D"
            visitLog={"visit_skills_viewer3D"}
            pageName="pageViewer"
            menuBut={props.menuBut}
            setMenuBut={props.setMenuBut}
          />
          <Menu1Button 
            titleBut="Mobile"
            visitLog={"visit_skills_pagemobile"}
            pageName="pageMobil"
            menuBut={props.menuBut}
            setMenuBut={props.setMenuBut}
          />
          <Menu1Button 
            titleBut="Embarqué"
            visitLog={"visit_skills_pagehardware"}
            pageName="pageHardware"
            menuBut={props.menuBut}
            setMenuBut={props.setMenuBut}
          />
        </div>
      </div>
      <div>
      {props.menuBut === 'pagePython' && (
        <PagePython 
          icons={icons}
          imageUrl={props.imageUrl}
          changeImage={changeImage}
          staticSelector={staticSelector}
          setStaticSelector={setStaticSelector}
          getPageBefore={getPageBefore}
          getPageAfter={getPageAfter}
        />
      )}
      {props.menuBut === 'pageStatique' && (
        <WebStatique 
          icons={icons}
          imageUrl={props.imageUrl}
          changeImage={changeImage}
          staticSelector={staticSelector}
          setStaticSelector={setStaticSelector}
          getPageBefore={getPageBefore}
          getPageAfter={getPageAfter}
        />
      )}
      {props.menuBut === 'pageDynamique' && (
        <WebDynamique 
          icons={icons}
          imageUrl={props.imageUrl}
          changeImage={changeImage}
          staticSelector={staticSelector}
          setStaticSelector={setStaticSelector}
          getPageBefore={getPageBefore}
          getPageAfter={getPageAfter}
        />
      )}
      {props.menuBut === 'pageViewer' && (
        <PageViewer />
      )}
      {props.menuBut === 'pageMobil' && (
        <PageMobil 
          icons={icons}
          imageUrl={props.imageUrl}
          changeImage={changeImage}
          staticSelector={staticSelector}
          setStaticSelector={setStaticSelector}
          getPageBefore={getPageBefore}
          getPageAfter={getPageAfter}
        />
      )}
      {props.menuBut === 'pageHardware' && (
        <PageHardware 
          icons={icons}
          imageUrl={props.imageUrl}
          changeImage={changeImage}
          staticSelector={staticSelector}
          setStaticSelector={setStaticSelector}
          getPageBefore={getPageBefore}
          getPageAfter={getPageAfter}
        />
      )}
      </div>
    </section>
  )
}
