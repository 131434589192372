import React from 'react'

export default function DepartmentMap(props) {
  return (
    <div className="mapContent" id="departmentContent">
      <svg width="612.3844" height="584.36005">
        <path
            id="FR-01"
            title="Ain"
            onClick={() => props.setDepartment('01 - Ain')}
            stroke="blue" fill={props.department.substr(5) === 'Ain' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 413.70408,308.36159 6.69,-20.52 0.4,1.07 1.39,-0.84 3.02,1.84 3.26,-1.47 1.3,0.28 0.05,-0.91 1.58,0.81 1.23,2.86 3.26,0.7
            0,0 0.38,1.07 -1.02,0.92 3.23,1.85 -0.65,0.72 1.72,1.9 0.04,1.68 0.85,-2.24 0.53,1.95 1.59,-0.01 -0.76,2.46 0.69,0.61 2.71,-0.25
            1.12,-1.55 1.24,-0.09 1.96,-2.92 0.15,1.23 2.79,0.89 0.48,2.9 5.99,-0.23 8.1,-9.07 0,0 4.37,2.95 -2.81,4.91 0.93,2.04 -6,2.06
            -0.64,1.2 1.25,0.91 -1.59,2.91 0,0 -2.64,0.81 -0.26,1.99 -2.77,-1.09 -0.59,5.3 1.17,2.49 -0.24,2.05 0,0 -2.2,12.71 -1.08,1.12
            -1.97,-0.3 -0.61,4.09 -2.58,1.67 0,0 -1.02,-2.67 -2.36,-2.01 0.42,-0.99 -5.39,-5.73 0.32,-1.78 -3.27,-2.82 -2.08,2.12 -1.32,3.32
            -1.94,1.36 -1.41,-0.23 -1.64,-1.96 -1.44,-0.3 -0.34,0.78 -0.21,-0.95 0,0 -7.62,0.63 0,-2.26 -1.54,-3.26 -1.39,-1 -1.8,0.99 0.17,-1.4
            -3.27,-1.31 1,-1.81 -0.32,-6.96 2.33,-3.73 z"/>
        <path
            id="FR-02"
            title="Aisne"
            onClick={() => props.setDepartment('02 - Aisne')}
            stroke="blue" fill={props.department.substr(5) === 'Aisne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 346.97408,70.521588 3.58,-1.35 0.79,1.17 2.14,-0.11 1.42,-1.34 5.28,1.2 2.2,-2.36 2.07,0.68 0.84,1.23 1.08,-0.84
            0.81,0.55 0.18,-1.55 2.11,-1.02 1.28,1.11 5.26,0.96 0.63,1.83 3.94,-2.24 0.55,1.15 -0.62,2.11 3.39,0.59 1.04,0.98 2.31,-0.69
            0,0 2.07,0.58 0.12,0.91 1.51,-0.17 0,0 -0.6,2.9 1.58,0.63 -0.1,2.29 -1.96,5.67 1.82,1.47 -2.69,3.72 -2.44,1.34 -0.47,2.88
            -3.07,-0.3 -0.73,1.01 2.26,3.25 -1.17,1.680002 1.09,0.19 0.14,1.11 -1.58,1.13 0.04,2.5 1.09,1.41 -1.27,0.46 0.42,2.04 0,0
            -0.48,2.93 -3.2,-1.09 -1.49,-1.87 -2.83,1.69 -0.18,2.25 -3.05,-0.73 -5.51,2.72 0.85,2.84 -0.56,0.94 1.08,1.22 -1.01,1.39 1.18,1
            0.69,-0.62 0.66,1.9 1.42,-0.27 -0.03,1.41 -1.8,1.05 -0.78,-0.75 -2.66,0.23 -0.89,1.95 1.55,2.68 -2.13,1.07 -0.07,1.47 2.72,-0.39
            1.12,1.83 -1.51,0.63 -0.85,2.39 -1.28,0.38 0.54,1.09 -1.22,0.22 -0.14,1.56 -1.76,0.16 -1.78,3.77 0,0 -1,0.92 -0.64,-1.43 -2.6,-0.73
            -0.64,-3.55 -1.6,1.28 -1,-2.56 -1.67,0.62 -0.55,-2.12 -3.48,-2.51 0.97,-2.39 -1.55,-2.05 0.47,-1.02 -3.83,-1.13 0,0 0.95,-1.64
            0.68,0.73 -0.24,-1.09 1.73,-0.75 -1.63,-0.45 0.36,-0.56 0,0 0.01,-0.52 0,0 -0.66,-0.84 -0.52,2.44 -0.18,-2.11 -3.18,-1.48 -0.19,1.7
            1.38,0.27 -0.57,0.9 -1.22,-1.58 -0.32,0.69 -0.71,-0.45 -0.38,-2.2 1.8,-0.61 0.88,1.21 0.71,-0.98 -0.08,-3.45 -2.17,-0.11 -0.71,-2.47
            1.33,-0.86 1.8,0.76 2.11,-3.07 -0.06,-3.6 2.95,-0.43 -2.4,-1.67 0.7,-1.21 -1.25,-1.78 1.33,-0.8 0.5,-3.130002 -1.87,-3.04 0.86,-1.05
            -0.65,-1.13 1.22,-0.32 -0.31,-2.84 0,0 0,0 0,0 -0.02,-0.18 0,0 -0.83,-3.7 -1.45,-0.26 0.9,-2.2 -1.31,-1.74 1.25,-0.64 -0.36,-2.4
            0.48,0.86 1.34,-1.17 0.12,-2.61 3.34,-3.81 -2.03,-0.47 z" />
        <path
            title="Allier"
            id="FR-03"
            onClick={() => props.setDepartment('03 - Allier')}
            stroke="blue" fill={props.department.substr(5) === 'Allier' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 309.92408,293.77159 0.74,-3.03 1.03,-0.01 1.56,-2.77 5.24,-0.96 2.06,0.81 3.24,-2.06 -0.45,-2.48 -1.55,-0.86 1.22,-1.84
            -1.07,-1.19 1.45,-0.75 0.95,0.77 -0.15,-1.91 3.21,-1.71 0.59,-1.61 0.74,-0.13 1.14,1.84 1.56,-1.08 1.98,0.55 0.14,-1.2 2.95,-2.86
            1.78,-0.69 2.81,0.57 0,0 1.11,2.64 2.94,1.43 3.04,3.12 2.78,-2.49 1.31,0.06 0.53,1.6 1.39,0.33 1.34,-1.73 1.72,-0.08 1.32,1.46
            -0.39,1.63 1.63,0.75 1.38,-1.98 1.88,-0.35 -0.55,-2.04 2.05,-0.09 0,0 -0.48,-0.92 0,0 0.46,-1.57 1.36,0.99 0,0 0.39,2.55 2.47,2.9
            1,1.93 -0.34,1.1 0.95,0.4 0.23,3.82 2.64,1.2 1.39,-0.76 1.15,2.55 3.59,-0.21 -0.02,0.89 1.95,0.95 -0.98,3.8 0.36,4.18 0.82,0.08
            -0.71,0.76 -1.7,-0.41 -0.09,1.26 -1.95,0.44 0.07,1.04 0,0 -0.89,0 0,0 -0.44,0.81 -2.67,0.25 0.05,1.05 -1.5,0.09 1.69,4.56 -0.8,0.42
            0.15,2.63 1.18,1.39 -0.67,2.1 1.07,3.23 -1.8,1.54 -1.33,-0.57 -0.52,1.03 -1.44,-0.47 -0.62,2.58 0,0 -0.7,-1.65 -1.84,-0.23 -1.71,-3.68
            -5.27,0.93 -0.1,-3.1 0,0 -0.99,0 0,0 -0.79,-0.87 -3.61,1.74 -1.22,-1.24 -7.19,-0.25 -1.28,-0.81 -0.29,-1.86 -3.2,0.73 -1.9,-1.28
            -0.68,-2.78 -1.6,-0.44 0.01,-2.16 1.34,-1.61 -1.1,-0.59 -3.64,0.23 -0.93,3.04 -3.2,-1.57 -0.38,2.43 -2.04,0.59 -1.47,3.18 -2.97,-1.46
            0,0 -3.53,-8.19 -2.11,-0.96 -0.42,0.78 -1.4,-2.9 -0.95,1.27 -2.6,-1.97 1.35,-1.57 -1.97,-0.72 z" />
        <path
            title="Alpes-de-Haute-Provence"
            id="FR-04"
            onClick={() => props.setDepartment('04 - Alpes-de-Haute-Provence')}
            stroke="blue" fill={props.department.substr(5) === 'Alpes-de-Haute-Provence' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 450.89408,424.90159 4.99,-1.28 4.7,1.34 0.15,-0.85 -1.51,-0.55 -2.14,-3.89 0.5,-0.95 1.22,0.12 2.29,2.77 0.17,-3.85 -1.14,-0.08
            0.18,-1.21 1.54,-1.33 0.5,-2.09 4.33,-3.15 0.82,0.71 0.33,-2.28 3.09,0.93 -0.3,1.15 0.98,0.1 2.18,3.36 1.45,-1.75 -1.2,-2.93 2.62,-1.1
            1.27,1.03 0.84,-2 -0.62,-0.65 1.25,-0.74 2.05,3.04 0.95,-0.27 1.42,1.37 6.79,0.23 1.97,-5.45 2.51,-0.73 1.15,-1.97 3.13,-1.24 2.8,-2.88
            1.63,0.21 0,0 0.69,2.13 -0.75,0.06 -0.72,2.58 -3.19,2.53 0.94,2.86 2.91,2.91 -2.3,0.48 -0.19,3.51 0,0 -3.16,1.7 -0.91,3.33 -1,-0.34
            -1.43,1.63 -1.83,4.76 2.94,5.16 -0.43,2.23 3.73,2.92 0.48,2.08 1.02,0.03 2.91,3.13 -2.4,0.62 -2.25,-1.68 -3.38,2.66 -3.18,-0.96
            1.2,1.79 -1.45,1.52 1.86,0.96 -3.18,1.42 0,0 -0.46,-0.9 -1.48,-0.03 -1.93,1.73 -1.01,-1.95 -1.36,1.03 -2.9,-0.05 -0.03,1.78 -1.29,1.52
            -2.37,-0.74 -3.16,-3.12 -1.45,0.11 -2.33,3.06 -2.17,0.07 -3.51,4.31 -1.45,-2.6 -2.06,-0.93 -0.48,-1.53 -0.96,0.21 -0.08,1.26 -2.12,0.79
            -0.84,-1.7 -2.02,-0.32 -0.86,1.19 0,0 -4.38,-5.31 -3.28,-0.27 -1.26,0.79 0.08,-2 2.55,-3.66 -1.15,0.02 -0.56,-1.52 -2.46,-0.57
            1.49,-6.67 -1.65,0.2 -0.25,-3.02 0,0 3.33,-2.08 -0.59,-1.08 0.56,-1 1.31,-0.13 1.25,1.3 -0.35,1 1.96,0.24 0.17,-0.99 -1.63,-0.3
            0.36,-1.19 z" />
        <path
            title="Hautes-Alpes"
            id="FR-05"
            onClick={() => props.setDepartment('05 - Hautes-Alpes')}
            stroke="blue" fill={props.department.substr(5) === 'Hautes-Alpes' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 475.15408,370.50159 1.39,1.08 1.63,-0.89 1.28,1.21 0.03,1.9 3.58,1.06 1.47,-0.18 -0.26,-2.22 1.25,-0.96 1.3,0.56 1.51,-1.41
            2.19,0.87 0,0 1.75,5.3 2.94,0.33 -0.23,1.48 1.07,1.67 -0.87,1.25 0.31,1.97 4.67,3.21 1.95,0.38 1.05,-1.06 3.6,2.26 -0.94,2 3.19,6.09
            -3.71,-0.37 -1.52,2.01 0,0 -1.63,-0.21 -2.8,2.88 -3.13,1.24 -1.15,1.97 -2.51,0.73 -1.97,5.45 -6.79,-0.23 -1.42,-1.37 -0.95,0.27
            -2.05,-3.04 -1.25,0.74 0.62,0.65 -0.84,2 -1.27,-1.03 -2.62,1.1 1.2,2.93 -1.45,1.75 -2.18,-3.36 -0.98,-0.1 0.3,-1.15 -3.09,-0.93
            -0.33,2.28 -0.82,-0.71 -4.33,3.15 -0.5,2.09 -1.54,1.33 -0.18,1.21 1.14,0.08 -0.17,3.85 -2.29,-2.77 -1.22,-0.12 -0.5,0.95 2.14,3.89
            1.51,0.55 -0.15,0.85 -4.7,-1.34 -4.99,1.28 0,0 0.44,-4.37 -1.64,0 -0.43,-1.95 -1.19,-0.33 0.88,-1.57 -3.38,0.18 -1.1,-1.18 -1.25,0.72
            -1.21,-1.67 -1.21,-0.21 0.43,-1.18 -1.03,-1.93 0.82,-0.51 1.6,0.81 0.84,-0.92 -1.36,-0.72 -0.27,-2.98 5.98,1.97 1.1,-2.02 1.44,-0.09
            -2.75,-2.45 2.11,-3.94 -0.26,-2.22 3.63,0.64 0.84,-1.34 1.67,0.51 1.7,-2.16 -1.31,-0.93 0,0 1.17,-3.09 2.32,0.76 2.83,-0.82 1.21,-1.23
            -1.27,-1.36 3.02,-1.89 1.42,1.24 3.22,-2.82 4.7,0.72 2.03,-1.29 1.09,1.27 1.38,-0.15 0.11,-4.94 -1.48,-0.84 0.05,-2.63 -3,0.13
            -1.96,-1.15 0.64,-2.83 1.02,-0.36 -0.54,-1.95 z" />
        <path
            title="Alpes-Maritimes"
            id="FR-06"
            onClick={() => props.setDepartment('06 - Alpes-Maritimes')}
            stroke="blue" fill={props.department.substr(5) === 'Alpes-Maritimes' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 501.88408,415.28159 2.67,4.28 1.02,0.19 0.07,1.66 1.42,1.61 1.67,-0.48 3.01,1.85 1.83,-0.06 3.82,3.5 2.64,-0.31 0.6,1.78
            1.46,-0.56 1.33,0.75 0.25,-1.05 1.26,0.28 4.19,-1.78 2.35,0.39 0.76,-1.57 1.96,0.11 -0.72,2.52 2.01,2.79 -0.4,2.36 -1.76,0.77
            -0.44,3.13 -3.54,1.86 -0.31,2.57 -2.57,1.34 1.45,4.98 -1.84,2.2 -1.43,-0.53 -1.79,2.09 -2.92,0.53 -0.24,2.08 -0.74,-1.74 -0.45,1.21
            -2.58,-0.22 -1.38,2.2 -2.16,-0.14 -1.27,2.89 0.47,3.24 -1.64,-1.44 -2.48,2.04 -0.74,-0.83 -2.05,0.24 -1.41,1.64 0.8,1.12 -1.15,1.16
            0,0 -1.86,-1.5 0.46,-1.36 -0.71,-0.14 1.4,-3.96 -4.67,-1.67 -1.52,-2.17 0.51,-2.17 -0.89,-2.12 -1.68,0.1 -1.13,-1.03 -1.14,0.51
            -0.98,-2.33 0,0 3.18,-1.42 -1.86,-0.96 1.45,-1.51 -1.2,-1.79 3.18,0.95 3.38,-2.66 2.25,1.68 2.4,-0.61 -2.9,-3.14 -1.03,-0.03
            -0.48,-2.08 -3.72,-2.92 0.42,-2.23 -2.94,-5.16 1.83,-4.76 1.43,-1.63 1,0.34 0.91,-3.33 3.16,-1.71 0,0 0.72,0.16 z" />
        <path
            title="Ardèche"
            id="FR-07"
            onClick={() => props.setDepartment('07 - Ardèche')}
            stroke="blue" fill={props.department.substr(5) === 'Ardèche' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 412.73408,356.42159 0.29,2.55 1.59,1.53 0,0 0.28,7.55 0.95,0.64 -1.18,1.93 1.1,3.04 1.28,0.84 -1.04,2.93 2.11,4.11 -1.33,2.24
            -0.43,3.23 -3.42,4.13 0.8,6.56 -1.64,4.17 -1.97,1.66 0.29,5.72 -1.44,1.98 -0.65,5.64 0,0 -0.05,3.44 0,0 -3.77,-1.84 -0.4,-1.33
            -1.53,-0.19 -0.28,-0.88 -2.16,0.31 -0.63,3.19 -1.76,-0.41 -0.15,-3.24 -2.72,0.47 -1.6,1.34 0.03,1.32 -1.3,1.58 -3.27,-3.06 -1.48,0.27
            -0.54,-1.35 -3.23,1.12 -0.65,-0.69 0.9,-1.99 -0.73,-1.6 1.18,-0.82 -1.35,-0.81 0.06,-1.53 -1.63,-0.8 0,0 -1.41,-5.52 -2.55,-2.29
            -1.73,-8.71 0,0 2.58,-1.42 -0.53,-0.55 1.6,-2.85 1.62,0.9 -0.24,-1.25 0.73,0.76 0.23,-1.06 0.94,0.26 0.26,-2.26 5.06,-0.04 2.84,-5.27
            3.69,-0.58 -0.97,-1.38 0.25,-1.33 3.57,-1.28 -1.51,-1.57 0.53,-1.84 1.66,-0.33 -1.04,-2.28 2.04,0.44 0.53,1.55 0.11,-0.74 0.79,0.21
            -0.6,-1.56 1.51,-2.64 0.14,-2.68 0,0 4.94,-0.86 -0.43,-1.36 1.33,-1.04 -0.32,-1.2 z" />
        <path
            title="Ardennes"
            id="FR-08"
            onClick={() => props.setDepartment('08 - Ardennes')}
            stroke="blue" fill={props.department.substr(5) === 'Ardennes' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 415.99408,61.321588 1.71,0.09 0.81,1.04 -1.19,1.1 0.18,2.05 -0.87,-0.88 -0.54,2.22 -0.75,0.14 0.88,1.74 -2.14,4.46 0.06,0.68
            2.34,0.64 1.7,2.49 -1.71,2.75 1.15,3.02 -0.96,1.71 2.26,0.47 3.71,-0.92 0.55,1.15 3.56,1.24 1.38,3.16 1.44,-0.14 0.17,1.43 4.33,-0.16
            2.54,2.68 -1.05,1.56 0.32,1.17 1.41,-1.26 1.99,0.92 0,0 -0.74,1.5 -1.68,-0.05 -1.06,2.19 -2.06,1.130002 -1.14,-1.760002 -2.96,0.41
            -0.84,-1.77 -1.28,-0.1 0.01,2.02 -0.94,1.160002 0,0 0,0.48 0,0 -1.29,2.18 2.16,2.72 0.17,2.45 -1.59,1.07 1,1.55 -1.92,0.63 -1.13,2.09
            -0.11,1.62 1.41,1.29 -0.35,0.98 -4.13,2.35 0,0 -1.25,-0.92 0.11,-0.92 -1.94,0.21 -0.45,2.23 -2.1,-1.41 -2.56,0.57 -2.64,-1.06 -2.33,1.64
            -1.76,-2.62 0.26,-1.17 -6.28,1.19 -2.08,-1.17 -0.69,-2.11 -1.51,0.69 -0.26,-0.78 -1.22,-0.01 -2.52,-3.62 -1.92,-0.03 -0.41,-1.12
            -5.94,-0.32 0,0 -0.42,-2.04 1.27,-0.46 -1.09,-1.41 -0.04,-2.5 1.58,-1.13 -0.14,-1.11 -1.09,-0.19 1.17,-1.680002 -2.26,-3.25 0.73,-1.01
            3.07,0.3 0.47,-2.88 2.44,-1.34 2.69,-3.72 -1.82,-1.47 1.96,-5.67 0.1,-2.29 -1.58,-0.63 0.6,-2.9 0,0 3.23,-0.67 5.66,2.04 2.72,-0.63
            2.63,-2.18 4.64,-1.11 0.51,-3.38 -0.81,-0.99 0.92,-1.86 2,-1.05 0.52,-1.61 2.55,-2.07 z" />
        <path
            title="Ariège"
            id="FR-09"
            onClick={() => props.setDepartment('09 - Ariège')}
            stroke="blue" fill={props.department.substr(5) === 'Ariège' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 250.86408,502.92159 -1.32,-5.14 2.44,-1 -0.4,-1.44 4.24,-0.89 1.92,-2.08 -1.51,-0.9 -0.32,-2.6 1.14,-0.57 -0.67,-0.57 0.43,-1.25
            1.56,0.82 0.62,-2.61 1.45,0.71 1.46,-1.21 2.8,1.32 1.37,2.58 1.58,-0.31 -0.04,-1.01 1.69,-0.72 -1.18,-1.48 -2.47,-0.82 0.81,-1.47
            3.61,-0.09 2.38,-1.51 -0.12,-1.6 -3.34,-1.21 -0.06,-1.29 1.66,-1.56 1.66,0.32 0.18,1.25 2.21,1.98 -0.57,1.59 2.08,1 0.76,-1.02
            0.95,0.29 -0.46,-4.05 0.85,0.09 0.08,1.22 1.17,-0.48 0.71,1.38 0.27,-1.2 2.74,1.07 -0.04,1.1 1.52,-2.52 0.68,0.43 0,0 1.47,2.76
            -0.55,2.15 3.03,1.98 2.79,0.32 0.01,-0.79 0.2,0.86 0.99,-0.18 0.41,1.83 1.05,-1 1.37,0.7 0.62,2.96 -0.9,0.1 0,0.89 1.82,1.02
            -0.66,1.39 0.66,0.29 -0.02,2.03 0.89,0.32 -2.03,-0.14 -1,1.41 1.93,0.55 0.26,3.33 -4.62,1.03 -0.63,1.89 1.56,0.6 1.22,1.9 -0.56,0.93
            1.43,1.2 5.69,-0.82 3.55,3.1 -0.28,1.87 0,0 -6.89,0.1 -1.01,2.49 -1.66,0.7 -1.38,-0.59 -1.3,1.96 -3.43,0.35 0,0 0.24,-0.77 -2.77,-0.13
            0.41,-1.5 -5.67,-0.55 -2.02,-1.64 -2.92,0.23 -0.09,2.1 -1.68,0.64 -1.96,-4.7 -1.61,-0.94 0.22,-0.9 -4.27,0.24 -0.94,-0.72 -2.55,1.08
            -3.74,-4.49 -3.96,0.1 -0.98,-1.08 -1.42,0.93 z" />
        <path
            title="Aube"
            id="FR-10"
            onClick={() => props.setDepartment('10 - Aube')}
            stroke="blue" fill={props.department.substr(5) === 'Aube' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 362.99408,159.08159 1,1.99 1.82,0.5 0.54,2.45 4.07,-0.02 3.64,1.47 1.65,-1.42 -0.55,-2.67 2.01,0.36 0.36,-1.68 1.66,-0.11
            2.2,-3.8 2.36,-0.17 0.91,-2.19 2.14,0.94 1.38,-1.34 2.83,0.37 0.39,-0.91 0.84,0.66 1.53,-0.53 1.4,2.27 -0.69,3.96 3.53,3.38
            3.85,1.44 0.65,-0.59 1.41,1.39 1.97,-1.74 2.12,-0.05 -0.37,0.62 1.46,0.8 0,0 -0.67,3.81 -1.03,0.55 2.06,1.31 1.16,3.09 2.24,0.19
            -0.22,1.32 3.54,1.53 -1.1,1.18 2,3.53 -1.07,2.87 1.17,1.31 -1.12,1.91 0.6,1.56 -0.9,0.83 0.21,1.5 -4.3,-0.75 -1.06,1.74 -1.99,0.63
            2.71,2.09 -0.74,1.51 0,0 -1.44,0.44 -3.54,-1.04 -2.03,1.5 0.77,2.38 -9.97,0.42 -0.9,2.25 0,0 -2,-0.32 -0.94,-1.18 0.18,-1.41
            -1.31,0.29 0.74,0.34 -0.43,1.26 -1.46,-1.12 0,0 -0.8,0.73 0,0 -1.57,1.35 -0.85,-1.17 -1.23,-0.05 -0.64,1.27 -1.67,-1.07 -0.76,0.75
            -3.79,0.07 0.77,-3.12 -0.78,-1.35 -0.6,1.41 -1.18,-0.26 -0.52,-1.29 1.31,0.09 0.05,-0.78 -2.06,-1.8 -1.29,-4.57 -2.14,-0.91 0.36,-2.07
            -1.41,-0.52 0.35,1.12 -2.31,1 -1.53,-3.1 -2.14,0.33 1.76,-2.55 -0.86,-0.43 0.76,-2.25 -1.73,-0.95 0.41,-1.03 -1.81,-1.19 -1.66,-2.92
            -1.55,-0.61 -1.59,1.1 -0.58,-1.97 0,0 0.31,-1.6 -1.27,-0.57 0.59,-1.79 -0.94,-1.65 2.26,-0.67 -1.3,-2.34 3.09,-0.86 -0.67,-1.8
            2.04,-1.21 -0.51,-0.93 z" />
        <path
            title="Aude"
            id="FR-11"
            onClick={() => props.setDepartment('11 - Aude')}
            stroke="blue" fill={props.department.substr(5) === 'Aude' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 299.51408,468.24159 0.94,0.27 0.83,1.99 4.06,-1.19 1.83,1.86 0.29,-2.72 1.52,-1.61 1.01,1.09 0.98,-0.36 3.75,1.75 3.25,-1.21
            4.05,0.99 0,0 0.88,0.94 -1.33,0.57 -0.9,2.72 1.95,0.74 1.31,2.76 2.37,-0.97 0.56,-1.23 0.76,2.77 2.74,0.93 1.87,-2.85 -0.45,-0.69
            2.59,-0.58 -0.06,-2.94 0.95,0.96 0,2.13 2.33,0.95 2.41,-0.5 0.27,2.61 1.78,-0.34 1.06,1.08 2.59,0.11 0.6,0.95 1.53,-0.41 0.7,0.79
            0,0 0.95,1.05 0,0 0.28,0.28 0,0 -4.3,5.01 -2.94,5.9 -1.1,4.52 0.93,1.25 -0.72,4.44 0,0 -7.46,-4.47 -3.13,1.24 -2.11,3.43 -9.84,-0.85
            -6.78,0.55 -0.73,0.99 0.95,1.27 0.46,4.14 -2.3,1.48 -1.39,-0.4 -2.96,3.22 -1.26,-0.7 0,0 0.28,-1.87 -3.55,-3.1 -5.69,0.82 -1.43,-1.2
            0.56,-0.93 -1.22,-1.9 -1.56,-0.6 0.63,-1.89 4.62,-1.03 -0.26,-3.33 -1.93,-0.55 1,-1.41 2.03,0.14 -0.89,-0.32 0.02,-2.03 -0.66,-0.29
            0.66,-1.39 -1.82,-1.02 0,-0.89 0.9,-0.1 -0.62,-2.96 -1.37,-0.7 -1.05,1 -0.41,-1.83 -0.99,0.18 -0.2,-0.86 -0.01,0.79 -2.79,-0.32
            -3.03,-1.98 0.55,-2.15 -1.47,-2.76 0,0 0.74,-1.8 1.19,-0.52 -0.54,-1.58 3.77,0.04 -0.5,-2.92 1.03,-0.19 -0.39,-0.85 1.01,-0.25
            0.46,-1.56 2.11,2.79 0.6,-1.55 1.54,-0.31 1.4,1 2.08,-0.88 z" />
        <path
            title="Aveyron"
            id="FR-12"
            onClick={() => props.setDepartment('12 - Aveyron')}
            stroke="blue" fill={props.department.substr(5) === 'Aveyron' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 306.90408,400.25159 0.71,-0.38 -0.39,-1.5 3.31,-1.11 1.31,-0.12 1.07,1.74 5.46,-0.59 0.65,-2.23 2.52,-1.81 -0.43,-1.94 2.1,-2.25
            0.18,-3.14 5.49,-5.58 1.55,1.71 -0.06,3.28 1.33,-1.09 2.27,0.01 1.19,4.96 1.34,-0.25 0.69,1.14 -1.06,0.74 0.97,1 0.07,3.74 1.87,2.07
            0,0 4.31,4.95 -0.79,3.27 3.24,3.74 -1.04,4.34 1.43,3.19 -1.34,1.35 -0.03,1.29 1.3,-0.74 0.36,1.76 1.13,-0.29 1.75,1.11 -1.1,2.33
            3.8,-0.94 2.74,0.34 0.44,1.75 0,0 -1.57,0.72 -0.54,2.89 -2.46,0.87 1.31,1.44 1.38,-0.63 1,1.68 1.55,-0.47 2.54,1.97 -1.79,3.08
            -2.01,1.18 0.05,2.04 0,0 -0.65,1.13 -3.27,-0.03 -1.22,1.82 0.57,1.91 -1.71,1.01 -5.95,-1.35 -0.61,1.9 1.07,1.91 -0.93,1.35 0.34,2.99
            -3.18,-0.9 -2.05,0.83 0,0 -0.92,-2.64 -1.78,0.07 -1.93,-1.28 -3.37,1.87 -3.51,-1.6 -4.04,-5.03 0.79,-1.84 -1.21,-0.91 0.07,-1.88
            -2.15,-1.15 1.29,-1.84 -1.24,-0.52 -1.67,-3.67 -2.14,-0.33 -0.87,-2.21 -2.36,-1.69 -1.08,0.26 -1.45,-1.65 -1.39,0.81 -2.21,-0.22
            2.15,-1.39 -1.83,-0.03 -2.1,-2.23 -1.54,0.45 -0.35,1.08 0,-0.81 -1.73,-0.04 0.24,0.96 -2.92,1.34 0,0 0.43,-0.51 -0.98,-1.21 -1.26,-0.14
            -0.29,0.8 -0.26,-1.17 -0.86,0.2 -0.85,-1.28 1.07,-0.27 0.7,-1.91 0.88,0.11 0.65,-1.72 -4.16,-1.12 0.48,-0.68 -0.96,-0.96 0.97,-1.07
            0,0 1.26,-1.04 -1.84,-2.29 0.26,-1.51 -0.96,-0.68 -0.45,-2.68 1.81,-0.19 0.19,-1.22 1.21,1.06 -0.21,-1.01 6.05,-4.59 0.23,1.03
            0.82,-1.28 2.03,1.04 1.92,-1.55 0.72,0.63 z" />
        <path
            title="Bouches-du-Rhône"
            id="FR-13"
            onClick={() => props.setDepartment('13 - Bouches-du-Rhône')}
            stroke="blue" fill={props.department.substr(5) === 'Bouches-du-Rhône' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 412.05408,440.35159 4.44,0.44 4.48,2.2 3.09,2.89 0.86,2.32 5.26,2.9 2.13,-0.74 3.38,0.6 4.76,3.06 4.32,1.44 3.15,0.03 2.8,-1.93
            1.95,-0.04 1.51,-1.73 0,0 2.45,1.95 -0.71,1.7 -4.04,0.93 -0.87,1.86 0.56,1.55 -1.49,0.58 2.86,1.31 -1.06,3.89 3.72,3.54 -1.6,1.09
            -2.81,0.14 0.93,2.46 -1.38,2.12 2.32,0.07 1.49,2 -0.1,0.83 -3.23,1.82 -0.48,3.15 0,0 -2.03,-0.39 -0.74,1.51 -2.84,-3.09 -1.52,1
            -1.86,-0.83 -4.13,0.18 -0.45,-0.85 1.26,-1.79 -1.22,-1.53 0.61,-1.64 -1.43,-2.56 -1.32,-0.23 -2.86,1.79 -7.27,0.19 -1.33,-0.97
            -0.68,-3.19 -1.13,-0.23 -0.18,-1.32 -2.07,-0.39 -1.31,1.06 0.72,-0.91 -1,0.59 -0.86,-2.03 0.69,2.52 -2.19,-0.8 1.51,1.31 -0.93,-0.25
            -0.35,0.65 0.89,0.37 -0.67,0.68 1.78,1.14 -0.74,1.54 -1,0.07 -2.57,-1.18 -8,-0.67 -1,-1.16 1.56,-1.31 -0.21,-1.04 -2.38,-1.71
            -12.58,-0.46 0,0 0.44,-2.25 3.4,-1.65 -0.41,-1.55 0.83,1.11 5.32,-3.08 0.43,-1.32 -1.9,-0.66 2.47,-4.19 2.27,-0.57 3.65,1.24
            -0.63,-2.29 1.61,-3.22 -0.35,-2.8 1.01,-0.81 -1.07,-1.25 4.07,-3.21 z" />
        <path
            title="Calvados"
            id="FR-14"
            onClick={() => props.setDepartment('14 - Calvados')}
            stroke="blue" fill={props.department.substr(5) === 'Calvados' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 227.60408,107.59159 0.18,3.92 0.99,1.99 -1.22,1.53 1.16,1.23 -0.58,0.83 0.71,0.34 1.5,-1.21 0.87,0.74 0,1.35 -2.62,1.02 3.07,2.39
            -0.39,3.58 2,0.81 -1.35,2.19 0.79,1.95 -1.03,-0.15 -1.14,1.58 3.29,2.1 -1.36,4.4 0,0 -1.58,-1.4 -1.65,1.86 -1.21,-0.81 -1.25,0.41
            -0.01,-1.12 -1.3,0.4 -0.55,1.32 -1.43,-0.22 -1.82,1.18 -1.2,-1.7 -1.92,0.87 -1.03,2.23 -2.56,1.01 0.03,0.87 -0.86,-0.29 -1.71,2.31
            -2.33,-0.5 -1,0.77 0.93,1.23 -2.69,-1.59 -4.76,-1.02 -0.99,0.11 -0.72,2.11 -1.15,-0.79 0.55,-0.83 -1.04,0.17 -0.94,-1.6 -1.47,0.09
            0,0 0,0 0,0 -5.34,2.48 -3.05,0.18 0.11,-0.83 -2.06,-0.41 -0.49,0.61 0.92,0.95 -1.3,1.37 -2.62,0.79 -2.62,2.23 0,0 0.75,-1.23
            -2.52,0.98 -2.84,-2.07 -0.87,1.04 -4.58,-0.33 -0.81,-2.34 -2.36,-0.76 2.55,-3.18 1.1,0.45 2.14,-2.26 -0.12,-1.31 -2.26,0.28
            0.81,-2.23 2.42,0.51 2.22,-0.98 1.72,-2.92 1.8,-1.15 -1.28,-1.09 0.88,-1.32 -0.38,-3.09 -1.83,0.9 1.49,-1.72 -2.98,-2.07 1.38,-0.12
            0.47,-1.71 1.13,-0.63 -1.6,-1.94 -1.99,2.59 -4.33,-2.73 -2.4,-2.31 0.66,-0.61 -0.81,-1.9 1.06,-0.99 -0.11,-1.14 0,0 -0.07,-1.01
            1.81,-1.89 5.14,-0.44 5.37,2.53 17.75,1.52 7.17,3.22 9.15,-2.7 5.33,-4.86 6.56,-2 0,0 z" />
        <path
            title="Cantal"
            id="FR-15"
            onClick={() => props.setDepartment('15 - Cantal')}
            stroke="blue" fill={props.department.substr(5) === 'Cantal' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 319.40408,349.78159 1.46,-0.01 -0.29,1.31 1.19,-0.52 0.92,0.95 1.26,-1.22 0.26,1.52 2.66,0.38 -0.33,1.8 1.42,1.5 1.14,-0.68
            1.76,0.52 1.18,-0.94 2.27,1.44 0.69,-0.44 1.6,0.9 1.08,3.52 2.13,1.08 2.15,-0.72 0.35,-1.69 2.15,-1.22 0,0 0.02,1.44 -0.92,0.17
            0.98,0.86 -1.32,1.22 3.22,-0.35 0.18,1.56 2.43,-0.49 0.57,3.43 1.52,0.58 -0.5,1.47 0,0 0,0.58 0,0 1.16,3.11 2.97,0.99 -3.22,0.02
            1.21,1.15 -0.54,3.01 0.58,0.8 1,-0.38 -0.41,0.92 1.79,0.31 -1.08,1.09 0.7,0.85 0,0 -3.14,2.58 -0.78,-0.91 -1.04,0.4 0.32,1.12
            -1.08,0.42 0.52,1.26 -1.83,1.47 -2.06,-2.34 -1.61,0.96 -0.19,3.06 -1.1,-0.23 -1.06,2.11 -0.84,4.26 0.52,0.74 -0.91,0.12 -1.47,4.03
            0,0 -1.87,-2.07 -0.07,-3.74 -0.97,-1 1.06,-0.74 -0.69,-1.14 -1.34,0.25 -1.19,-4.96 -2.27,-0.01 -1.33,1.09 0.06,-3.28 -1.55,-1.71
            -5.49,5.58 -0.18,3.14 -2.1,2.25 0.43,1.94 -2.52,1.81 -0.65,2.23 -5.46,0.59 -1.07,-1.74 -1.31,0.12 -3.31,1.11 0.39,1.5 -0.71,0.38 0,0
            -1.49,-1.16 -0.54,-3.42 -1.21,-0.06 1.74,-5.35 -3.34,-4.95 -0.26,-1.76 0.75,-0.98 -1.08,-0.81 -0.64,-2.42 0,0 1.4,-0.57 0,0 0.83,0.04
            0,0 1.15,-0.96 -1.84,-3.43 1.84,-1.55 1.25,0.25 0.27,-3.22 2.23,-1.84 -1.35,-0.76 -0.28,-2.37 1.94,-1.59 1.18,-2.51 0.83,0.17
            1.12,-1.96 1.81,-0.89 -0.22,-4.61 5.24,2.65 1.8,-0.7 -0.08,-1.18 -1.43,-1.02 z" />
        <path
            title="Charente"
            id="FR-16"
            onClick={() => props.setDepartment('16 - Charente')}
            stroke="blue" fill={props.department.substr(5) === 'Charente' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 223.25408,313.20159 2.65,0.88 0.83,1.22 4.7,-0.49 1.52,1.2 2.25,-1.03 -0.07,-1.22 -1.25,-0.03 -0.23,-0.9 2.01,-2.09 1.97,2.97
            2.34,0.88 0.95,-1.22 2.85,-0.2 -0.5,-0.93 1.57,-1.68 3.02,0.58 1.04,-0.57 0.57,0.67 0,0 -0.62,0.44 1.04,1.43 -0.85,2.73 1.82,1.91
            1.08,-0.83 1.76,1.41 0.61,3.11 -3.37,2.38 -1.99,-0.89 0.6,2.93 -1.82,5.42 -2.94,-0.75 0.13,2.49 -2.54,1.3 -0.97,1.79 0,0 -2.69,4.53
            -1.12,0.2 -0.35,-0.9 -0.79,0.73 -0.27,4.8 -2.3,1.64 -0.72,2.23 -2.12,0.01 -2.08,2.58 -1.17,-1.02 0.61,1.33 -1.7,0.57 -1.19,3.41
            0.79,4.25 -1.85,0.41 -0.7,1.56 -1.22,0.17 -0.03,1.3 -1.65,1.92 -1.51,-1.25 -1.82,0.02 -2.09,2.08 0,0 -0.05,-2.15 -1.81,-1.13 -3.13,-0.06
            0.21,-2.46 -1.42,0.16 -0.58,-1.08 -1.54,0.04 -1.01,-1 -1.55,1.41 -1.12,-0.63 0.28,-2.33 1.15,0.39 0.21,-1.13 -1.46,0.29 -1.27,-1.7
            3.15,-1.52 -1.34,-2.63 -0.81,-0.05 1.86,-2.72 -1.11,-0.34 0.64,-0.78 -2.87,-0.8 1.09,-1.72 -0.56,-0.77 -3.24,-2.93 -1.81,0.11 0.95,-1.8
            1.07,0.02 -1.66,-1.81 0.11,-2.32 0.79,-0.64 -2.5,-0.5 0.5,-1.32 1.72,-0.13 0.25,-1.09 3.93,0 0.81,-1.21 2.14,0.24 0.63,1.62 2.44,-0.99
            0.28,0.76 0.43,-3.44 1.36,-2.01 -1.38,-1.32 0.55,-1.04 -0.75,-0.9 2.18,-0.12 0.45,-1.32 -0.67,-1.13 0,0 2.69,-1.37 -0.81,-0.41
            1.52,-1.79 -0.65,-1.46 2.29,0.11 1.82,-1.19 0.14,-1.13 1.27,0.1 0.08,-0.96 1.57,0.05 1.37,1.4 z" />
        <path
            title="Charente-Maritime"
            id="FR-17"
            onClick={() => props.setDepartment('17 - Charente-Maritime')}
            stroke="blue" fill={props.department.substr(5) === 'Charente-Maritime' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 156.83408,316.03159 1.54,0.54 2.28,2.68 3.22,0.61 0.07,3.28 2.01,2.5 -0.83,2.26 0.53,1.18 -1.49,1.74 -1.58,-4.83 -4.89,-4.34
            -0.86,-5.62 z m -4.29,-12.52 1.52,1.4 -1.65,-0.02 0.51,1.81 1.79,-1.42 1.87,-0.13 -1.1,1.29 3.5,0.17 2.62,1.1 0.79,1.68 0.91,-0.19
            -2.4,1.19 -6.33,-3.54 -2.98,0.01 -1.17,-2.49 2.12,-0.86 z m 16.37,-3.54 1.7,-0.04 0.88,-1.95 3.28,-0.1 1.86,-1.17 -1.31,2.94
            1.17,0.68 1.91,-0.96 1.55,0.55 0.39,-1.36 1.62,-0.14 -0.08,1.02 2.24,1.26 0,0 0.58,2.17 -0.72,1.35 2.64,1.66 -0.1,2.24 0.95,-0.27
            0.68,1.09 1.97,0.53 -1.26,0.44 0.67,0.83 2.77,-0.54 1.65,0.91 0.3,1.5 2.68,0.58 0.85,-1.05 -0.05,1.25 0.85,0.55 1.23,-0.59 0.34,1.1
            3.05,-0.71 1.32,2.1 1.91,0.49 0.83,-0.6 0.2,1.63 1.19,-0.66 -0.19,1.6 1.03,0.64 0.07,1.24 1.42,0.54 0,0 0.68,1.13 -0.45,1.31
            -2.18,0.13 0.75,0.9 -0.55,1.03 1.38,1.32 -1.35,2.01 -0.44,3.44 -0.28,-0.76 -2.44,0.98 -0.63,-1.62 -2.14,-0.23 -0.81,1.21 -3.94,-0.01
            -0.25,1.09 -1.71,0.13 -0.5,1.32 2.5,0.5 -0.78,0.64 -0.11,2.32 1.66,1.81 -1.07,-0.02 -0.95,1.8 1.81,-0.11 3.24,2.93 0.56,0.76 -1.08,1.73
            2.87,0.8 -0.64,0.78 1.1,0.34 -1.86,2.72 0.81,0.05 1.33,2.62 -3.15,1.53 1.27,1.7 1.46,-0.29 -0.21,1.13 -1.15,-0.39 -0.28,2.32 1.12,0.64
            1.56,-1.42 1,1.01 1.54,-0.04 0.58,1.08 1.42,-0.16 -0.21,2.46 3.13,0.06 1.81,1.13 0.05,2.16 0,0 -0.89,1.28 0,0 0.66,0.62 0,0 -1.47,1.13
            -0.12,2.24 0,0 -2.31,-1.15 -2.01,1.86 -1.96,-0.28 -2.82,-1.2 -0.89,-1.63 -1.15,-0.36 -0.26,0.64 -1.52,-1.84 -1.37,1.53 -1.56,-3.79
            0.61,-1.87 -0.82,-0.48 0.14,-1.69 -2.08,-1 -3.84,-0.15 -0.93,-2.95 -0.34,1.52 -4.97,-0.42 0,0 -1.41,-5.12 -2.92,-4.56 -3.94,-3.37
            -1.94,-0.45 -2.58,-3.88 -0.71,0.33 -3.08,-1.75 -3.76,-2.95 -1.26,0.25 -0.06,-5.54 4.3,-1 -1.69,-3.2 2.22,0.04 0.79,-0.84 1.14,-3.32
            -1.08,-1.12 0.99,-0.85 -1.26,-2.11 2.27,-0.46 -2.03,-5.5 -1.73,-0.69 0.59,-1.22 -1.87,-0.65 0.63,-1.06 -2.96,0.09 1.2,-3.45 3.9,-3.25
            -0.39,-3.1 z" />
        <path
            title="Cher"
            id="FR-18"
            onClick={() => props.setDepartment('18 - Cher')}
            stroke="blue" fill={props.department.substr(5) === 'Cher' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 308.19408,220.89159 2.11,-0.54 3.42,2.67 2.73,-1.48 2.22,2.54 2.98,-0.23 2.23,1.9 -0.08,1.04 1.73,0.86 0.48,1.57 2.34,-0.62
            0.3,-2.7 3.13,2.66 2.8,-1.58 0,0 2.34,5.07 -2.62,6.09 0.49,1.33 4.24,3.35 -0.14,2.03 2.17,6.53 -0.53,3.46 2.49,2.46 -0.58,2.97
            0.65,1.16 -1.25,2.92 0.82,3.38 -1.57,3.44 0,0 -2.81,-0.57 -1.78,0.69 -2.95,2.86 -0.14,1.2 -1.98,-0.55 -1.56,1.08 -1.14,-1.84
            -0.74,0.13 -0.59,1.61 -3.21,1.71 0.15,1.91 -0.95,-0.77 -1.45,0.75 1.07,1.19 -1.22,1.84 1.55,0.86 0.45,2.48 -3.24,2.06 -2.06,-0.81
            -5.24,0.96 -1.56,2.77 -1.03,0.01 -0.74,3.03 0,0 -4.66,-0.28 0,0 -0.66,-2.03 2.18,-1.54 -1.93,-4.35 1.28,-5.24 -2.09,-1.32 0.65,-1.65
            -2.8,-1.23 -0.81,-2.31 1.16,-0.22 0.9,-1.54 -3.07,-3.6 1.37,0.32 0.84,-1.24 -0.52,-0.81 1.81,-0.74 1.15,-1.87 -1.85,-0.1 -1.62,-1.23
            1.5,-3.83 -3.29,-3.11 1.09,-1.48 -0.24,-2.14 -1.86,0.79 -0.38,-1.96 -2.96,1.49 -1.95,-0.01 -4.19,-1.66 2.81,-2.99 -0.1,-2.52 0,0
            1.39,0.88 1.13,-0.47 0.9,-1.63 -1.17,-0.92 1.94,-2.05 3.44,1.68 2.12,-1.36 2.2,0.31 1.22,-1.2 -1.6,-2.06 -0.24,-2.26 -0.71,0.18
            0.76,-3.37 0.96,0.85 2.1,-1.89 0.68,1.98 1.05,-0.11 0.71,-5.16 -2.09,0.29 0.75,-2.17 -0.69,-1.4 -2.91,-0.34 -0.36,-1.95 z" />
        <path
            title="Corrèze"
            id="FR-19"
            onClick={() => props.setDepartment('19 - Corrèze')}
            stroke="blue" fill={props.department.substr(5) === 'Corrèze' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 294.04408,336.79159 1.13,-0.92 0.82,0.57 0.5,-1.24 1.41,0.26 0.46,-1.69 2.02,-0.96 1.43,2.13 1.96,-0.31 1.01,0.71 0.28,-0.86
            1.78,1.25 -0.51,1 0.76,-0.78 0.52,0.99 1.85,0.23 0,0.81 0,0 -0.07,0.81 0,0 2.22,-0.27 1.12,-2.39 3.66,0.56 2.31,-2.28 0,0 1.33,1.67
            -0.45,2.58 0.71,0.47 -0.41,1.06 -1.41,0 -1.03,2.65 1.16,2.08 1.18,0.39 -0.38,4.47 0,0 -0.88,3.51 1.43,1.02 0.08,1.18 -1.8,0.7
            -5.24,-2.65 0.22,4.61 -1.81,0.89 -1.12,1.96 -0.83,-0.17 -1.18,2.51 -1.94,1.59 0.28,2.37 1.35,0.76 -2.23,1.84 -0.27,3.22 -1.25,-0.2
            -1.84,1.55 1.84,3.43 -1.15,0.96 0,0 -0.83,-0.04 0,0 -1.4,0.57 0,0 -3.13,0.1 -1.57,1.28 -0.31,-1.21 -1.34,-0.27 -4.4,3.21 -2.14,-0.85
            -0.02,-1.14 -1.8,-0.73 -2.5,-3.47 -2.95,-0.75 -1.23,0.68 -0.32,-1.02 -3.74,1.58 0,0 -2.91,-5.01 1.41,-1.16 -2.57,-0.94 -1.69,0.48
            -2.32,-1.82 0,0 0.61,0 0,0 0.88,-1.46 -2.55,-0.92 2.1,-2.17 -0.11,-0.9 -2.01,-0.93 0.37,-2.87 1.2,-0.1 2.49,-3.42 -2.75,-0.99
            -0.03,-1.28 0.83,0.41 0.63,-1.21 -1.67,-0.63 0,0 1.35,-2.74 2.66,1.36 2.39,-3.5 1.44,0.24 0.43,-1.86 1.05,-0.66 0.62,0.93 3.74,-0.39
            0.53,-1.28 1.76,-0.19 2.99,-3.52 2.81,-1.06 0.35,-1.35 1.92,1.09 1.72,-0.13 0.32,-0.99 1.17,0.1 z" />
        <path
            title="Corse-du-Sud"
            id="FR-2A"
            onClick={() => props.setDepartment('2A - Corse-du-Sud')}
            stroke="blue" fill={props.department.substr(5) === 'Corse-du-Sud' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 571.15408,527.79159 2.81,0.45 5.4,2.69 2.03,0.48 1.74,-0.78 -0.09,1.73 1.93,2.54 4.8,2.82 0.99,-0.22 1.08,4.32 2.21,1.39 0.25,2.83
            1.26,1.64 2.52,-0.15 -0.26,4.77 0.64,0.51 -0.72,0.83 1.6,1.03 -0.83,2.76 3.51,0.96 2.43,-1.89 1.11,0.38 0,0 0.17,8.07 -1.4,1.92 0.68,1.22
            -1.7,1.12 0.29,1.11 -1.93,-0.65 -0.89,2.92 1.32,-1.61 2.12,0.59 -1.07,1.95 -2.89,1.58 0.2,3.66 -2.3,1.2 -0.28,1.83 2.05,-0.85 -2.13,3.13
            -4.98,-1.37 0.86,-2.59 -1.71,-0.1 -0.29,-1.3 -1.15,0.49 -0.9,-1.2 -4.06,-0.59 -1.83,-1.94 -1.39,0.36 0.1,-1.59 -2.41,-0.52 -0.07,-3.46
            0.81,-1.29 0.61,0.67 2.2,-0.96 1.79,-2.26 -4.31,-1.47 -1.27,0.8 -0.28,-2.29 -2.82,1.09 -0.1,-0.98 -1.9,-0.07 2.98,-2 -0.77,-1.43
            2.48,-0.52 0.53,-1.05 -0.86,-0.75 1.03,-0.35 0.61,-2.5 -2.28,-2.11 -1.21,1.4 -3.21,-0.12 -1.42,0.92 0.61,-2.12 -1.23,-1.86 3,-0.7
            -0.19,-1.94 3.62,-2.07 -1.17,-0.77 -0.8,-2.73 -1.69,0.66 -1.96,-1.91 -1.3,0.37 0.38,-1.16 -1.35,0.18 1.49,-1.26 -1.35,-0.18 1,-0.42
            -0.16,-1.49 -0.61,0.04 0.29,-1.3 -1.37,-0.52 6.35,-1.73 -1.38,-1.72 -2.3,-0.36 -0.27,-0.75 1.29,-1.16 -0.86,-0.56 -2.3,0.9 -0.34,-2.31
            1.02,0.34 0.15,-0.67 z" />
        <path
            title="Haute-Corse"
            id="FR-2B"
            onClick={() => props.setDepartment('2B - Haute-Corse')}
            stroke="blue" fill={props.department.substr(5) === 'Haute-Corse' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 606.45408,492.34159 1.68,1.41 1.22,10.22 -1.96,7.41 3.6,7.06 -0.02,9.96 1.16,4.93 -0.05,4.86 -0.37,5.08 -5.72,8.4 -0.39,5.22 0,0
            -1.11,-0.38 -2.43,1.89 -3.51,-0.96 0.83,-2.76 -1.6,-1.03 0.72,-0.83 -0.64,-0.51 0.26,-4.77 -2.52,0.15 -1.26,-1.64 -0.25,-2.83 -2.21,-1.39
            -1.08,-4.32 -0.99,0.22 -4.8,-2.82 -1.93,-2.54 0.09,-1.73 -1.74,0.78 -2.03,-0.48 -5.4,-2.69 -2.81,-0.45 0,0 1.48,-0.3 -0.14,-1.67
            2.12,-0.04 -0.34,-1.42 1.31,-1.49 -1.2,-0.25 0.65,-2.38 2.23,-0.54 -0.45,-2.89 0.61,-0.17 0.15,1.03 2.46,0.19 0.63,-2.49 2.69,-0.33
            0.49,-1.17 1.82,-0.59 3.84,-0.08 1.8,-1.01 -0.07,-1.86 2.82,-2.22 4.03,-0.21 3.19,3.3 1.91,-3.34 -0.1,-3.32 -1.37,-2.15 1.28,-1.84
            -0.76,-1.93 1.51,-1.44 -0.76,-3.87 3.43,-0.98 z" />
        <path
            title="Côte-d'Or"
            id="FR-21"
            onClick={() => props.setDepartment('21 - Côte-d\'Or')}
            stroke="blue" fill={props.department.substr(5) === 'Côte-d\'Or' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 386.21408,251.19159 -0.07,-1.31 3.01,-0.29 0,0 -0.45,2.27 -2.49,-0.67 z m 7.27,-49.07 0.9,-2.25 9.98,-0.41 -0.78,-2.39 2.03,-1.49
            3.54,1.03 1.44,-0.43 0,0 1.36,1 2.31,-0.07 0.67,1.07 -1.02,1.62 2.53,0.11 0.82,1.25 -1.4,1.95 1.2,0.77 0.37,-1.32 1.4,-0.2 0.98,3.06 1.11,0.3
            0.27,1.7 1.39,1.21 -0.19,0.99 -3.05,2.25 1.79,0.31 0.41,4.68 2.5,-1.5 0.03,1.12 1.32,-0.18 -0.3,1.66 1.08,-0.19 1.57,1.53 2.53,-2.08
            -0.2,1.73 2.69,2.2 0.86,-0.36 -0.87,1.58 0.56,1.2 2.27,-2.01 1.3,-0.03 0.71,1.16 0.71,-0.87 0,0 0.93,0.64 1.37,-2.26 2.11,1.54 0.37,4.85
            -2.08,2.24 -1.59,-0.5 -0.48,1.08 0.85,0.24 -1.71,0.78 0.51,0.83 1.13,-0.64 1.34,0.67 -0.27,3.54 2.77,0.2 -0.23,3.38 -1.03,1.06 2.07,0.79
            0,0 -0.6,1.26 -0.68,-0.32 -0.34,4.32 -1.45,1.13 0.59,0.92 -0.84,2.5 -1.27,0.95 -0.96,2.73 -2.38,0.4 -1.83,1.62 -0.35,1.36 1.78,0.84
            -2.58,2 0,0 -2.15,-0.31 -0.38,0.92 -3.09,0.43 -0.96,1.11 0.21,-0.79 -2.21,-1.46 -1.91,0.56 -0.22,0.83 -4.29,-0.5 -0.05,0.95 -4.3,1.14
            -0.37,0.87 -2.43,-0.22 -1.44,1.32 -0.94,-0.93 0.59,-0.92 -2.71,-0.66 -1.97,-3.14 -0.8,0.05 0.29,-1.72 -1.92,0.46 -0.82,-1.26 -2.54,-0.25
            -1.08,-0.92 0.62,-1.5 -0.57,-0.3 -1.99,1.17 -0.34,-2.31 -3.01,-0.4 -1.2,-2.4 -1.34,0.18 -0.1,-0.69 0,0 0.75,-3.25 -2.05,-2.79 -1.86,0.98
            -0.8,-3.29 0.8,-0.86 -0.81,-0.13 1.2,-2.83 -1.52,0.93 0,0 0.01,-2.27 -1.09,0.06 0.41,-1.06 -1.04,-0.87 0.83,-0.81 -0.26,-1.17 0.81,0.59
            1,-1.1 -0.33,-4.09 2.47,-2.26 0,0 0.48,-1.82 0,0 2.65,-4.81 -0.92,-1.15 2.34,-0.21 -1.88,-2.58 0.87,-1.1 1.34,0.53 1.72,-1.44 -0.12,-5.55
            -2.48,0.5 -0.28,-2.13 -0.74,-0.02 2.72,-1.92 -0.77,-1.24 z" />
        <path
            title="Côtes-d'Armor"
            id="FR-22"
            onClick={() => props.setDepartment('22 - Côtes-d\'Armor')}
            stroke="blue" fill={props.department.substr(5) === 'Côtes-d\'Armor' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 91.074077,143.57159 -0.83,1.71 -0.08,-1.33 0.91,-0.38 z m -9.69,-0.23 0.35,2.02 5.31,-1.91 -0.46,2.25 1.21,-0.18 -0.63,0.84
            3.06,-0.19 0.32,1.47 -1.74,0.92 2.78,1.44 1.16,-0.61 0.76,0.88 -0.51,2.32 5.32,4.52 -0.2,3.35 4.490003,2.47 -0.08,2.28 1.26,1.32
            0.14,-2.14 2.11,0.39 3.21,-4.6 3.36,-1.43 -0.62,-1.41 2.13,-0.48 0.79,1.27 4.14,-3.52 1.32,1.58 -2.09,2.71 0.96,0.63 2.93,-2.15
            -0.51,0.53 1.87,3.06 -0.31,1.34 0.9,-2.83 0.7,2.17 1.19,-2.62 1.04,1.14 0,0 1.32,1.86 1.96,0.34 0.07,1.41 1.54,-1.56 0,0 1.46,1.45
            -0.46,2.79 1.35,-2.44 0,0 0.88,-0.74 0.54,0.88 0.29,3.37 -1.74,2.43 0.98,5.94 -1.66,0.29 0.44,3.16 -0.7,-0.6 -2.02,1.28 -1.28,-1.03
            -2.38,1.44 0.68,0.54 -0.48,0.89 -1.88,-0.61 -1.32,0.73 0.04,2.42 -1.89,-0.04 -0.29,4.16 -1.46,-0.43 -0.67,1.02 0,0 -2.57,0.53 -0.35,0.92
            -2.77,-3.88 -4.61,1.01 0.88,1.5 -1.41,3.54 -4.49,2.74 -0.69,-1.92 0.96,-2.16 -0.43,-2.03 -1.8,1.11 -2.11,-0.23 -0.8,1.83 -1.270003,-3.59
            -1.93,0.19 -1.67,-1.43 -2.13,-0.4 -2.38,0.68 -0.64,-2.33 -0.92,-0.37 -3.23,-0.19 -1.1,2.69 -2.15,-0.04 -0.91,1.4 -1.14,-0.56 -1.28,0.7
            -0.73,-0.89 -1.47,0.24 -0.45,-1.28 -3.38,1.55 -0.87,-0.86 0.79,-0.84 -1.08,-0.91 -1.02,0.7 -3.96,-0.62 0,0 1.91,-0.26 -1.59,-2.1
            1.61,-3.56 -1.43,-0.96 1.45,-1.25 -1.39,0.09 -0.15,-3.91 -2.07,-0.64 0.14,-1.98 2.12,-1.64 -2.31,-1.95 0.08,-1.13 2.73,-2.6 -1.37,-1.29
            -0.98,0.22 0.27,-1.7 -2.17,-0.81 -0.74,-3.97 0,0 1.47,-1.72 0.88,1.08 1.42,-0.87 -0.65,-2.62 1.45,-1.35 -1.48,-0.71 0,-2.07 0.73,-0.33
            -0.83,0.04 0.32,-0.75 1.03,0.92 0.81,-0.65 -0.25,-1.31 1.53,-1.04 -0.07,0.91 0.97,-0.79 0.98,1.37 1.29,-0.33 -0.53,1.43 1.77,-0.13
            0.37,-0.97 2.87,-1.42 2.36,0.25 1.58,-2.08 z" />
        <path
            title="Creuse"
            id="FR-23"
            onClick={() => props.setDepartment('23 - Creuse')}
            stroke="blue" fill={props.department.substr(5) === 'Creuse' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 274.01408,298.19159 4.59,-4.84 0.79,2.05 2.68,-1.73 0.79,2 2.37,-1.82 1.8,1.69 1.29,-2.11 -0.52,-1.49 1.47,-0.38 2.33,1.85
            1.9,-0.96 1.95,0.63 1.95,-0.71 4.55,1.99 3.31,-0.87 0,0 4.66,0.28 0,0 0.29,2.3 1.97,0.72 -1.35,1.57 2.6,1.97 0.95,-1.27 1.4,2.9
            0.42,-0.78 2.11,0.96 3.53,8.19 0,0 -0.71,3.4 0.89,2.32 1.25,0.73 -0.36,2.61 0.67,1.43 -1.86,0.62 -0.83,1.46 0.51,1.1 -2.35,1.61
            -0.31,1.35 -1.98,-0.15 0.07,1.28 -2.42,1.05 2.29,4.04 2,1.23 0,0 -2.31,2.28 -3.66,-0.56 -1.12,2.39 -2.22,0.27 0,0 0.07,-0.81 0,0
            0,-0.81 -1.85,-0.23 -0.52,-0.99 -0.76,0.78 0.51,-1 -1.78,-1.25 -0.28,0.86 -1.01,-0.71 -1.96,0.31 -1.43,-2.13 -2.02,0.96 -0.46,1.69
            -1.41,-0.26 -0.5,1.24 -0.82,-0.57 -1.13,0.92 0,0 -1.06,-1.67 0.09,-1.72 1.09,-0.76 -0.77,-1.63 -1.94,-1.74 -0.36,0.93 -2.24,-1.36
            -0.06,-2.04 -1.75,1.48 -2.84,0.42 -2.35,-1.33 -0.51,-1.43 0.97,-0.55 0.2,0.61 0.05,-1.12 0.93,0.45 -0.83,-1.49 0.66,-0.55 -2.18,-0.35
            -0.92,1.01 -2.28,-1.05 2.75,-2.75 -1.83,-1.84 0.74,-1.48 -0.42,-2.69 -2.3,-1.53 0.94,-1.04 -0.45,-1.17 -0.52,0.41 -1.24,-1 0.24,-1.6
            -1.96,0.11 -1.61,-2.29 1.86,-1.61 -0.56,-1.31 1.35,-0.46 -0.99,-0.57 1.21,-1.33 0.06,-1.91 z" />
        <path
            title="Dordogne"
            id="FR-24"
            onClick={() => props.setDepartment('24 - Dordogne')}
            stroke="blue" fill={props.department.substr(5) === 'Dordogne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 241.41408,336.11159 0.96,1.29 2.81,-0.43 2.24,1.67 -0.97,2.94 0.99,1.59 1.25,-0.27 0.28,1.21 1.36,-0.49 0.99,-2.34 1.18,1.28
            2.01,-0.86 3.01,0.29 2.8,4.71 1.26,-0.99 2.1,1.3 -1.89,2.95 1.73,-0.14 0.62,1.25 1.32,-0.37 1.12,0.51 -0.07,0.83 0.78,-0.21 0,0 1.67,0.63
            -0.63,1.21 -0.83,-0.41 0.03,1.28 2.75,0.99 -2.49,3.42 -1.2,0.1 -0.37,2.87 2.01,0.93 0.11,0.9 -2.1,2.17 2.55,0.92 -0.88,1.46 0,0
            -0.61,0 0,0 2.32,1.82 1.69,-0.48 2.57,0.94 -1.41,1.16 2.91,5.01 0,0 -1.58,0.81 0,0 0.18,0.78 0,0 1.17,4.49 -1.2,0.35 1.1,1.98
            -3.19,1.96 0.18,1.9 -2.81,0.88 -0.04,1.17 1.04,0.65 -0.28,1.43 -0.69,-0.2 -0.5,1.59 -0.71,-0.36 -1.92,2.25 -3.13,0.8 0.16,2.03
            -2.37,2.41 0.4,1.17 -1.16,-0.03 0,0 -0.18,-1.32 -2.45,-1.09 -1.36,-1.71 -4.3,1.66 -0.28,1.08 -1.52,-0.19 -0.66,-1.56 1.44,-2.43
            -2.31,-1.87 -1.39,1.3 -3.26,0.15 -2.41,-1.78 -0.09,0.81 -1.53,-0.42 -1.49,1.95 -1.8,-0.72 -3.73,1.96 -2.59,-0.61 -0.31,-3.74
            -2.13,-2.55 0,0 -1.44,-3.67 0.94,0.15 1.09,-1.3 -1.52,-1.25 -1.61,-0.29 0.16,0.9 -2.14,2.18 -1.81,-0.55 -1.52,0.61 -0.48,-0.84
            -1.15,0.85 -2.02,-1.79 -2.33,-0.17 2.82,-3.62 -1.33,-1.94 2.95,-7.2 -1.17,-2.65 -2.17,-0.21 -1.33,1.01 0,0 0.12,-2.24 1.47,-1.12
            0,0 -0.66,-0.63 0,0 0.89,-1.28 0,0 2.09,-2.08 1.82,-0.02 1.51,1.25 1.65,-1.92 0.03,-1.3 1.22,-0.17 0.7,-1.56 1.85,-0.41 -0.79,-4.25
            1.19,-3.41 1.7,-0.57 -0.61,-1.33 1.17,1.02 2.08,-2.58 2.12,-0.01 0.72,-2.23 2.3,-1.64 0.27,-4.8 0.79,-0.73 0.35,0.9 1.12,-0.2 z" />
        <path
            title="Doubs"
            id="FR-25"
            onClick={() => props.setDepartment('25 - Doubs')}
            stroke="blue" fill={props.department.substr(5) === 'Doubs' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 451.92408,242.60159 1.76,0.18 1.45,-1.78 2.79,-0.54 1.58,-1.31 0.64,0.67 0.24,-1.42 1.16,0.35 -0.16,-1.05 2.12,1.23 3.96,-1.59
            0.45,-1.32 1.14,0.75 0.18,-2.18 2.15,0.43 1.04,-0.87 -1,-0.52 1.48,-1.17 1.45,1.11 -0.31,-1.06 0.95,0.35 0.34,-1.35 1.06,-0.04 0.52,-2.68
            0.81,0.36 0.54,-1.3 2.29,0.06 0.89,-1.05 2.15,0.66 0.33,1.54 1.18,-1.49 2.35,1.27 1.12,-1.21 -0.66,-1.3 0.55,-0.83 1.23,0.87 1.58,-0.35
            0.89,-1.29 -0.42,-1.41 4.48,2.62 1.6,-1.51 0,0 0.43,0.92 3.5,-0.14 0.32,1.49 1.46,0.74 0.18,1.71 -1.3,-0.81 -0.43,1.57 1.39,2.47 0,0
            -0.11,1.66 -2.2,1.94 -0.23,1.3 5.7,-1.23 1.35,0.65 0.28,1.74 -1.88,0.56 -0.57,1.83 -2.22,0.46 0.51,2.72 -4.73,4.32 0.73,0.43 -0.83,0.96
            -5.65,4.07 -0.46,0.9 1.14,0.99 -4.07,3.52 -5.17,1.13 -2.7,2.8 1.34,2.37 -1.38,4.64 0.89,2.25 -13.53,11.19 -0.66,0.77 1.15,1.14 0,0
            -3.7,-3 2.34,-2.67 -1.46,-2.26 5.65,-4.53 -2.29,-3.48 -3.59,-2.33 -1.35,0.64 -0.23,-0.85 0,0 0.13,-0.68 0,0 -0.97,0.12 0.11,-1.36
            -1.09,-0.74 0.54,-1.7 -1.61,-0.79 0.79,-1.63 -2.65,-0.73 0.43,-1.73 -1.09,1.27 -2.04,-0.42 -1.37,-0.92 -0.2,-1.77 -0.8,1.54 -1.19,0.58
            -0.75,-0.59 0.4,-1.11 -0.72,-0.95 1.16,0.66 0.87,-0.68 -0.63,-2.38 2.41,-2.8 -1.73,-2.9 -1.98,-0.82 z" />
        <path
            title="Drôme"
            id="FR-26"
            onClick={() => props.setDepartment('26 - Drôme')}
            stroke="blue" fill={props.department.substr(5) === 'Drôme' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 414.61408,360.50159 2.41,-0.75 0.86,0.75 4.5,-2.75 0.87,0.11 0.54,1.41 1.29,-0.06 0.9,2.18 2.57,-0.9 -0.71,2.92 1.6,-0.43 1.77,2.18
            -1.47,0.97 0.97,1.88 -0.15,2.86 -2.08,2.73 1.22,0.43 0.7,-1.09 1.2,0.07 2.41,1.7 2.99,-0.41 1.84,1.59 0.07,-1.39 1.14,0.74 1.98,-2.37
            0.88,0.13 0.47,0.71 -0.8,0.07 -0.42,1.49 1.18,2.82 -1.21,9.96 0.81,0.22 -1.08,1.34 4.01,1.87 -0.25,-1.6 3.23,2.45 0.86,1.66 2.38,-0.28
            2.19,1.94 1.73,-0.6 0,0 1.31,0.93 -1.7,2.15 -1.67,-0.5 -0.84,1.34 -3.63,-0.64 0.26,2.21 -2.11,3.94 2.76,2.46 -1.45,0.09 -1.1,2.02
            -5.98,-1.97 0.27,2.98 1.36,0.72 -0.83,0.92 -1.6,-0.81 -0.82,0.5 1.03,1.93 -0.42,1.18 1.2,0.21 1.21,1.67 1.25,-0.72 1.1,1.19 3.38,-0.19
            -0.88,1.58 1.19,0.32 0.44,1.95 1.64,0 -0.44,4.38 0,0 -1,0.1 -0.36,1.19 1.64,0.3 -0.17,0.99 -1.96,-0.24 0.35,-1 -1.25,-1.3 -1.31,0.13
            -0.56,1.01 0.59,1.07 -3.33,2.09 0,0 -1.94,-0.27 -0.68,-1.8 -2.18,-0.2 0.08,-2.6 -1.23,-0.81 -0.83,0.66 -3.57,-1.65 -0.81,1.16 -3.09,-1.09
            -0.33,-2.79 0.84,-2.1 -2.46,2.16 -1.33,-0.37 -0.69,-1.37 -5.72,2.76 -1.76,-0.06 -2.53,1.93 -1.26,-4.96 -5.73,-0.97 0,0 0.65,-5.64 1.44,-1.98
            -0.29,-5.72 1.98,-1.66 1.64,-4.17 -0.8,-6.56 3.42,-4.13 0.43,-3.23 1.33,-2.24 -2.11,-4.11 1.04,-2.93 -1.27,-0.83 -1.1,-3.05 1.17,-1.92
            -0.95,-0.64 -0.33,-7.55 z m 5.06,51.84 0,0 -0.09,-0.06 0,0 -0.48,0.7 0,0 -1.51,2.73 0.87,0.64 -0.22,1.98 4.05,0.68 1.51,-3.92 1.98,-0.93
            0,0 0.01,-0.35 0,0 -2.32,-0.57 0.12,-1.33 -0.67,0.2 0,0 -0.58,-0.63 0,0 -0.43,-0.05 0,0 -0.77,0.2 0,0 -0.68,0.39 -0.79,0.32 z" />
        <path
            title="Eure"
            id="FR-27"
            onClick={() => props.setDepartment('27 - Eure')}
            stroke="blue" fill={props.department.substr(5) === 'Eure' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 227.60408,107.59159 3.59,-0.74 4.82,-2.59 3.57,3.42 2.32,-0.81 -0.56,1.44 0.98,0 0.41,1.23 4.31,-1.07 0.81,0.57 0.68,-1.12 0.79,1.97
            2.36,-0.01 0.06,1.56 1.61,-0.82 0.07,2.95 -2.46,-0.53 -0.64,1.45 2.25,0.51 0.15,1.16 1.55,-1.56 0.99,1.73 -0.33,1.53 0.7,-0.61 1.42,1.89
            2.34,-0.48 -0.61,-2.54 3.63,-0.7 0.98,-2.04 1.85,0.36 -0.17,-1.2 3.08,0.56 1.51,-5.27 1.12,0.05 0.32,-1.28 1.75,-0.93 -0.1,0.91
            3.86,-0.18 2.5,1.38 1.56,-0.59 1.22,1.75 2.1,0.8 1.02,-0.47 0.58,0.76 0.83,-0.81 0,0 1.07,0.11 -0.76,0.78 1.64,1.74 0.47,4.74 1.05,0.11
            0.26,0.98 -0.62,1.81 -1.29,-1.67 -2.02,0.49 -0.24,2.09 0,0 -1.23,1.29 -0.69,5.04 -2.03,3.42 0,0 -3.56,0.59 -0.53,-1.11 -0.88,2.2
            -1.07,-0.84 -0.6,0.66 1.26,2.49 -0.34,2.41 2.02,-0.17 -1.1,0.72 0.36,1.69 0,0 0,0 0,0 -2.21,1.05 0.97,1.68 -0.46,1.57 -4.08,2.36
            0.43,3.35 -1.79,1.34 -2.99,0.15 -0.56,-0.8 -0.88,0.76 -4.19,-1.97 -0.28,2.71 -2.14,-0.76 -2.02,2.06 0,0 -1.18,0 0,0 -2.66,1.03
            -1.9,-0.4 -0.53,1.74 -2,1.11 0,0 -1.86,0.04 -0.07,-1.62 -1.73,-0.73 1.91,-1.93 -0.66,-1.87 -6.12,-4.07 1.12,-1.41 -1.09,-0.02 0.22,-1.44
            -1.08,0.36 -0.37,-1.32 -1.24,1.17 -5.33,-0.77 -1.6,-1.61 1.25,-2.37 0,0 1.36,-4.4 -3.29,-2.1 1.14,-1.58 1.03,0.15 -0.79,-1.95 1.35,-2.19
            -2,-0.81 0.39,-3.58 -3.07,-2.39 2.62,-1.02 0,-1.35 -0.87,-0.74 -1.5,1.21 -0.71,-0.34 0.58,-0.83 -1.16,-1.23 1.22,-1.53 -0.99,-1.99 z" />
        <path
            title="Eure-et-Loir"
            id="FR-28"
            onClick={() => props.setDepartment('28 - Eure-et-Loir')}
            stroke="blue" fill={props.department.substr(5) === 'Eure-et-Loir' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 277.59408,138.79159 2.57,3.15 -0.72,1.27 1.61,0.65 -0.32,0.96 0.89,0.33 -0.79,0.54 0.09,4.39 1.88,0.9 -2.07,2.76 1.46,0.88
            -0.33,1.68 1.58,0.76 0.98,2.24 2.03,-0.02 -0.25,2.34 2,0.17 1.24,1.3 -0.47,2.52 1.07,2.91 1.42,-0.03 0.32,1.28 1.09,0.39 1.55,0.02
            0.62,-1.12 0,0 0.84,1.04 -0.6,2.2 2.06,0.28 0.09,5.2 -0.91,0.47 1.5,1.46 0,0 -1.33,2.53 0.72,2.63 -0.33,0.75 -0.26,-0.66 -0.66,0.44
            0.95,1.34 -2.02,-0.03 -0.82,0.86 0.65,0.85 -1.51,3 -2.24,1.16 -1.11,-0.68 -0.19,0.82 -2.19,0.83 -2.33,-0.87 -0.76,0.91 -0.45,-0.85
            -1.44,0.41 -0.17,1.72 -0.87,-0.09 -0.2,0.82 -2.03,-0.83 0.11,0.88 -1.51,0.17 0.38,2.78 0,0 -2.95,-1.53 -0.54,1.89 -2.07,-0.32 -0.71,1.68
            -4.34,-0.45 -0.78,-1.07 -2.08,0.68 -1.29,-3.73 -1.73,-0.42 -0.45,-2.9 -1.58,0.44 -0.44,-0.77 -2.96,-0.23 2.24,-1.71 -0.26,-0.87
            -1.6,0.13 -2.18,1.97 -0.31,-0.89 -4.21,0.57 0,0 0.41,-1.83 2.69,-0.34 -4.9,-3.44 0,0 1.35,-1.2 -1.75,-2.94 0.75,-1.75 -2.11,-0.66
            0.61,-1.42 0.73,-1.17 5.25,-1.97 2.57,-4.22 -1.49,-0.99 -0.17,-1.27 0.81,-0.36 -0.55,-1.11 1.01,-1.47 -1.75,-0.83 0.63,-0.79 -0.56,-0.67
            -3.09,-1.39 -0.18,-1.7 -1.17,-0.15 0.54,-1.36 -0.68,-2.27 0,0 2,-1.11 0.53,-1.74 1.9,0.4 2.66,-1.03 0,0 1.18,0 0,0 2.02,-2.06 2.14,0.76
            0.28,-2.71 4.19,1.97 0.88,-0.76 0.56,0.8 2.99,-0.15 1.79,-1.34 -0.43,-3.35 4.08,-2.36 0.46,-1.57 -0.97,-1.68 z" />
        <path
            title="Finistère"
            id="FR-29"
            onClick={() => props.setDepartment('29 - Finistère')}
            stroke="blue" fill={props.department.substr(5) === 'Finistère' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 2.9140771,168.75159 1.57,1.49 -2.76,1.41 -0.72,-0.11 0.88,-1.1 -1.73000003,0.47 2.76000003,-2.16 z m 46.9999999,-16.42 0.76,0.16
            -0.2,2.77 1.12,0.83 0.79,-0.41 -0.1,-1.72 0.59,1.8 2.32,-0.25 0.37,0.85 -0.37,-1.82 0.98,-1.57 0.45,0.86 -0.1,-1.16 1.56,1.18 2.62,-0.33
            0.97,1.05 2.28,-0.43 -0.61,2.33 0,0 0.74,3.97 2.17,0.81 -0.27,1.7 0.98,-0.22 1.37,1.29 -2.73,2.6 -0.08,1.13 2.31,1.95 -2.12,1.64
            -0.12,1.99 2.06,0.63 0.15,3.91 1.39,-0.09 -1.45,1.25 1.43,0.96 -1.61,3.56 1.59,2.1 -1.91,0.26 0,0 -6.21,2.77 -0.83,1.91 0.07,0.96
            1.55,0.12 -0.55,1.66 1.45,0.5 1.44,4.62 4.48,-0.25 1.69,0.85 0.96,1.82 2.1,-1.75 1.04,0.99 0.22,1.98 -1.35,1.29 0.85,2.6 -2.06,-0.04
            -0.36,1.67 -1.38,0.8 -1.56,-1.35 -0.25,5.07 0,0 -6.17,-0.86 -1.85,-1.73 -1.43,0.9 -3.85,-0.02 -2.68,-4.06 0.48,-0.48 -1.98,-1.88
            -1.4,0.7 0.6,1.78 -2.74,0.61 -2.7,-1.66 -1.69,0.91 -1.86,3.72 -7.31,0.01 -0.54,-1.5 1.23,-0.5 0.12,-1.42 -3.03,-6.84 -3.94,-2.7
            -1.36,-0.34 -0.67,0.73 -2.79,-1.78 -2.69,0.17 -1.64,-0.81 1.25,-0.28 -0.32,-1.3 6.73,-0.72 0.69,-0.87 2.06,0.21 2.89,-1.25 2.24,-0.15
            2.38,1.25 0.66,-0.71 0.67,-3.32 -0.94,-0.32 -0.82,-2.77 -7.18,-1.99 -2.13,4.12 -0.78,-0.04 0.27,-2.45 -0.83,-1.53 1.2,-0.91 -3.16,-0.93
            -0.37,-0.97 2.43,-0.16 -0.07,-3.02 1.63,-0.64 -1.02,2.37 0.85,1.16 0.7,-0.72 0.66,0.92 3.48,-0.83 0.85,1.21 3.78,-1.44 1.48,0.11
            0.44,1 1.62,-2.09 -1.13,0.7 -3.67,-0.78 1.45,-1.07 -1.1,-1.43 -1.59,0.63 -0.28,1.1 -1.77,0.16 0.39,-0.9 -2.1,0.78 2.41,-3.53 -0.98,-0.78
            -0.53,0.89 -1.97,-0.05 -5.13,2.74 -3.06,-1.06 -1.1,1.49 -2.71,0.13 -0.63,-2.31 1.1,-0.75 -1.4,-2.18 1.42,-3.36 -0.64,-2.96 1.03,-1.8
            1.66,-0.64 0.33,-1.01 3.87,-0.41 0.27,-1.63 1.67,0.29 -0.22,-1.41 1.14,-0.23 -0.02,-0.81 2.69,0.83 1.54,-1.11 1.41,0.18 -1.25,-1
            3.79,-1.42 2.04,0.79 -0.66,1.44 0.67,0.5 0.9,-0.98 3.13,-0.2 -0.99,-0.38 1.5,-1.77 3.59,-0.53 1.69,1.2 0.25,-1.86 2.91,-1.19 z m
            -1.52,-1.83 1.25,0.92 -1.94,-0.14 0.69,-0.78 z" />
        <path
            title="Gard"
            id="FR-30"
            onClick={() => props.setDepartment('30 - Gard')}
            stroke="blue" fill={props.department.substr(5) === 'Gard' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 381.26408,409.37159 1.64,0.8 -0.07,1.53 1.36,0.82 -1.18,0.81 0.73,1.6 -0.9,2 0.65,0.69 3.23,-1.12 0.54,1.35 1.48,-0.28 3.27,3.06
            1.3,-1.58 -0.03,-1.32 1.6,-1.34 2.73,-0.47 0.15,3.24 1.76,0.42 0.63,-3.19 2.16,-0.31 0.28,0.87 1.53,0.19 0.4,1.34 3.77,1.83 0,0 0.98,3.18
            1.42,0.07 0.59,1.55 -0.68,4.58 0.64,1.52 2.74,0.91 2.39,3.37 -0.12,1.26 -1.19,-0.09 0.07,1.37 -3.05,2.32 0,0 -4.11,3.22 1.07,1.25
            -1.01,0.81 0.35,2.8 -1.61,3.22 0.63,2.29 -3.65,-1.24 -2.27,0.57 -2.47,4.19 1.9,0.66 -0.43,1.32 -5.32,3.08 -0.83,-1.11 0.41,1.55
            -3.4,1.65 -0.44,2.25 0,0 -3.79,-1.3 -0.71,-2.13 0.71,-0.67 -1.65,-1.3 0,0 0.37,-0.74 -1.14,-1.31 2.92,0.27 1.78,-3.22 -2.09,-5.43
            -3.74,-2.02 -1.93,-2.54 -1.76,0.73 0.62,-2.26 -2.25,-0.85 -0.37,-1.76 -2.71,1.17 -1.96,-0.65 0.98,-3.28 -1.38,-0.43 -0.29,-1.32
            -2.28,-0.29 -1.99,1.09 -0.53,2.52 -1.98,-0.45 -1.9,2.64 0.9,1.06 -0.88,0.53 -2.1,-0.64 -0.64,-2.41 -3.36,1.93 -0.46,-0.53 0.87,-0.88
            -0.73,-1.39 -2.73,-0.13 0,0 -0.05,-2.03 2.01,-1.18 1.78,-3.08 -2.54,-1.97 -1.55,0.47 -1,-1.68 -1.38,0.63 -1.31,-1.44 2.47,-0.87
            0.54,-2.89 1.57,-0.72 0,0 3,2.33 4.08,0.91 3.73,-0.42 0.86,-1.42 -1.04,-0.44 0.41,-1.34 1.39,-0.57 5.21,3.41 1.4,-0.58 1.85,0.65
            2.17,-2.04 -0.15,-1.22 2.1,1.15 -0.71,-1.78 -0.83,-0.1 0.44,-2.78 1.15,-0.83 -1.71,-1.02 -0.44,-1.71 1.21,-1.54 -2.76,-3.22 1.3,-1.03
            1.34,0.3 2.02,-3.45 z" />
        <path
            title="Haute-Garonne"
            id="FR-31"
            onClick={() => props.setDepartment('31 - Haute-Garonne')}
            stroke="blue" fill={props.department.substr(5) === 'Haute-Garonne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 254.84408,448.12159 4.3,-0.61 1.1,-0.99 1.1,1.12 1.66,-1.51 1.25,1.55 1.49,0.13 -0.1,1.46 2.49,-1 -0.27,-0.77 3.79,-1.07
            -0.14,-1.08 -2.5,-0.55 2.65,-0.34 -0.45,-1.03 0.71,-1.15 3.5,1.09 1.15,-2.18 0.61,1.23 1.69,-2.05 0.93,0.2 0,0 0.02,2.92 1.57,1.41
            -0.18,1.54 2.3,0.95 0.25,2.88 2.33,1.92 -1.87,0.66 0.11,0.65 2.39,0.29 0.38,1.76 -1.27,0.49 -0.43,1.31 6.22,2.77 0.47,1.64 2.68,2.73
            3.1,1.34 1.09,-0.56 -0.08,-1.2 0.93,0.52 0.13,-0.71 0.32,1.49 -1.42,2.05 0.67,0.82 0,0 0.37,0.65 -2.08,0.88 -1.4,-1 -1.54,0.31 -0.6,1.55
            -2.11,-2.79 -0.46,1.56 -1.01,0.25 0.39,0.85 -1.03,0.19 0.5,2.92 -3.77,-0.04 0.54,1.58 -1.19,0.52 -0.74,1.8 0,0 -0.68,-0.43 -1.52,2.52
            0.04,-1.1 -2.74,-1.07 -0.27,1.2 -0.71,-1.38 -1.17,0.48 -0.08,-1.22 -0.85,-0.09 0.46,4.05 -0.95,-0.29 -0.76,1.02 -2.08,-1 0.57,-1.59
            -2.21,-1.98 -0.18,-1.25 -1.66,-0.32 -1.66,1.56 0.06,1.29 3.34,1.21 0.12,1.6 -2.38,1.51 -3.61,0.09 -0.81,1.47 2.47,0.82 1.18,1.48
            -1.69,0.72 0.04,1.01 -1.58,0.31 -1.37,-2.58 -2.8,-1.32 -1.46,1.21 -1.45,-0.71 -0.62,2.61 -1.56,-0.82 -0.43,1.25 0.67,0.57 -1.14,0.57
            0.32,2.6 1.51,0.9 -1.92,2.08 -4.24,0.89 0.4,1.44 -2.44,1 1.32,5.14 0,0 -6.21,-2.15 -1.28,0.37 -0.79,0.94 0.45,1.99 -1.03,1.12 0.9,0.46
            -0.8,1.29 1.34,1.62 -0.17,1.81 -3.25,-0.82 -3.33,0.79 -1.72,-0.46 0,0 0.35,-0.58 -1.47,-1.31 1.19,-8.14 3.53,0.96 1.58,-3.79 1.92,-1.85
            -1.34,-1.21 0.57,-0.92 -0.82,-2.02 -3.3,1.67 0.39,-2.07 1.12,0.34 0.05,-2.25 -2.67,-1.18 -0.48,-1.09 -1.49,0.25 0.35,-0.63 -0.92,-0.51
            3.37,-3.47 -0.18,-1.19 2.32,-0.43 -0.92,-0.91 3.5,-3.37 -1.14,-0.82 0,0 2.4,-0.7 2.03,-3.3 0.83,0.56 1.3,-2.63 1.69,0.97 0.55,-0.99
            4.03,0.6 2.94,2.37 0.73,-0.81 -0.52,-1.72 1.54,-0.48 -0.49,-0.71 1.11,-1.6 -1.16,-0.8 2.32,-1.83 -0.92,-0.66 1.07,-0.62 -1.07,-0.76
            1.57,-0.36 0.51,0.77 0.52,-1.37 3.61,-0.7 -0.69,-2.48 -1,0.45 -0.73,-2.09 -2.25,-0.18 0.6,-0.88 -2.2,-0.83 0.72,-1.49 -3.16,-2.2 z" />
        <path
            title="Gers"
            id="FR-32"
            onClick={() => props.setDepartment('32 - Gers')}
            stroke="blue" fill={props.department.substr(5) === 'Gers' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 218.40408,436.89159 2.26,-1.07 1.1,1.65 2.4,-2.87 3.58,1.84 0.59,-1.08 2.75,0.17 2.56,-1.3 0.78,-1.57 3.94,0.05 1.53,-1.45
            2.94,3.22 3.25,-2.38 0,0 0.96,2.05 2.35,-1.47 1.35,0.46 -0.46,1.35 -1.23,-0.07 0.51,1.7 -2.79,3.48 0.49,0.81 3.81,-0.07 1.7,1.43
            -0.92,1.29 1.22,1.31 -0.69,0.76 1.26,0.56 -1.24,2.21 2.44,0.22 0,0 1.52,2.77 3.16,2.2 -0.72,1.49 2.2,0.83 -0.6,0.88 2.25,0.18 0.73,2.09
            1,-0.45 0.69,2.48 -3.61,0.7 -0.52,1.37 -0.51,-0.77 -1.57,0.36 1.07,0.76 -1.07,0.62 0.92,0.66 -2.32,1.83 1.16,0.8 -1.11,1.6 0.49,0.71
            -1.54,0.48 0.52,1.72 -0.73,0.81 -2.94,-2.37 -4.03,-0.6 -0.55,0.99 -1.69,-0.97 -1.3,2.63 -0.83,-0.56 -2.03,3.3 -2.4,0.7 0,0 -6.79,-1.72
            -0.09,0.71 -1.28,-0.05 -0.91,-1.55 -2.39,0.74 -0.29,-1.81 -0.67,0.43 -0.49,-1.18 -4.75,1.04 -0.36,-1.85 -0.86,0.22 -0.87,-1.41 1.34,-1.2
            -1.95,-4.02 -2.67,-0.18 -2.29,-2.7 -0.49,-2.29 -2.46,-0.05 -0.95,1.38 0,0 -2.66,0.09 -0.69,-0.9 -2.72,0.65 0,0 -0.19,-1.76 -1.27,-0.04
            -0.17,-1.5 0.8,0.36 -0.02,-0.91 0.88,-0.14 -0.32,-3.01 2.29,-1.72 -0.99,-0.75 -0.33,-3.31 1.37,-0.1 -0.62,-2.74 0.93,-0.41 -0.19,-0.97
            -2.03,-0.9 2.49,-2.4 0.83,0.66 1.38,-0.89 1.26,0.85 0.09,-1.12 2,-0.75 0.38,-1.33 1.79,1.74 -1.16,1.43 3.32,1.89 0.71,-1.19 -0.88,-2.45 z" />
        <path
            title="Gironde"
            id="FR-33"
            onClick={() => props.setDepartment('33 - Gironde')}
            stroke="blue" fill={props.department.substr(5) === 'Gironde' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 171.17408,344.92159 0.89,1.47 -0.8,0.05 -0.32,1.4 5.59,4.22 5.31,5.54 4.79,15.18 3.9,4.58 0.51,-0.37 -3.56,-6.7 -1.94,-11.61 0,0
            4.97,0.42 0.34,-1.52 0.93,2.95 3.84,0.15 2.08,1 -0.14,1.69 0.82,0.48 -0.61,1.87 1.56,3.79 1.37,-1.53 1.52,1.84 0.26,-0.64 1.15,0.36
            0.89,1.63 2.82,1.2 1.96,0.28 2.01,-1.86 2.31,1.15 0,0 1.32,-1.01 2.17,0.21 1.17,2.64 -2.95,7.2 1.33,1.94 -2.82,3.62 2.33,0.18
            2.02,1.79 1.15,-0.85 0.49,0.84 1.52,-0.61 1.8,0.55 2.14,-2.18 -0.15,-0.89 1.6,0.29 1.52,1.24 -1.09,1.31 -0.94,-0.15 1.44,3.67 0,0
            -1.68,0.72 -1.24,-0.86 -0.37,2.39 -0.71,0.13 -0.56,-1.75 -0.52,1.06 -1.4,-0.15 -0.29,1.82 -0.61,-0.48 -0.8,0.69 1.5,2.03 2.01,0.29
            -0.84,1.66 -1.03,-0.32 0.5,1.64 -2.87,1.49 -0.51,2.01 -2.81,-0.07 0.78,1.17 -1.46,1.65 0.11,2.44 0.89,0.65 -1,1.76 1.62,2.46 -4.26,1.23
            -0.27,0.96 2.05,2.41 0.09,1.42 -2.22,2.06 -1.48,-0.26 -0.77,0.81 0,0 -2.12,-2.66 -1.46,0.49 0.04,3.48 -1.94,0.74 -4.99,-0.89 0.23,-4.61
            -1.97,-0.82 -0.05,-1.21 -3.5,-0.94 -0.38,-1.39 -4.77,-2.22 0.66,-0.82 -0.71,-2.05 -5.57,0.63 -1.35,1.16 -4.58,-1.37 -4.51,1.22 1.94,-3.38
            -0.21,-1.55 -1.64,0.05 -2.39,-1.47 -0.93,1.74 -6.14,2.02 0,0 -0.11,-4.44 2.7,-6.77 2,0.21 0.12,0.89 5.67,0.33 -0.83,-2.51 -1.89,-0.97
            1.48,-0.02 -5.54,-4.62 -0.26,1.77 -2.27,2.55 -0.77,5.03 -0.63,-1.04 4.13,-38.94 0.22,-10.45 2.59,-4.77 1.39,-1.02 -0.01,0.75 z" />
        <path
            title="Hérault"
            id="FR-34"
            onClick={() => props.setDepartment('34 - Hérault')}
            stroke="blue" fill={props.department.substr(5) === 'Hérault' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 354.70408,440.87159 2.73,0.13 0.73,1.39 -0.87,0.88 0.46,0.53 3.36,-1.93 0.64,2.41 2.1,0.64 0.88,-0.53 -0.9,-1.06 1.88,-2.62
            1.98,0.45 0.53,-2.52 1.99,-1.09 2.28,0.29 0.29,1.32 1.38,0.43 -0.98,3.28 1.96,0.65 2.71,-1.17 0.37,1.76 2.25,0.85 -0.62,2.26 1.76,-0.73
            1.93,2.54 3.74,2.02 2.09,5.43 -1.78,3.22 -2.92,-0.27 1.14,1.31 -0.37,0.74 0,0 -2.94,-0.05 -5.12,2.28 -4.5,4.26 -6.34,3.29 -5.27,6.07
            -3.7,-0.8 -3.32,0.63 -4.47,3.79 0,0 -0.29,-0.28 0,0 -0.95,-1.05 0,0 -0.7,-0.78 -1.53,0.4 -0.6,-0.95 -2.59,-0.11 -1.06,-1.08 -1.79,0.34
            -0.27,-2.61 -2.41,0.49 -2.33,-0.95 0,-2.13 -0.95,-0.96 0.06,2.93 -2.59,0.58 0.44,0.69 -1.87,2.84 -2.74,-0.93 -0.76,-2.77 -0.56,1.23
            -2.37,0.97 -1.31,-2.76 -1.96,-0.74 0.9,-2.72 1.33,-0.56 -0.88,-0.95 0,0 3.98,-2.84 -0.53,-1.57 0.15,-1.15 0.64,0.09 -2.44,-2.89
            1.25,-5.35 3.09,0.87 1.46,1.75 2.43,-1.51 3.94,-0.86 1.13,-2.21 0,0 2.05,-0.82 3.18,0.9 -0.34,-2.99 0.93,-1.35 -1.07,-1.91 0.61,-1.9
            5.95,1.34 1.71,-1.01 -0.58,-1.9 1.22,-1.82 3.28,0.03 0.66,-1.12 z" />
        <path
            title="Ille-et-Vilaine"
            id="FR-35"
            onClick={() => props.setDepartment('35 - Ille-et-Vilaine')}
            stroke="blue" fill={props.department.substr(5) === 'Ille-et-Vilaine' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 127.57408,157.44159 0.65,0.66 2.04,-0.37 -0.32,0.8 0.91,-0.06 0.08,1.45 1.41,1.28 -0.44,1.02 0,0 -1.54,1.56 -0.07,-1.41 -1.96,-0.34
            -1.32,-1.86 0,0 -1.11,-2.1 1.67,-0.63 z m 11.13,-3.92 0.35,1.66 -1.52,2.1 1.49,2.04 5.63,0.34 5.33,-1.66 0,0 3.46,9.09 1.58,0.12
            1.21,1.64 2.8,-0.5 1.05,-1.87 2.05,-0.66 1.05,-2.15 7.63,2.23 0,0 -0.46,5.85 1.49,5.35 -2.35,4.09 2.13,10.7 1.22,1.36 -0.5,2.28 0.7,1.78
            -1.38,1.17 -3.08,-0.14 -0.07,0.95 -1.22,0.39 -0.89,4.12 -0.82,0.48 0.37,1.4 -1.43,0.99 0.07,1.95 -0.75,0.63 0,0 -0.36,2.05 0,0
            -4.84,-1.39 -1.03,-1.79 -3.76,-0.44 0.24,1.99 -6.19,2.43 -1.49,3.29 -1.49,-0.17 -1.15,0.93 -5.75,-0.67 -3.94,2.29 -0.76,-1.38 -5.19,3.85
            0,0 -1.29,-5.65 0.76,-0.36 0,0 0.17,-0.5 0,0 2.05,-0.11 -0.15,-0.79 -2.74,-1.18 0,0 0.63,0 0,0 3.2,-3.87 -0.77,-2.19 -1.41,1.6
            -0.93,-0.33 0.58,-0.81 -0.79,-1.8 1.53,-1.78 -2.75,-3.97 -1.22,0.28 -2.64,-1.25 -2.13,0.53 1.05,-3.01 0.82,-0.83 2.27,0.02 0.85,-1.44
            -2.15,-0.33 -1.47,1.11 0.07,-2.73 -1.38,-1.69 0,0 0.67,-1.02 1.46,0.43 0.29,-4.16 1.89,0.04 -0.04,-2.42 1.32,-0.73 1.88,0.61 0.48,-0.89
            -0.68,-0.54 2.38,-1.44 1.28,1.03 2.02,-1.28 0.7,0.6 -0.44,-3.16 1.66,-0.29 -0.98,-5.94 1.74,-2.43 -0.29,-3.37 -0.54,-0.88 -0.88,0.74 0,0
            -0.58,0.14 -0.89,-2.99 1.38,0.32 -2.45,-1.54 0.44,-0.71 -1.29,-1.21 0.1,-1.05 1.68,-0.85 0.1,-1.27 1.71,0.13 -0.53,-0.41 1.01,-0.82
            1.23,0.78 2.58,-1.06 z" />
        <path
            title="Indre"
            id="FR-36"
            onClick={() => props.setDepartment('36 - Indre')}
            stroke="blue" fill={props.department.substr(5) === 'Indre' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 270.35408,247.49159 5.07,-3.27 2.25,0.19 1.18,1.01 -0.29,-2.18 2.82,-0.16 -0.03,-1.15 0.81,-0.11 2.31,1.24 2.03,-1.16 2.52,2.71
            2.66,0.76 0,0 0.1,2.52 -2.81,2.99 4.19,1.66 1.95,0.01 2.96,-1.49 0.38,1.96 1.86,-0.79 0.24,2.14 -1.09,1.48 3.29,3.11 -1.5,3.83 1.62,1.23
            1.85,0.1 -1.15,1.87 -1.81,0.74 0.52,0.81 -0.84,1.24 -1.37,-0.32 3.07,3.6 -0.9,1.54 -1.16,0.22 0.81,2.31 2.8,1.23 -0.65,1.65 2.09,1.32
            -1.28,5.24 1.93,4.35 -2.18,1.54 0.66,2.03 0,0 -3.31,0.87 -4.55,-1.99 -1.95,0.71 -1.95,-0.63 -1.9,0.96 -2.33,-1.85 -1.47,0.38 0.52,1.49
            -1.29,2.11 -1.8,-1.69 -2.37,1.82 -0.79,-2 -2.68,1.73 -0.79,-2.05 -4.59,4.84 0,0 -2.55,-3.22 -1.69,0.29 -0.4,1.41 -1.86,-0.41 -1.67,0.75
            -0.39,-1.46 -0.78,0.81 -0.51,-0.5 0,0 1.4,-2.93 -1.2,0.33 0.2,-0.76 -1.54,-0.46 -0.66,-1.37 0.94,0.09 -0.95,-1.67 0.49,-0.48 -2.22,-1.86
            -3.04,0.03 -0.22,-1.85 -2.11,-0.26 -2.2,-1.8 -0.75,-1.7 1.44,-4.11 -0.59,-1.77 -1.87,-1.28 0,0 2.43,-0.55 1.32,1.19 1.04,-1.61 1.16,0.34
            -1.1,-2.76 1.04,-0.64 1.09,-7.55 1.18,-0.97 -0.39,-2.45 1.96,-1.92 2.74,-0.78 2.63,1.62 0.92,-0.53 1.56,-3.06 0.8,0 0.06,-1.78 2.15,-0.94 z" />
        <path
            title="Indre-et-Loire"
            id="FR-37"
            onClick={() => props.setDepartment('36 - Indre-et-Loire')}
            stroke="blue" fill={props.department.substr(5) === 'Indre-et-Loire' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 240.85408,216.28159 0.95,-0.91 0.36,1.57 1.06,-0.95 1.53,1.12 1.19,-0.86 3.78,1.19 1.54,-0.86 -0.98,2.81 0.77,2.81 1.55,-0.32
            0.84,-1.72 2.97,0.75 0.24,2.2 1.5,-1.48 1.24,2.51 0.59,-0.33 -1.35,2.49 1.03,0.53 0.71,3.72 1.07,-0.21 0.88,1.04 -0.84,1.29 -1.21,-0.13
            1.59,2.37 -0.79,1.34 0.76,1 -1.04,1.5 0.32,1.39 2.42,1.98 0.52,-0.91 2.83,0.07 3.47,6.21 0,0 1.52,4.13 -2.15,0.94 -0.06,1.78 -0.8,0
            -1.56,3.06 -0.92,0.53 -2.63,-1.62 -2.74,0.78 -1.96,1.92 0.39,2.45 -1.18,0.97 -1.09,7.55 -1.04,0.64 1.1,2.76 -1.16,-0.34 -1.04,1.61
            -1.32,-1.19 -2.43,0.55 0,0 -2.18,-2.48 -0.78,-3.66 -1.82,-0.6 -1.99,-2.64 -0.53,-4.28 -2.39,-0.74 -0.68,-1.31 -2.07,0.09 1.27,2.97
            -3.85,-0.1 -2.78,1.78 -3.04,-1.21 -1.73,1.14 -1.34,-3.64 0.89,-0.48 -0.4,-3.21 -1.44,0.52 -0.05,-1.51 -0.91,-0.25 -1.33,1.07 -1.44,-0.31
            0.9,-1.98 -0.76,-1.44 -0.82,0.86 -1.13,-0.38 0.22,-1.08 -2.49,0.36 -0.04,-1.63 -1.05,-1.1 0,0 0.55,-1.53 -0.6,-0.39 1.08,-1.34 -0.08,-3.9
            2.88,-3.98 -0.3,-0.9 1.78,-1.23 -1.22,-1.08 2.85,-6.17 0.19,-1.66 -1.33,-0.73 1.62,-3.37 0,0 -0.19,-0.91 0,0 1.29,-0.13 4.95,2.62 1.02,-0.62
            -1.6,-2.52 0.78,-1.47 1.7,1.67 1.04,-0.17 0.61,-1.58 2.2,-0.71 0,0 0.87,-0.03 0,0 2.41,-0.92 z" />
        <path
            title="Isère"
            id="FR-38"
            onClick={() => props.setDepartment('38 - Isère')}
            stroke="blue" fill={props.department.substr(5) === 'Isère' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 427.08408,329.96159 0.21,0.95 0.34,-0.78 1.44,0.3 1.64,1.96 1.41,0.23 1.94,-1.36 1.32,-3.32 2.08,-2.12 3.27,2.82 -0.32,1.78 5.39,5.73
            -0.42,0.99 2.36,2.01 1.02,2.67 0,0 2,4.14 0,0 0,0.65 0,0 1.64,1.49 1.1,3.96 2.1,-0.05 4.16,2.92 0.77,0.09 0.77,-1.43 -1.04,0.35 0.51,-4.04
            2.15,-0.9 3.45,3.25 3.4,0.14 2.55,3.83 -0.94,0.66 1.04,0.3 -0.37,1.99 -2.31,1.96 0.41,1.41 -0.77,1.06 1.65,3.24 -0.74,2.02 1.52,-0.63 1.76,1.46
            1.13,-0.64 0.45,1.45 0,0 -1.21,1.33 0.54,1.95 -1.02,0.36 -0.64,2.83 1.96,1.15 3,-0.13 -0.05,2.63 1.48,0.84 -0.11,4.94 -1.38,0.15 -1.09,-1.27
            -2.03,1.29 -4.7,-0.72 -3.22,2.82 -1.42,-1.24 -3.02,1.89 1.27,1.36 -1.21,1.23 -2.83,0.82 -2.32,-0.76 -1.17,3.09 0,0 -1.73,0.6 -2.18,-1.94
            -2.38,0.28 -0.86,-1.66 -3.22,-2.45 0.24,1.61 -4.01,-1.87 1.08,-1.35 -0.81,-0.21 1.2,-9.96 -1.18,-2.82 0.42,-1.5 0.8,-0.07 -0.47,-0.71 -0.87,-0.13
            -1.99,2.37 -1.14,-0.75 -0.06,1.39 -1.85,-1.58 -2.98,0.41 -2.41,-1.71 -1.19,-0.07 -0.7,1.09 -1.22,-0.43 2.09,-2.73 0.15,-2.86 -0.97,-1.88 
            1.47,-0.98 -1.77,-2.18 -1.59,0.43 0.7,-2.92 -2.57,0.9 -0.9,-2.18 -1.29,0.06 -0.54,-1.41 -0.87,-0.11 -4.5,2.76 -0.86,-0.76 -2.41,0.76 0,0
            -1.59,-1.53 -0.29,-2.55 0,0 0.05,-5.27 0,0 0.97,-0.03 0.03,-0.95 3.78,-3.4 -4.01,-3.39 3.42,0.62 0.1,-0.92 1.64,-0.84 4.04,0.11 -0.37,-0.88
            0.65,-0.26 1.37,0.56 0.91,-3.03 2.16,-2.21 1.82,0.03 -0.77,-1.76 -1.77,-0.46 0.15,-1.8 -1.31,0.1 -0.25,-1.36 1.21,0.48 z" />
        <path
            title="Jura"
            id="FR-39"
            onClick={() => props.setDepartment('39 - Jura')}
            stroke="blue" fill={props.department.substr(5) === 'Jura' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 444.36408,240.28159 2.44,3.06 2.66,-0.12 1.43,-1.26 1.03,0.64 0,0 1.54,3.88 1.98,0.82 1.73,2.9 -2.41,2.8 0.63,2.38 -0.87,0.68 -1.16,-0.66
            0.72,0.95 -0.4,1.11 0.75,0.59 1.19,-0.58 0.8,-1.54 0.2,1.77 1.37,0.92 2.04,0.42 1.09,-1.27 -0.43,1.73 2.65,0.73 -0.79,1.63 1.61,0.79 -0.54,1.7
            1.09,0.74 -0.11,1.36 0.97,-0.12 0,0 -0.13,0.68 0,0 0.23,0.85 1.35,-0.64 3.59,2.33 2.29,3.48 -5.65,4.53 1.46,2.26 -2.34,2.67 3.7,3 0,0 0.66,1.29
            -3.38,4.42 0.55,1.15 -0.88,1.65 0,0 -8.1,9.07 -5.99,0.23 -0.48,-2.9 -2.79,-0.89 -0.15,-1.23 -1.96,2.92 -1.24,0.09 -1.12,1.55 -2.71,0.25 -0.69,-0.61
            0.76,-2.46 -1.59,0.01 -0.53,-1.95 -0.85,2.24 -0.04,-1.68 -1.72,-1.9 0.65,-0.72 -3.23,-1.85 1.02,-0.92 -0.38,-1.07 0,0 0.39,-1.01 3.87,-0.83 0.16,-1.53
            -2.56,-1.04 0.11,-3.51 0.9,-0.21 0,0 0.85,-0.01 0,0 0.37,-1.11 -0.72,-0.62 1.89,-1.64 -1.92,-2.85 0.54,-0.77 -0.65,-1.85 -1.33,-0.08 1.27,-2.45
            -2.1,-1.22 -0.55,-1.5 3.37,-1.08 2.28,0.34 -0.08,-1.6 -2.16,-0.61 -0.42,-1.71 -2.9,0.3 -1.24,-2.95 -1.46,0.45 -0.5,-0.93 0.17,-2.18 0,0 2.58,-2
            -1.78,-0.84 0.35,-1.36 1.82,-1.61 2.38,-0.4 0.96,-2.73 1.27,-0.95 0.84,-2.5 -0.58,-0.92 1.44,-1.13 0.34,-4.32 0.67,0.32 z" />
        <path
            title="Landes"
            id="FR-40"
            onClick={() => props.setDepartment('40 - Landes')}
            stroke="blue" fill={props.department.substr(5) === 'Landes' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 163.18408,408.94159 6.14,-2.02 0.93,-1.74 2.39,1.47 1.64,-0.05 0.2,1.55 -1.94,3.38 4.51,-1.22 4.58,1.37 1.35,-1.16 5.57,-0.64 0.71,2.06 -0.66,0.81
            4.77,2.22 0.38,1.39 3.51,0.94 0.04,1.21 1.98,0.83 -0.23,4.6 4.99,0.9 1.94,-0.74 -0.04,-3.48 1.46,-0.49 2.12,2.66 0,0 0.51,4.28 5.21,0.15 1.53,1.15
            4.24,0.34 -3.18,5.73 0.57,2.45 0,0 -0.86,1.36 0.88,2.45 -0.71,1.19 -3.32,-1.89 1.16,-1.43 -1.79,-1.74 -0.38,1.33 -2,0.75 -0.09,1.12 -1.26,-0.85
            -1.38,0.89 -0.83,-0.66 -2.49,2.4 2.03,0.9 0.19,0.97 -0.93,0.41 0.62,2.74 -1.37,0.1 0.33,3.31 0.99,0.75 -2.29,1.72 0.32,3.01 -0.88,0.14 0.02,0.91
            -0.8,-0.36 0.17,1.5 1.27,0.04 0.19,1.76 0,0 -1.69,-0.07 -2.18,2.01 -2.44,-1.04 -2.27,1.1 -0.54,-0.75 1.28,-1.28 -0.73,-0.63 -4.08,2.93 -0.86,-0.74
            -1.18,1.16 -2.56,-1.04 0.65,-1.06 -2.71,0.82 -0.45,1.13 -2.13,-2.21 -3.19,2.13 -2.47,-0.49 -1.82,1.06 -1.69,-0.6 -0.14,2.19 -3.13,-0.26 -0.53,-0.81
            -2.59,2.03 -1.14,-1.04 1.66,-0.76 -2.16,-1.61 -4.51,2.79 -5.19,0.07 -0.53,-0.97 -1.52,-0.14 -0.44,-1.3 -1.93,0.54 0,0 3.22,-6.79 5.57,-28.52 z" />
        <path
            title="Loir-et-Cher"
            id="FR-41"
            onClick={() => props.setDepartment('41 - Loir-et-Cher')}
            stroke="blue" fill={props.department.substr(5) === 'Loir-et-Cher' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 250.17408,191.07159 4.21,-0.57 0.31,0.89 2.18,-1.97 1.6,-0.13 0.26,0.87 -2.24,1.71 2.96,0.23 0.44,0.77 1.58,-0.44 0.45,2.9 1.73,0.42 1.29,3.73
            2.08,-0.68 0.78,1.07 4.34,0.45 0.71,-1.68 2.07,0.32 0.54,-1.89 2.95,1.53 0,0 1.82,-0.47 -0.41,2.28 -1.34,1.3 2.46,1.81 0.38,1.46 -2.71,3.6 1.83,1.44
            -0.93,1.7 2.2,1.7 -0.93,0.99 2.17,-2.01 3.45,1.54 1.11,4.48 2.22,1.47 2.21,-1.33 0.48,-2.08 0.3,1.06 2.94,-0.31 0.18,1.3 0.96,0.11 1.84,-1.32 4.3,0.57
            3.77,-0.56 1.57,2.29 -0.09,1.27 0,0 -4.93,2.31 0.36,1.95 2.91,0.34 0.69,1.4 -0.75,2.17 2.09,-0.29 -0.71,5.16 -1.05,0.11 -0.68,-1.98 -2.1,1.89 -0.96,-0.85
            -0.76,3.37 0.71,-0.18 0.24,2.26 1.6,2.06 -1.22,1.2 -2.2,-0.31 -2.12,1.36 -3.44,-1.68 -1.94,2.05 1.17,0.92 -0.9,1.63 -1.13,0.47 -1.39,-0.88 0,0 -2.66,-0.76
            -2.52,-2.71 -2.03,1.16 -2.31,-1.24 -0.81,0.11 0.03,1.15 -2.82,0.16 0.29,2.18 -1.18,-1.01 -2.25,-0.19 -5.07,3.27 0,0 -3.47,-6.21 -2.83,-0.07 -0.52,0.91
            -2.42,-1.98 -0.32,-1.39 1.04,-1.5 -0.76,-1 0.79,-1.34 -1.59,-2.37 1.21,0.13 0.84,-1.29 -0.88,-1.04 -1.07,0.21 -0.71,-3.72 -1.03,-0.53 1.35,-2.49 -0.59,0.33
            -1.24,-2.51 -1.5,1.48 -0.24,-2.2 -2.97,-0.75 -0.84,1.72 -1.55,0.32 -0.77,-2.81 0.98,-2.81 -1.54,0.86 -3.78,-1.19 -1.19,0.86 -1.53,-1.12 -1.06,0.95 -0.36,-1.57
            -0.95,0.91 0,0 -0.61,0.66 -0.83,-1.77 1.86,-2.34 3.13,-0.95 -0.38,-1 3.43,-3.59 -0.61,-3.64 1.21,-0.69 0.89,1.45 0.17,-2.7 1.39,-0.49 -1.26,-2.84 0.98,-2.7
            -1.44,-0.14 -0.57,-1.01 -0.01,-1.38 2.05,-0.15 0.09,-1.37 -1.35,0.07 z" />
        <path
            title="Loire"
            id="FR-42"
            onClick={() => props.setDepartment('42 - Loire')}
            stroke="blue" fill={props.department.substr(5) === 'Loire' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 377.13408,302.40159 0.43,1.76 -0.79,1.92 3.42,0.7 0.86,1.82 0,0 1.04,0 0,0 3.5,-1.59 1.25,1.29 1.88,0.2 1.22,-1.23 0.75,1 1.19,-0.59 -0.19,1.74 1.06,0.13
            2.17,-1.72 1.8,0.03 -0.37,-0.88 1.07,-1.25 0,0 1.35,0.57 -0.99,1.31 1.64,1.68 -0.82,1.48 -1.48,-0.8 -2.41,1.15 -0.78,3.59 -2.24,1.24 2.63,2.61 -0.55,1 -2.3,-0.35
            4.19,3.86 -0.95,1.56 0.84,1.52 2.23,1.1 -0.77,4.49 -1.21,0.82 0.54,1.1 1.69,0.41 -1.53,4.36 3.15,2.78 1.19,2.24 6.03,0.61 1.11,1.89 1.58,-1.04 -1.21,1.73 0.1,2.61
            1.81,0.62 1.08,-1.03 1.44,2.31 0,0 -0.05,5.27 0,0 -5.85,3.18 0.32,1.2 -1.33,1.04 0.43,1.36 -4.94,0.86 0,0 -1.94,-2.28 -1.91,0.98 0,0 -0.5,-0.19 0,0 -1.18,-0.79
            0.9,-1.58 -1.63,-0.96 1.48,-1.13 -0.87,-1.5 -1.57,-0.55 -1.5,0.81 -1.62,-1.83 -3.08,0 -0.21,1.43 -3.01,0.39 -0.42,1.32 -1.05,-1.17 -1.05,0.38 -0.1,1.26 -1.74,-2.81
            -1.67,0.23 -1.16,2.27 -0.44,-1.42 0,0 -0.24,-2.49 2.98,-2.14 0.89,-3.53 -0.99,-0.63 -1.13,-4.23 -4.57,-3.16 -1.94,-3.54 0.15,-1.99 -2,-1.5 -0.05,-1.44 -1.76,-0.98
            1.39,-1.33 -0.29,-3.36 1.62,-0.94 -2.56,-2.62 0,0 0.62,-2.58 1.44,0.47 0.52,-1.03 1.33,0.57 1.8,-1.54 -1.07,-3.23 0.67,-2.1 -1.18,-1.39 -0.15,-2.63 0.8,-0.42
            -1.69,-4.56 1.5,-0.09 -0.05,-1.05 2.67,-0.25 0.44,-0.81 0,0 z" />
        <path
            title="Haute-Loire"
            id="FR-43"
            onClick={() => props.setDepartment('43 - Haute-Loire')}
            stroke="blue" fill={props.department.substr(5) === 'Haute-Loire' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 344.45408,357.26159 2.81,0.03 2.35,-2.51 2.18,-0.38 0.47,-1.21 1.1,1.37 1.19,-1.48 0.98,1.25 1.72,0.17 1.75,-0.2 1.6,-1.58 1.2,1.76 1.68,-0.21 1.98,3.78
            1.92,-1.39 0.25,-1.29 1.59,0.52 -0.31,0.93 3.09,0.63 1.12,-2.54 1.39,0.51 0.07,1.01 2.51,0.49 0,0 0.44,1.42 1.16,-2.27 1.67,-0.23 1.74,2.81 0.1,-1.26 1.05,-0.38
            1.05,1.17 0.42,-1.32 3.01,-0.39 0.21,-1.43 3.08,0 1.62,1.83 1.5,-0.81 1.57,0.55 0.87,1.5 -1.48,1.13 1.63,0.96 -0.9,1.58 1.18,0.79 0,0 0.5,0.19 0,0 1.91,-0.98
            1.94,2.28 0,0 -0.14,2.68 -1.51,2.64 0.6,1.56 -0.79,-0.21 -0.11,0.74 -0.53,-1.55 -2.04,-0.44 1.04,2.28 -1.66,0.33 -0.53,1.84 1.51,1.57 -3.57,1.28 -0.25,1.33 0.97,1.38
            -3.69,0.58 -2.84,5.27 -5.06,0.04 -0.26,2.26 -0.94,-0.26 -0.23,1.06 -0.73,-0.76 0.24,1.25 -1.62,-0.9 -1.6,2.85 0.53,0.55 -2.58,1.42 0,0 -1.07,-0.21 0.3,-1.42 -1.39,0.44
            -2.01,-2.11 -0.74,-2.18 -3.19,0.55 0.28,-1.63 -1.8,-1.44 -1.51,0.32 -0.21,2.9 -0.77,-0.57 -3.82,1.54 -2.54,-5.19 -0.52,-3.43 -0.56,0.38 -1.23,-1.2 0,0 -0.7,-0.85
            1.08,-1.09 -1.79,-0.31 0.41,-0.92 -1,0.38 -0.58,-0.8 0.54,-3.01 -1.21,-1.15 3.22,-0.02 -2.97,-0.99 -1.16,-3.11 0,0 0,-0.58 0,0 0.5,-1.47 -1.52,-0.58 -0.57,-3.43
            -2.43,0.49 -0.18,-1.56 -3.22,0.35 1.32,-1.22 -0.98,-0.86 0.92,-0.17 z" />
        <path
            title="Loire-Atlantique"
            id="FR-44"
            onClick={() => props.setDepartment('44 - Loire-Atlantique')}
            stroke="blue" fill={props.department.substr(5) === 'Loire-Atlantique' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 128.15408,220.22159 5.19,-3.85 0.76,1.38 3.94,-2.29 5.75,0.67 1.15,-0.93 1.49,0.17 1.49,-3.29 6.19,-2.43 -0.24,-1.99 3.76,0.44 1.03,1.79 4.84,1.39
            0,0 0.51,1.26 -0.83,1.54 2.44,0.57 0.86,1.7 0,0 -0.07,0.61 0,0 -0.23,1.25 0.94,0.17 0.88,2.54 5.48,1.87 -1.52,1.62 -5.4,-0.59 0.66,3.84 7.86,1.08 0.78,4.4
            1.08,1.27 -2.22,1.74 -6.98,0.11 -6.09,1.79 -2.61,2.17 2.42,-0.1 2.23,3.81 1.98,-0.52 1.29,4.09 0,0 -0.02,0.26 0,0 -3.08,2.67 0.59,1.1 -1.02,1.2 3.28,0.37
            0.58,1.28 1.62,0.56 -0.02,1.22 -1.25,0.86 0,0 -2.25,-0.71 -2.86,-2.72 -1.24,3.08 -1.88,-0.67 -1.35,0.81 0.56,4.51 -4.07,1.85 -0.37,-6.31 -1.3,-0.47 -2.2,1.52
            0.55,0.76 -0.59,1.34 1.56,2.36 -0.42,1.08 -0.69,-0.17 1.88,2.72 -1.8,0.39 -0.14,0.83 -4.75,-0.84 -1,-0.97 -1.48,0.38 -0.98,-2.69 -3.18,-0.12 -0.24,-1.39
            -2.99,-0.48 -0.65,-1.77 -1.06,-0.18 -1.56,-2.18 0,0 -3.07,-3.98 -8.07,-2.34 3.36,-2.17 -0.21,-5.83 -1.67,-0.36 -3.54,2.18 -3.26,-2.68 -1.74,0.1 -0.6,1.12
            -4.7,-1.94 1.5,-1.12 0.12,-1.81 -2.23,-2.2 4.18,-3.01 0.02,-1.49 0,0 1.43,-1.77 0.9,1.27 3.5,-0.29 0.78,-3.38 1.63,0.13 0.59,1.19 2.49,-1.11 -0.17,1.08
            1.06,0.32 0.26,-1.95 2.46,-0.69 -0.11,-3.76 0.8,-1.06 -0.7,-1.21 z" />
        <path
            title="Loiret"
            id="FR-45"
            onClick={() => props.setDepartment('45 - Loiret')}
            stroke="blue" fill={props.department.substr(5) === 'Loiret' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 298.02408,179.71159 1.95,0.13 2.65,-1.4 2.44,0.52 -0.49,-1.09 1.23,0.29 0.9,-2.04 1.77,0.76 -0.02,2.08 0.9,-1.04 1.37,0.22 0,0 0.46,-1.06 0,0
            0.76,-0.25 1.24,1.55 1.88,-0.49 0,0 0.81,3.48 3.41,1.34 0.71,2.36 -0.68,2.7 -0.97,-0.51 -1.64,2.53 3.23,-0.02 1.57,-1.04 6.07,1.09 2.02,-1.36 -0.74,-1.33
            1.1,0.83 1.5,-0.95 0.09,2.3 0.76,0.11 1.94,-1.63 2.94,-0.43 0,0 3.84,1.85 -0.56,1.07 0.82,-0.06 0.58,2.71 3.11,2.69 -1.05,0.64 1.07,0.79 0.12,2.1
            -1.35,1.53 0,0 -0.49,0.34 0,0 -1.39,1.95 -1.7,0.3 0.15,2.01 -0.71,0.39 1.53,1.08 -0.81,1 0.51,2.88 -7.21,1.82 -0.16,2.49 1.27,-0.21 0.02,1.03 1.96,1.34
            -0.3,0.82 1.43,1.44 -0.87,1.14 0.17,1.7 1.66,1.81 0,0 -0.74,0.76 -1.82,-0.54 -1.74,1.28 -0.67,-0.46 -0.39,0.81 1.12,1.12 0,0 -2.8,1.58 -3.13,-2.66 -0.3,2.7
            -2.34,0.62 -0.48,-1.57 -1.73,-0.86 0.08,-1.04 -2.23,-1.9 -2.98,0.23 -2.22,-2.54 -2.73,1.48 -3.42,-2.67 -2.11,0.54 0,0 0.09,-1.27 -1.57,-2.29 -3.77,0.56
            -4.3,-0.57 -1.84,1.32 -0.96,-0.11 -0.18,-1.3 -2.94,0.31 -0.3,-1.06 -0.48,2.08 -2.21,1.33 -2.22,-1.47 -1.11,-4.48 -3.45,-1.54 -2.17,2.01 0.93,-0.99 -2.2,-1.7
            0.93,-1.7 -1.83,-1.44 2.71,-3.6 -0.38,-1.46 -2.46,-1.81 1.34,-1.3 0.41,-2.28 -1.82,0.47 0,0 -0.38,-2.78 1.51,-0.17 -0.11,-0.88 2.03,0.83 0.2,-0.82 0.87,0.09
            0.17,-1.72 1.44,-0.41 0.45,0.85 0.76,-0.91 2.33,0.87 2.19,-0.83 0.19,-0.82 1.11,0.68 2.24,-1.16 1.51,-3 -0.65,-0.85 0.82,-0.86 2.02,0.03 -0.95,-1.34
            0.66,-0.44 0.26,0.66 0.33,-0.75 -0.72,-2.63 z" />
        <path
            title="Lot"
            id="FR-46"
            onClick={() => props.setDepartment('46 - Lot')}
            stroke="blue" fill={props.department.substr(5) === 'Lot' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 275.38408,376.75159 3.74,-1.58 0.32,1.02 1.23,-0.68 2.95,0.75 2.5,3.47 1.8,0.73 0.02,1.14 2.14,0.85 4.4,-3.21 1.34,0.27 0.31,1.21 1.57,-1.28
            3.13,-0.1 0,0 0.64,2.42 1.08,0.81 -0.75,0.98 0.26,1.76 3.34,4.95 -1.74,5.35 1.21,0.06 0.54,3.42 1.49,1.16 0,0 -0.44,1.86 -0.72,-0.63 -1.92,1.55
            -2.03,-1.04 -0.82,1.28 -0.23,-1.03 -6.05,4.59 0.21,1.01 -1.21,-1.06 -0.19,1.22 -1.81,0.19 0.45,2.68 0.96,0.68 -0.26,1.51 1.84,2.29 -1.26,1.04 0,0
            -2.3,0.86 -1.34,-0.61 -0.84,1.74 -1.33,-0.84 -1.79,0.36 -1.66,1.78 -1,-0.99 0.71,0.76 -0.43,0.93 -1.06,-0.56 0.01,-1.01 -1.85,-0.28 -0.33,1.62 0.91,1.54
            -1.89,1.09 -0.61,-1.82 -1.75,-0.72 -0.5,-1.42 -0.72,2.51 -3.9,2.89 -0.97,-1.49 -2.23,-0.37 0.83,-3.42 -1.96,0.56 -0.28,1.06 -1.14,-0.83 -0.32,0.76 -1.41,-1.3
            0.05,-1.07 -2.39,-0.37 -1.74,-2.14 0.33,-1.07 1.82,0.01 0.12,-1.28 -2.97,0.83 0,0 -0.46,-3.09 -1.44,-0.76 -0.02,-3.55 -1.47,-2.16 1.39,0.41 2.54,-2.14 0,0
            1.16,0.03 -0.4,-1.17 2.37,-2.41 -0.16,-2.03 3.13,-0.8 1.92,-2.25 0.71,0.36 0.5,-1.59 0.69,0.2 0.28,-1.43 -1.04,-0.65 0.04,-1.17 2.81,-0.88 -0.18,-1.9 3.19,-1.96
            -1.1,-1.98 1.2,-0.35 -1.17,-4.49 0,0 -0.18,-0.78 0,0 z" />
        <path
            title="Lot-et-Garonne"
            id="FR-47"
            onClick={() => props.setDepartment('47 - Lot-et-Garonne')}
            stroke="blue" fill={props.department.substr(5) === 'Lot-et-Garonne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 209.52408,422.79159 0.77,-0.81 1.48,0.26 2.22,-2.06 -0.08,-1.42 -2.05,-2.41 0.26,-0.95 4.26,-1.23 -1.62,-2.46 0.99,-1.76 -0.88,-0.65 -0.12,-2.44
            1.46,-1.64 -0.78,-1.18 2.81,0.07 0.52,-2.01 2.87,-1.48 -0.5,-1.64 1.03,0.32 0.85,-1.67 -2.02,-0.29 -1.5,-2.03 0.81,-0.69 0.61,0.48 0.29,-1.83 1.4,0.15
            0.52,-1.06 0.56,1.75 0.71,-0.13 0.37,-2.39 1.24,0.86 1.68,-0.72 0,0 2.13,2.55 0.31,3.74 2.59,0.61 3.73,-1.96 1.8,0.72 1.49,-1.95 1.53,0.42 0.09,-0.81
            2.41,1.78 3.26,-0.15 1.39,-1.3 2.31,1.87 -1.44,2.43 0.66,1.56 1.52,0.19 0.28,-1.08 4.3,-1.66 1.36,1.71 2.45,1.09 0.18,1.32 0,0 -2.54,2.14 -1.39,-0.41
            1.47,2.16 0.02,3.55 1.44,0.76 0.46,3.09 0,0 -0.57,0.9 -4.07,0.06 -0.44,0.95 -0.87,-2.24 -1,0.16 -1.12,4.11 1,0.82 0.93,-0.38 1.44,1.61 -0.93,0.44 -0.9,4.38
            -2.21,0.38 0.26,0.75 1.07,-0.23 0.36,2.14 -1.33,0.65 -3.02,-0.98 0.42,1.76 -1.78,0.33 -0.65,2.39 0,0 -3.25,2.38 -2.94,-3.22 -1.53,1.45 -3.94,-0.05 -0.78,1.57
            -2.56,1.3 -2.75,-0.17 -0.59,1.08 -3.58,-1.84 -2.4,2.87 -1.1,-1.65 -2.26,1.07 0,0 -0.57,-2.45 3.18,-5.73 -4.24,-0.34 -1.53,-1.15 -5.21,-0.15 z" />
        <path
            title="Lozère"
            id="FR-48"
            onClick={() => props.setDepartment('48 - Lozère')}
            stroke="blue" fill={props.department.substr(5) === 'Lozère' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 354.79408,379.60159 1.23,1.2 0.56,-0.38 0.52,3.43 2.54,5.19 3.82,-1.54 0.77,0.57 0.21,-2.9 1.51,-0.32 1.8,1.44 -0.28,1.63 3.19,-0.55 0.74,2.18 2.01,2.11
            1.39,-0.44 -0.3,1.42 1.07,0.21 0,0 1.73,8.71 2.55,2.29 1.41,5.52 0,0 -2.04,3.45 -1.34,-0.3 -1.3,1.03 2.76,3.21 -1.21,1.54 0.44,1.71 1.71,1.02 -1.14,0.84
            -0.44,2.78 0.83,0.1 0.71,1.78 -2.1,-1.15 0.16,1.21 -2.17,2.04 -1.85,-0.64 -1.4,0.57 -5.21,-3.4 -1.38,0.56 -0.41,1.35 1.04,0.43 -0.87,1.42 -3.73,0.42
            -4.08,-0.91 -3,-2.33 0,0 -0.44,-1.75 -2.74,-0.34 -3.8,0.94 1.1,-2.33 -1.75,-1.11 -1.13,0.29 -0.36,-1.76 -1.3,0.74 0.03,-1.29 1.34,-1.35 -1.43,-3.19
            1.04,-4.34 -3.24,-3.74 0.79,-3.27 -4.31,-4.95 0,0 1.47,-4.03 0.91,-0.12 -0.52,-0.74 0.84,-4.26 1.06,-2.11 1.1,0.23 0.19,-3.06 1.61,-0.96 2.06,2.34
            1.83,-1.47 -0.52,-1.26 1.08,-0.42 -0.32,-1.12 1.04,-0.4 0.78,0.91 z" />
        <path
            title="Maine-et-Loire"
            id="FR-49"
            onClick={() => props.setDepartment('49 - Maine-et-Loire')}
            stroke="blue" fill={props.department.substr(5) === 'Maine-et-Loire' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 163.85408,209.24159 2.85,1.9 6.03,0.09 1.82,0.99 1.21,-0.43 -0.93,-0.8 0.51,-0.87 1.23,0.1 1.62,1.84 2.22,0.68 0.01,-0.98 0.91,-0.2 3.34,2.11
            5.32,0.09 0.97,-1.34 2.15,0.49 0.65,-2.31 2.66,1.94 1.75,-0.53 -0.18,-0.94 1.4,1.22 0,0 1.09,1.74 -0.3,1.07 3.21,-0.32 2.12,0.99 1.41,-1.29 1.08,0.49
            -1.78,2.11 0.85,1.98 2.47,1.03 1.1,-0.11 0.75,-1.7 0.05,0.71 0.82,-0.46 2.14,1.36 0.78,-0.58 0.61,1.45 2.03,1.18 2.19,-0.13 1.25,1.47 1.62,-1.02 0.06,-1.12
            0.83,0.86 0.98,-0.42 0,0 0,0 0,0 0.19,0.91 0,0 -1.62,3.37 1.33,0.73 -0.19,1.66 -2.85,6.17 1.22,1.08 -1.78,1.23 0.3,0.9 -2.88,3.98 0.08,3.9 -1.08,1.34 0.6,0.39
            -0.55,1.53 0,0 -1.48,-0.67 -1.1,0.91 -1.38,2.82 0.17,1.67 -2.08,-0.87 -0.66,2.22 0,0 -1.13,0.62 -0.82,-0.99 -1.07,0.2 1.37,-2.12 -1.75,-0.39 -0.84,0.85 -2.54,-0.7
            -3.1,1.15 -2.34,-0.26 -0.45,1.48 -2.11,-0.13 -0.95,0.94 0.9,-1.71 -1.45,-0.12 -2.56,1.18 0.53,1.92 -2.3,2.16 -2.96,-0.38 -0.35,0.95 -1.48,0.22 -3.02,-1.3 -0.87,1.1
            -1.44,-0.15 -0.39,1.21 -1.64,-0.36 0,0 -1.59,-1.94 -1.28,0.76 -0.62,-1.39 -3.27,0.85 -3.84,-1.48 0,0 1.25,-0.86 0.02,-1.22 -1.61,-0.56 -0.58,-1.28 -3.28,-0.37
            1.02,-1.2 -0.59,-1.1 3.08,-2.67 0,0 0.03,-0.26 0,0 -1.29,-4.08 -1.97,0.52 -2.24,-3.81 -2.41,0.1 2.61,-2.17 6.08,-1.79 6.98,-0.11 2.22,-1.74 -1.08,-1.27 -0.79,-4.4
            -7.86,-1.09 -0.66,-3.83 5.4,0.59 1.52,-1.62 -5.48,-1.87 -0.88,-2.54 -0.94,-0.17 0.23,-1.25 0,0 0.07,-0.61 0,0 -0.86,-1.71 -2.44,-0.56 0.83,-1.54 -0.51,-1.26 0,0 z" />
        <path
            title="Manche"
            id="FR-50"
            onClick={() => props.setDepartment('50 - Manche')}
            stroke="blue" fill={props.department.substr(5) === 'Manche' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 135.51408,88.801588 1.44,1.36 1.46,-0.76 1.14,1.75 5.67,1.07 3.47,1.77 -0.11,-0.67 4.5,-0.61 0.98,-1.99 4.31,-0.63 4.33,0.68 1.5,5.71 -1.08,-0.38 -0.07,1.71
            -1.71,0.26 -0.35,2.750002 5.8,8.42 -0.62,3.71 1.12,-0.88 1.59,1.18 0,0 0.11,1.14 -1.06,0.99 0.8,1.9 -0.66,0.6 2.4,2.31 4.32,2.73 2,-2.59 1.6,1.94 -1.13,0.63
            -0.47,1.7 -1.38,0.12 2.98,2.08 -1.49,1.72 1.83,-0.9 0.39,3.08 -0.88,1.32 1.28,1.09 -1.81,1.15 -1.71,2.91 -2.22,0.98 -2.42,-0.5 -0.81,2.22 2.26,-0.28 0.12,1.3
            -2.15,2.27 -1.1,-0.45 -2.55,3.18 2.36,0.77 0.82,2.34 4.57,0.33 0.87,-1.04 2.84,2.07 2.53,-0.99 -0.75,1.23 0,0 -0.42,1.36 1.36,-0.04 3.39,2.84 -1.55,1.79 1.42,2.26
            -1.47,3.65 -3.56,3.82 0,0 -2.03,0.31 -1.94,-1.29 -0.71,1.43 -1.3,0.32 -2.72,-1.18 0,0 -7.63,-2.23 -1.05,2.15 -2.05,0.66 -1.05,1.87 -2.8,0.5 -1.21,-1.64 -1.58,-0.12
            -3.46,-9.09 0,0 2.62,0.48 -0.1,-0.68 1.01,0.78 3.95,-1.22 1.56,0.77 -2.41,-1.68 1.05,-1.03 -2.54,1.28 -0.11,-1.02 -1.99,-0.78 -1.2,-2.92 -1.75,-1.04 -0.18,-4.7
            -1.66,-0.84 1.25,-0.86 0.91,-4.83 0.78,-0.35 -0.8,-0.65 0.21,-5.37 0.93,-0.47 1.27,0.85 -1.57,-1.3 -0.77,0.32 -0.72,2.27 -0.9,-2.41 -0.42,-4.06 0.72,-2.11 -0.92,-5.21
            1.63,-0.32 -1.64,-0.7 -0.51,1.57 -3.52,-7.38 -2.69,-2.87 -1.39,-0.12 -1.59,-8.36 -1.65,-1.93 1.96,-2.350002 -0.33,-3.85 -1.11,-1.78 -2.99,-1.02 -0.02,-2.61 1.13,-0.67 z" />
        <path
            title="Marne"
            id="FR-51"
            onClick={() => props.setDepartment('51 - Marne')}
            stroke="blue" fill={props.department.substr(5) === 'Marne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 359.91408,144.33159 1.78,-3.77 1.76,-0.16 0.14,-1.56 1.22,-0.22 -0.54,-1.09 1.28,-0.38 0.85,-2.39 1.51,-0.63 -1.12,-1.83 -2.72,0.39 0.07,-1.47 2.13,-1.07
            -1.55,-2.68 0.89,-1.95 2.66,-0.23 0.78,0.75 1.8,-1.05 0.03,-1.41 -1.42,0.27 -0.66,-1.9 -0.69,0.62 -1.18,-1 1.01,-1.39 -1.08,-1.22 0.56,-0.94 -0.85,-2.84 5.51,-2.72
            3.05,0.73 0.18,-2.25 2.83,-1.69 1.49,1.87 3.2,1.09 0.48,-2.93 0,0 5.94,0.32 0.41,1.12 1.92,0.03 2.52,3.62 1.22,0.01 0.26,0.78 1.51,-0.69 0.69,2.11 2.08,1.17 6.28,-1.19
            -0.26,1.17 1.76,2.62 2.33,-1.64 2.64,1.06 2.56,-0.57 2.1,1.41 0.45,-2.23 1.94,-0.21 -0.11,0.92 1.25,0.92 0,0 1.69,1.71 -2.18,1.6 2.32,4.61 0.42,3.34 -1.66,2.38 1.8,-0.89
            1.15,0.76 -0.09,1.92 -1.65,0.59 1.87,0.63 -0.96,2.18 -3.24,1.34 0,0 -0.18,0.35 0,0 -0.87,0.86 -0.02,1.92 1.04,1.62 -1.99,1.57 0.05,1.19 1.94,0.55 1.85,2.84 1.17,0.17
            0.22,1.99 -0.73,0.05 -0.38,1.55 0,0 -1.61,-0.29 -0.5,0.9 -1.15,-0.87 -1.79,1.36 -2.09,-0.82 -1.49,0.63 -0.37,1.13 2.86,0.21 0.53,0.89 0,1.4 -3.76,1.11 0.55,1.82
            0.82,-0.16 -0.56,0.84 0.66,1.6 -3.38,-0.79 -1.99,0.66 0,0 -1.46,-0.8 0.37,-0.62 -2.12,0.05 -1.97,1.74 -1.41,-1.39 -0.65,0.59 -3.85,-1.44 -3.53,-3.38 0.69,-3.96
            -1.4,-2.27 -1.53,0.53 -0.84,-0.66 -0.39,0.91 -2.83,-0.37 -1.38,1.34 -2.14,-0.94 -0.91,2.19 -2.36,0.17 -2.2,3.8 -1.66,0.11 -0.36,1.68 -2.01,-0.36 0.55,2.67 -1.65,1.42
            -3.64,-1.47 -4.07,0.02 -0.54,-2.45 -1.82,-0.5 -1,-1.99 0,0 -1.63,-1.02 0.58,-0.84 -3.45,0.7 0.35,-1.09 -0.81,-0.7 1.52,-2.16 -0.35,-2.49 -3.06,-1.27 0.6,-1.6 1.43,-0.4
            -0.08,-0.8 -1.58,-0.35 3.48,-0.33 z" />
        <path
            title="Haute-Marne"
            id="FR-52"
            onClick={() => props.setDepartment('52 - Haute-Marne')}
            stroke="blue" fill={props.department.substr(5) === 'Haute-Marne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 409.10408,164.46159 1.99,-0.66 3.38,0.79 -0.66,-1.6 0.56,-0.84 -0.82,0.16 -0.55,-1.82 3.76,-1.11 0,-1.4 -0.53,-0.89 -2.86,-0.21 0.37,-1.13 1.49,-0.63
            2.09,0.82 1.79,-1.36 1.15,0.87 0.5,-0.9 1.61,0.29 0,0 0.89,0.39 -0.69,1.2 0.33,2.85 1.31,0.02 1.02,-1.18 0.58,2.28 1.99,0.03 1.12,2.21 0.84,-0.28 1.89,2.27
            4.82,1.17 0.76,1.66 2.14,0.59 0.45,1.82 2.48,1.47 0,0 -3.14,1.84 2.01,0.74 -1.02,1.39 0.39,1.6 2.18,-1.55 1.88,0.52 2.67,4.6 0.94,-1.11 1.24,1 0,2.12 1.91,0.48
            1.83,2.46 -2.11,0.95 0.15,1.96 -1.13,1.67 0.57,0.78 -1.77,1.42 1.21,0.97 -0.03,-1 1.67,0.57 1.06,2.03 0.84,-0.36 1.43,1 0.77,2.28 -0.32,2.72 0.92,0.27 1.55,-1.7
            1.57,3.07 0,0 -0.02,1.59 -1.4,-0.39 -1.19,2.18 0.25,1.16 -2.79,-0.45 -0.79,2.43 -2.1,-0.05 -0.62,2.57 1.23,0.7 0.13,1.29 -1.01,1.5 0.26,2.32 -3.9,0.77 -1.22,-2.17
            -1.74,2.12 -1.77,-0.67 -0.86,0.74 -2.15,-0.16 -1.58,4.29 0,0 -0.71,0.86 -0.71,-1.16 -1.3,0.03 -2.28,2.01 -0.56,-1.2 0.88,-1.59 -0.87,0.36 -2.69,-2.2 0.21,-1.73 -2.53,2.08
            -1.57,-1.53 -1.08,0.19 0.3,-1.66 -1.32,0.18 -0.03,-1.12 -2.5,1.5 -0.4,-4.68 -1.8,-0.32 3.05,-2.25 0.19,-0.99 -1.39,-1.2 -0.27,-1.7 -1.12,-0.3 -0.98,-3.07 -1.4,0.21
            -0.37,1.32 -1.2,-0.77 1.4,-1.95 -0.82,-1.25 -2.53,-0.11 1.02,-1.62 -0.67,-1.07 -2.3,0.07 -1.36,-1 0,0 0.74,-1.51 -2.71,-2.09 1.99,-0.63 1.06,-1.74 4.3,0.75 -0.21,-1.5
            0.9,-0.83 -0.6,-1.56 1.12,-1.91 -1.17,-1.31 1.07,-2.87 -2,-3.53 1.1,-1.18 -3.54,-1.53 0.22,-1.32 -2.24,-0.19 -1.16,-3.09 -2.06,-1.31 1.03,-0.55 z" />
        <path
            title="Mayenne"
            id="FR-53"
            onClick={() => props.setDepartment('53 - Mayenne')}
            stroke="blue" fill={props.department.substr(5) === 'Mayenne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 179.53408,166.30159 1.95,2.91 1.42,-0.65 0.58,1.79 2.08,-0.75 -0.65,-1.54 1.73,0.32 0.86,-1.13 0.69,2.64 2.24,-1.71 1.92,-0.1 1.72,-2.24 4.7,-0.06 1.81,1.61
            1.33,-2.39 2.07,0.94 1.24,-1.81 -0.89,-0.78 0.57,-0.98 1.74,0.05 2.57,2.22 -1.17,1.09 1.12,3.35 3.99,0.36 -0.23,4.37 0,0 -3.36,1.09 0.61,1.58 -1.47,-0.11 0.78,2.93
            -1.17,1.83 0.91,3.65 -4.49,2.93 0.64,0.63 -0.7,0.67 1.6,1.54 -0.9,0.91 0.28,2.38 -1.67,-0.4 -2.9,1.43 0,1.89 1.38,0.37 0.22,2.47 1.09,0.5 -5.64,2.59 0.2,1.32 1.4,0.45
            0.11,1.78 -1.6,-0.03 -1.47,1.67 0.71,1.54 1.74,0.25 0.17,2.62 0,0 -1.4,-1.22 0.18,0.94 -1.75,0.53 -2.66,-1.94 -0.65,2.31 -2.15,-0.49 -0.97,1.34 -5.32,-0.09 -3.34,-2.11
            -0.91,0.2 -0.01,0.98 -2.22,-0.68 -1.62,-1.84 -1.23,-0.1 -0.51,0.87 0.93,0.8 -1.21,0.43 -1.82,-0.99 -6.03,-0.09 -2.85,-1.9 0,0 0.75,-0.63 -0.07,-1.95 1.43,-0.99
            -0.37,-1.4 0.82,-0.48 0.88,-4.12 1.22,-0.39 0.07,-0.95 3.08,0.14 1.38,-1.17 -0.69,-1.78 0.49,-2.29 -1.22,-1.35 -2.12,-10.7 2.35,-4.1 -1.49,-5.34 0.47,-5.85 0,0
            2.72,1.18 1.29,-0.32 0.72,-1.43 1.94,1.29 z" />
        <path
            title="Meurthe-et-Moselle"
            id="FR-54"
            onClick={() => props.setDepartment('54 - Meurthe-et-Moselle')}
            stroke="blue" fill={props.department.substr(5) === 'Meurthe-et-Moselle' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 455.27408,99.561588 1.5,1.380002 0.82,-0.35 0,1.48 2.14,1.38 0,0 0.38,1.14 1.27,-0.3 0.6,1.14 -0.01,2.3 -1.34,1.47 0.93,0.72 -0.34,1.48 2.19,1.78 -1.35,0.21
            0.07,1.01 1.6,1.3 0.8,-0.36 0.05,3.41 1.47,1.07 -2.67,2.91 2.3,1.79 -2.09,1.35 0.91,2.06 -2.99,0.21 -0.3,1.08 1.39,2.81 2.38,0.69 1.21,1.49 -0.1,2.25 1.27,-0.27
            0.37,1.18 1.43,-0.34 0.67,2.09 1.67,-0.56 5.74,1.42 0.67,1.31 -1.74,3.4 2.28,0.79 -1.6,0.61 2.25,0.76 0.1,2.05 1.54,0.28 1.57,-0.91 0.87,1.48 1.4,-0.07 1.06,1.06
            2.06,-0.4 1.51,2.58 2.63,0.59 0.21,2.39 0.78,-1.09 1.94,1.4 1.05,-0.44 0.95,1.7 2.21,-0.07 0.79,1.39 2.39,0.08 0.78,-0.95 0.96,1.61 2.38,-0.07 -0.7,1.1 1.72,0.86
            0.73,1.74 1.28,0.42 -1.08,0.47 0.79,0.14 0,0 0.13,1.42 1.6,-0.07 0,0 -5.79,2.18 -3.6,3.81 -1.17,-1.2 -1.45,1.24 0,0 -0.42,0.81 0,0 -1.5,0.99 0.23,-0.84 -0.89,0.17
            -0.08,-0.73 -4.96,-1.09 -0.98,-2.79 -2.24,1.99 0.5,1.55 -1.89,-0.97 -6.01,2.51 -0.29,-1.46 -2.18,0.63 -1.41,-1.87 -2.58,2.26 -3.43,-0.66 0.05,1.7 -1.47,1.75
            -1.02,-0.55 -0.18,-1.33 -0.67,1.49 -2.85,-0.24 -1.7,0.86 -0.49,-2.88 -1.89,-0.26 1.12,-2.08 -1.39,-0.15 -0.15,1.97 -1.59,-0.33 1.71,-2.22 -0.5,-1.49 0.83,-1.58
            -1.98,-0.96 -2.93,1.71 -0.92,-0.88 0,0 0.49,-2.71 -2.5,-1.29 -0.03,-1.74 3.14,-1.2 0.47,-1.07 -1.84,0.21 -0.66,-1.92 0.97,-3.05 -2.07,-2.78 1.13,-0.45 -0.24,-1.13
            1.74,-1.56 -0.94,-0.33 1.04,-1.71 -0.69,-2.82 1.03,-0.89 -1.78,-1.94 2.18,-1.86 2.07,-0.35 -0.55,-1.4 -1.47,-0.74 2.2,-3.52 -0.73,-1.47 -1.28,0.1 0.66,-2.75 -2.3,0.12
            -0.79,-1.23 1.05,-0.87 -1.17,-1.16 0.87,-2.56 -1.68,0.09 -0.24,-1.27 0.88,-0.89 -0.73,-2.22 1.64,-0.89 0.38,-1.76 -0.32,-0.61 -1.24,0.38 0,0 -0.19,-0.71 0,0 0.4,-2.07
            -1.13,-0.61 -0.17,-1.25 1.17,-0.94 -1.83,-0.26 0.01,-0.9 -2.43,-1.4 -0.76,1.09 -4.87,0.88 -0.56,1.52 -1.41,-1.12 2.35,-1.28 -1.4,-0.35 0.61,-1.33 -1.07,-0.33 0.19,-1.57
            -1.5,-0.63 0.52,-1.03 1.25,1.14 -0.7,0.31 0.71,-0.15 0.01,-0.94 0,0 3.46,-2.04 2.28,1.45 1.28,-2.73 4.3,0.72 0.52,-1.420002 z" />
        <path
            title="Meuse"
            id="FR-55"
            onClick={() => props.setDepartment('55 - Meuse')}
            stroke="blue" fill={props.department.substr(5) === 'Meuse' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 440.43408,96.951588 1.31,2.04 0.49,2.560002 -0.7,0.58 0.95,1.3 0,0 -0.01,0.94 -0.71,0.15 0.7,-0.31 -1.25,-1.14 -0.52,1.03 1.5,0.63 -0.19,1.57 1.07,0.33
            -0.61,1.33 1.4,0.35 -2.35,1.28 1.41,1.12 0.56,-1.52 4.87,-0.88 0.76,-1.09 2.43,1.4 -0.01,0.9 1.83,0.26 -1.17,0.94 0.17,1.25 1.13,0.61 -0.4,2.07 0,0 0.19,0.71
            0,0 1.24,-0.38 0.32,0.61 -0.38,1.76 -1.64,0.89 0.73,2.22 -0.88,0.89 0.24,1.27 1.68,-0.09 -0.87,2.56 1.17,1.16 -1.05,0.87 0.79,1.23 2.3,-0.12 -0.66,2.75 1.28,-0.1
            0.73,1.47 -2.2,3.52 1.47,0.74 0.55,1.4 -2.07,0.35 -2.18,1.86 1.78,1.94 -1.03,0.89 0.69,2.82 -1.04,1.71 0.94,0.33 -1.74,1.56 0.24,1.13 -1.13,0.45 2.07,2.78
            -0.97,3.05 0.66,1.92 1.84,-0.21 -0.47,1.07 -3.14,1.2 0.03,1.74 2.5,1.29 -0.49,2.71 0,0 -1.94,2.33 -3.17,-0.79 0.51,0.78 -1.52,1.08 0.15,1.03 -1.19,-1.05
            -3.09,0.68 -0.5,1.41 -1.54,-0.73 0,0 -2.48,-1.47 -0.45,-1.82 -2.14,-0.59 -0.76,-1.66 -4.82,-1.17 -1.89,-2.27 -0.84,0.28 -1.12,-2.21 -1.99,-0.03 -0.58,-2.28
            -1.02,1.18 -1.31,-0.02 -0.33,-2.85 0.69,-1.2 -0.89,-0.39 0,0 0.38,-1.55 0.73,-0.05 -0.22,-1.99 -1.17,-0.17 -1.85,-2.84 -1.94,-0.55 -0.05,-1.19 1.99,-1.57
            -1.04,-1.62 0.02,-1.92 0.87,-0.86 0,0 0.18,-0.35 0,0 3.24,-1.34 0.96,-2.18 -1.87,-0.63 1.65,-0.59 0.09,-1.92 -1.15,-0.76 -1.8,0.89 1.66,-2.38 -0.42,-3.34
            -2.32,-4.61 2.18,-1.6 -1.69,-1.71 0,0 4.13,-2.35 0.35,-0.98 -1.41,-1.29 0.11,-1.62 1.13,-2.09 1.92,-0.63 -1,-1.55 1.59,-1.07 -0.17,-2.45 -2.16,-2.72 1.29,-2.18
            0,0 0,-0.48 0,0 0.94,-1.160002 -0.01,-2.02 1.28,0.1 0.84,1.77 2.96,-0.41 1.14,1.760002 2.06,-1.130002 1.06,-2.19 1.68,0.05 0.74,-1.5 0,0 z" />
        <path
            title="Morbihan"
            id="FR-56"
            onClick={() => props.setDepartment('56 - Morbihan')}
            stroke="blue" fill={props.department.substr(5) === 'Morbihan' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 80.424077,235.16159 3.75,1.43 0.73,2.13 3.24,0.92 -0.56,1.7 -0.73,0.52 -3.41,-1.45 -1.82,0.54 -0.82,-0.61 -1.01,-2.95 0.63,-2.23 z m -13.17,-49.25
            3.96,0.62 1.02,-0.7 1.08,0.91 -0.79,0.84 0.87,0.86 3.38,-1.55 0.45,1.28 1.47,-0.24 0.73,0.89 1.28,-0.7 1.14,0.56 0.91,-1.4 2.15,0.04 1.03,-2.66 3.23,0.19
            0.92,0.37 0.64,2.33 2.38,-0.68 2.13,0.4 1.67,1.43 1.93,-0.19 1.270003,3.59 0.8,-1.83 2.11,0.23 1.8,-1.11 0.43,2.03 -0.96,2.16 0.69,1.92 4.49,-2.74 1.41,-3.54
            -0.88,-1.5 4.61,-1.01 2.77,3.88 0.35,-0.92 2.57,-0.53 0,0 1.38,1.69 -0.07,2.73 1.47,-1.11 2.15,0.33 -0.85,1.44 -2.27,-0.02 -0.82,0.83 -1.05,3.01 2.13,-0.53
            2.64,1.25 1.22,-0.28 2.75,3.97 -1.53,1.78 0.79,1.8 -0.58,0.81 0.93,0.33 1.41,-1.6 0.77,2.19 -3.2,3.87 0,0 -0.63,0 0,0 2.74,1.18 0.15,0.79 -2.05,0.11 0,0
            -0.17,0.5 0,0 -0.76,0.36 1.29,5.65 0,0 0.69,1.22 -0.8,1.06 0.11,3.76 -2.46,0.69 -0.26,1.95 -1.06,-0.32 0.17,-1.08 -2.49,1.11 -0.59,-1.19 -1.63,-0.13 -0.78,3.38
            -3.5,0.29 -0.9,-1.27 -1.43,1.77 0,0 -1.56,-0.12 -0.26,-2.58 2.52,-0.38 -2.85,-1.59 -4.67,1.23 -0.36,-0.94 -1.1,0.08 -1,1.29 -1.87,-0.54 -3.710003,1.21 -1.43,-0.73
            -0.99,-2.13 -1.29,-0.1 -0.27,-1.13 -2.2,-0.21 -1.05,-1.41 -0.63,0.81 -0.84,-1.58 0.04,1.2 -4.13,0.2 -0.46,2.3 1.83,3.61 -1.69,0 -1.18,-3.07 0.86,-1.21 -0.81,-3.91
            -5.1,-4.73 -2.41,-0.73 -0.9,0.58 -0.2,-1.52 -3.96,0.74 -3.04,-3.97 0,0 0.25,-5.07 1.56,1.35 1.38,-0.8 0.36,-1.67 2.06,0.04 -0.85,-2.6 1.35,-1.29 -0.22,-1.98
            -1.04,-0.99 -2.1,1.75 -0.96,-1.82 -1.69,-0.85 -4.48,0.25 -1.44,-4.62 -1.45,-0.5 0.56,-1.65 -1.55,-0.12 -0.07,-0.96 0.83,-1.91 6.26,-2.78 z" />
        <path
            title="Moselle"
            id="FR-57"
            onClick={() => props.setDepartment('57 - Moselle')}
            stroke="blue" fill={props.department.substr(5) === 'Moselle' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 474.57408,102.71159 4.91,3.16 0.11,-0.7 1.17,0.41 1.3,-0.79 4.8,2.84 0.61,0.77 -0.72,1.13 2.54,2.33 -1.4,1.18 1.95,2.89 2.32,1.37 -0.38,1.34 1.19,0.58
            0.27,2.14 1.17,-0.15 -0.5,1.96 1.12,1.46 1.71,-0.28 2.31,0.98 1.1,-1.75 -0.99,-2.02 0.92,-0.64 1.35,0.86 1.89,-0.86 0.89,1.27 0.55,-0.44 2.51,1.21 0.72,4.86
            1.49,-0.99 -0.28,-1.17 0.77,-0.47 0.2,1.05 0.89,-0.18 1.31,1.37 3.69,-0.6 1.99,0.95 1.34,-1.81 1.51,0.02 0.1,-1.78 3.31,-0.77 -0.37,1.2 2.42,-0.27 0.25,2.39
            1.49,2.38 3.97,1.27 0.23,1.22 0,0 -2.42,6.09 -1.73,1.55 -3.52,-2.27 -5.24,1.58 -1.46,-0.77 0.14,-1.15 -2.34,-0.9 -1.11,0.53 -1.58,-1.64 -1.95,-0.01 -1.04,-3.56
            0.65,-0.45 -0.78,-0.5 -1.28,0.76 0.41,0.94 -1.94,4.01 0.07,1.94 -1.74,-0.32 -0.49,1.77 -1.44,-0.01 0.45,2.51 3.9,1.19 0.08,0.98 1.85,-0.22 0.04,0.73 -1.87,0.43
            0.04,1.77 1.54,0.28 -0.92,0.73 0.47,0.96 2,-1.1 0.68,-2.45 1.75,-0.51 1.52,1.94 0.07,-0.7 1.53,0.45 -0.45,0.9 1.62,0.61 1.11,1.94 -2.06,4.31 -0.94,0.41 0.96,1.86
            1.62,0.15 -1.68,1.84 0.63,0.13 -0.59,2.38 -4.15,3.94 -3.69,-0.49 0,0 -0.79,-0.14 1.08,-0.47 -1.28,-0.42 -0.73,-1.74 -1.72,-0.86 0.7,-1.1 -2.38,0.07 -0.96,-1.61
            -0.78,0.95 -2.39,-0.08 -0.79,-1.39 -2.21,0.07 -0.95,-1.7 -1.05,0.44 -1.94,-1.4 -0.78,1.09 -0.21,-2.39 -2.63,-0.59 -1.51,-2.58 -2.06,0.4 -1.06,-1.06 -1.4,0.07
            -0.87,-1.48 -1.57,0.91 -1.54,-0.28 -0.1,-2.05 -2.25,-0.76 1.6,-0.61 -2.28,-0.79 1.74,-3.4 -0.67,-1.31 -5.74,-1.42 -1.67,0.56 -0.67,-2.09 -1.43,0.34 -0.37,-1.18
            -1.27,0.27 0.1,-2.25 -1.21,-1.49 -2.38,-0.69 -1.39,-2.81 0.3,-1.08 2.99,-0.21 -0.91,-2.06 2.09,-1.35 -2.3,-1.79 2.67,-2.91 -1.47,-1.07 -0.05,-3.41 -0.8,0.36
            -1.6,-1.3 -0.07,-1.01 1.35,-0.21 -2.19,-1.78 0.34,-1.48 -0.93,-0.72 1.34,-1.47 0.01,-2.3 -0.6,-1.14 -1.27,0.3 -0.38,-1.14 0,0 3.22,0.28 0.56,2.67 2.57,0.26 0.64,-1.22
            1.78,0.84 1.12,-2.52 0.63,0.39 1.33,-1.45 z" />
        <path
            title="Nièvre"
            id="FR-58"
            onClick={() => props.setDepartment('58 - Nièvre')}
            stroke="blue" fill={props.department.substr(5) === 'Nièvre' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 338.82408,224.01159 1.86,0.77 3.35,-1.9 0.92,0.7 -0.1,2.23 1.77,1.3 1.61,-0.28 1.24,2.1 1.7,-0.11 0.44,-0.81 2.59,2.14 1.89,-2.25 3.98,0.73 0.45,-4.11
            0.62,2.06 2.76,1.84 0.28,2.52 1.2,-0.62 2.43,0.98 2.15,3.17 2.53,-0.41 1.26,1.6 0.16,-1.41 1.88,-2.01 1.15,1.25 -1.43,1.34 0.53,1.63 2.98,-0.93 0.19,-0.82
            0.98,1.12 -0.01,2.69 1.25,1.33 1.04,-0.24 0.66,-1.58 2.62,0.03 0,0 1.52,-0.93 -1.2,2.83 0.81,0.13 -0.8,0.86 0.8,3.29 1.87,-0.98 2.05,2.78 -0.75,3.25 0,0
            -0.9,0.3 0,0 -3.01,0.28 0.07,1.32 0,0 -2.79,0.71 -0.49,2.07 1.46,1.4 -0.69,2.55 -2.63,1.72 0.64,0.86 1.21,-0.86 0.7,1.71 -0.74,2.88 2.59,2.55 -2.34,1.85
            0.46,3.56 -0.86,-0.61 -1.53,0.54 -2.88,2.32 -5.43,2.37 -0.58,-2.08 -1.89,-1 -3.23,0.89 -1.28,-0.69 0,0 -1.36,-0.99 -0.46,1.57 0,0 0.48,0.92 0,0 -2.05,0.09
            0.55,2.04 -1.88,0.35 -1.38,1.98 -1.63,-0.75 0.39,-1.63 -1.32,-1.46 -1.72,0.08 -1.34,1.73 -1.39,-0.33 -0.53,-1.6 -1.31,-0.06 -2.78,2.49 -3.04,-3.12 -2.94,-1.43
            -1.11,-2.64 0,0 1.57,-3.44 -0.82,-3.38 1.25,-2.92 -0.65,-1.16 0.58,-2.97 -2.49,-2.46 0.53,-3.46 -2.17,-6.53 0.14,-2.03 -4.24,-3.35 -0.49,-1.33 2.62,-6.09 -2.34,-5.07
            0,0 -1.12,-1.12 0.39,-0.81 0.67,0.46 1.74,-1.28 1.82,0.54 z" />
        <path
            title="Nord"
            id="FR-59"
            onClick={() => props.setDepartment('59 - Nord')}
            stroke="blue" fill={props.department.substr(5) === 'Nord' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 342.41408,60.101588 0.66,0.14 0,0 0.71,0.52 -1.13,0.95 0.2,1.08 -1.51,0.09 -1.09,1.13 -0.33,-2.45 1.57,-0.09 0.73,-1.01 0,0 0.19,-0.36 z m -20.81,-57.5299999
            0.53,3.46 1.39,0.81 1.03,2.82 -1.73,1.8199999 0.77,1.42 -0.39,3.18 1.48,2.44 1.38,-0.63 2.25,0.77 2.75,5.49 1.17,0.71 1.2,-0.46 2.48,2.01 1.91,-3.91 4.24,-1.83
            1.05,0.47 1.33,-1.41 1.62,0.27 1.66,4.24 2.88,1.54 0.19,1.65 -1.08,1.2 2.17,8.71 3.51,2.16 2.96,-1 1.14,-1.74 1.83,0.63 -0.76,2.36 4.48,-0.57 2.16,2.56 0.62,3.47
            -0.59,2.39 1.97,3.96 1.64,-2.99 2.5,-0.2 0.28,0.69 1.06,-0.63 2.58,1.71 5.09,-2.08 2.15,3.12 1.9,0.88 0.65,2.55 1.23,-0.02 -0.72,-1.08 0.52,-0.92 1.92,1.11 0.53,1.25
            -2.82,2.61 0.07,3.19 -1.1,1.91 2.98,0.04 -0.12,1.78 1.37,2.33 -2.91,1.36 -1,1.85 1.08,1.19 -0.84,1.37 0,0 -2.32,0.68 -1.03,-0.97 -3.4,-0.59 0.62,-2.11 -0.55,-1.15
            -3.93,2.24 -0.64,-1.82 -5.26,-0.96 -1.28,-1.12 -2.11,1.03 -0.18,1.55 -0.8,-0.55 -1.09,0.84 -0.84,-1.23 -2.07,-0.68 -2.2,2.36 -5.28,-1.2 -1.42,1.34 -2.14,0.1 -0.79,-1.17
            -3.58,1.35 0,0 -0.93,-1 -1.32,0.06 -1.18,-1.79 0,0 -0.07,-1.88 1.05,-0.54 -0.92,-1.92 1.58,-1.07 -1.2,-1.56 2.32,-1.22 -0.9,-1.71 2.21,-1.4 -1.67,-1.11 0.02,-1.05
            -4.16,-0.98 -1.53,0.52 0.15,-0.96 1.67,0.12 0.01,-1.26 1.25,-0.56 -3.2,-3.15 -0.99,-2.8 3.51,-1.53 0.05,-2.01 -2.24,0.9 -0.39,-0.92 0.91,-1.74 -2.5,-1.76 -2.81,0.7
            -0.79,-0.52 0.2,-1.88 -2.53,0.76 -0.96,-0.53 -0.34,-1.06 0.81,-0.02 0.03,-0.96 -1,-1.36 2.13,-2.89 1.17,-0.08 -2.49,-2.47 -1.78,0.38 -0.2,0.93 1.17,0.97
            -1.27,-0.12 -0.6,2.09 -1.38,-1.8 -1.62,-0.35 -1.78,1.21 -0.66,-1.04 -2.81,0.48 -0.71,-1.11 -1.81,0.75 -3.52,-3.22 -1.2,0.62 -0.43,-0.82 1,-0.83 -2,-2.79 3.01,-1.72
            -1.62,-1.19 -0.08,0.83 -2.35,-0.95 -0.99,0.58 -3.13,-2.47 -3.27,-9.7799999 -1.97,-2.84 0,0 3.44,-0.91 0.5,1.18 0.47,-1.62 5.91,-1.84 2.13,0.43 6.53,-2.61 0.72,2.24 z" />
        <path
            title="Oise"
            id="FR-60"
            onClick={() => props.setDepartment('60 - Oise')}
            stroke="blue" fill={props.department.substr(5) === 'Oise' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 286.42408,109.22159 3.17,-6.14 -1.28,-0.51 -0.61,1.42 -1.17,-0.99 1.32,-2.12 -1.02,-0.41 -0.4,-2.090002 -1.01,-0.17 1.3,-0.57 -1.13,-0.78 1.13,-1.42
            -1,-1.34 2.25,-3.24 -0.31,-0.47 -1.58,1.36 -0.71,-0.89 1.07,-0.78 -0.05,-1.76 1.36,-0.29 0.13,-1.56 1.49,0.38 0,0 2.27,1.14 -0.78,1.21 1.13,1.24 1.67,0.21
            1.84,-1.42 0.68,1.23 0.91,-1.18 2.59,0.54 1.33,1.82 4.85,-1.38 2.66,0.2 2.34,1.32 0.55,-0.6 2.28,2.18 3.09,0.18 1.35,2.06 1.17,-1.18 1.47,1.5 1.05,-0.21
            0.18,1.42 0.92,-1.01 1.35,0.14 0.17,1.99 0.86,0.48 1.61,-3.44 4.03,0.86 0.44,-3.12 2.04,0.05 0.62,-1.61 1.29,0.8 -1.09,-1.91 0.72,-0.74 0.4,1.01 0.88,-0.76
            1.66,2.86 -0.27,-1.86 1.25,-0.72 1.14,0.19 0.92,1.48 1.17,-0.97 -0.15,-1.21 1.3,0.08 -0.01,1.4 1.54,-0.98 0,0 0.31,2.84 -1.22,0.32 0.65,1.13 -0.86,1.05 1.87,3.04
            -0.5,3.130002 -1.33,0.8 1.25,1.78 -0.7,1.21 2.4,1.67 -2.95,0.43 0.06,3.6 -2.11,3.07 -1.8,-0.76 -1.33,0.86 0.71,2.47 2.17,0.11 0.08,3.45 -0.71,0.98 -0.88,-1.21
            -1.8,0.61 0.38,2.2 0.71,0.45 0.32,-0.69 1.22,1.58 0.57,-0.9 -1.38,-0.27 0.19,-1.7 3.18,1.48 0.18,2.11 0.52,-2.44 0.66,0.84 0,0 -0.01,0.52 0,0 -0.36,0.56 1.63,0.45
            -1.73,0.75 0.24,1.09 -0.68,-0.73 -0.95,1.64 0,0 -0.23,1.98 -2.39,-0.4 -0.89,1.29 -0.78,-1.29 -1.15,0.91 -1.69,-0.44 -2.02,0.87 -1.81,-1.67 -2.33,2.25 -1.32,-0.91
            -0.99,0.71 -2.64,-2.78 -1.77,1.8 0,0 -1.34,-1.17 -0.16,-1.62 -0.91,1.54 -1.74,-0.41 0.56,-0.72 -1.82,-1.46 -1.07,0.47 0.24,-0.82 -2.7,-0.83 -0.72,0.74 -1.82,-2.43
            -1.17,1.69 -2.42,0.51 -0.36,-1.75 -1.99,1 -0.94,-1.58 -0.48,0.6 -0.31,-0.93 -1.35,0.23 -0.64,-1.28 -3.28,2.18 -1.08,-0.49 -1.04,0.84 -4.8,0.39 0.06,-0.8 -1.02,0.21
            -0.36,-0.75 -2.57,0.27 -1.12,-1.61 1.05,-1.12 -1.09,-1.06 -0.41,0.58 0,0 0.24,-2.09 2.02,-0.49 1.29,1.67 0.62,-1.81 -0.26,-0.98 -1.05,-0.11 -0.47,-4.74 -1.64,-1.74
            0.76,-0.78 z" />
        <path
            title="Orne"
            id="FR-61"
            onClick={() => props.setDepartment('61 - Orne')}
            stroke="blue" fill={props.department.substr(5) === 'Orne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 180.36408,150.62159 2.62,-2.23 2.62,-0.79 1.3,-1.37 -0.92,-0.95 0.49,-0.61 2.06,0.41 -0.11,0.83 3.05,-0.18 4.37,-2.55 2.44,-0.02 0.94,1.6 1.04,-0.17
            -0.55,0.83 1.15,0.79 0.72,-2.11 0.99,-0.11 4.76,1.02 2.69,1.59 -0.93,-1.23 1,-0.77 2.33,0.5 1.71,-2.31 0.86,0.29 -0.03,-0.87 2.56,-1.01 1.03,-2.23 1.92,-0.87
            1.2,1.7 1.82,-1.18 1.43,0.22 0.55,-1.32 1.3,-0.4 0.01,1.12 1.25,-0.41 1.21,0.81 1.65,-1.86 1.58,1.4 0,0 -1.25,2.37 1.6,1.61 5.33,0.77 1.24,-1.17 0.37,1.32 1.08,-0.36
            -0.22,1.44 1.09,0.02 -1.12,1.41 6.12,4.07 0.66,1.87 -1.91,1.93 1.73,0.73 0.07,1.62 1.86,-0.04 0,0 0.68,2.27 -0.54,1.36 1.17,0.15 0.18,1.7 3.09,1.39 0.56,0.67
            -0.63,0.79 1.75,0.83 -1.01,1.47 0.55,1.11 -0.81,0.36 0.17,1.27 1.49,0.99 -2.57,4.22 -5.25,1.97 -0.73,1.17 -0.61,1.42 2.11,0.66 -0.75,1.75 1.75,2.94 -1.35,1.2
            0,0 -1.55,0.85 -0.93,-0.48 -2.25,-4.12 -1.25,-0.58 -0.92,0.48 0.3,1.43 -0.62,-0.8 -3.67,-0.17 -1.76,-2.24 -0.14,-1.58 -0.43,0.72 -3.62,-1.45 -0.91,-5.61 0.51,-0.82
            -1.1,-2.14 -2.28,-1.35 -5.33,0.97 0.06,0.91 -1.22,-0.43 0.51,1 -3.55,2.05 -0.56,1.92 -1.81,-0.19 -0.64,-1.03 -2.29,1.01 0,0 0.23,-4.37 -3.99,-0.36 -1.12,-3.35
            1.17,-1.09 -2.57,-2.22 -1.74,-0.05 -0.57,0.98 0.89,0.78 -1.24,1.81 -2.07,-0.94 -1.33,2.39 -1.81,-1.61 -4.7,0.06 -1.72,2.24 -1.92,0.1 -2.24,1.71 -0.69,-2.64
            -0.86,1.13 -1.73,-0.32 0.65,1.54 -2.08,0.75 -0.58,-1.79 -1.42,0.65 -1.95,-2.91 0,0 3.56,-3.82 1.47,-3.65 -1.42,-2.26 1.55,-1.79 -3.39,-2.84 -1.36,0.03 z" />
        <path
            title="Pas-de-Calais"
            id="FR-62"
            onClick={() => props.setDepartment('62 - Pas-de-Calais')}
            stroke="blue" fill={props.department.substr(5) === 'Pas-de-Calais' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 301.67408,5.5615881 0.24,0.14 0,0 1.97,2.84 3.27,9.7799999 3.13,2.47 0.99,-0.58 2.35,0.95 0.08,-0.83 1.64,1.19 -3.01,1.72 2,2.79 -1,0.83 0.43,0.82 1.2,-0.62
            3.52,3.22 1.81,-0.75 0.71,1.11 2.81,-0.48 0.66,1.04 1.78,-1.21 1.62,0.35 1.38,1.8 0.6,-2.09 1.27,0.12 -1.17,-0.97 0.2,-0.93 1.78,-0.38 2.49,2.47 -1.17,0.08
            -2.16,2.89 1.03,1.36 -0.03,0.96 -0.81,0.02 0.34,1.06 0.96,0.53 2.53,-0.76 -0.2,1.88 0.79,0.52 2.81,-0.7 2.5,1.76 -0.91,1.74 0.39,0.92 2.24,-0.9 -0.05,2.01
            -3.51,1.53 0.99,2.8 3.2,3.15 -1.25,0.56 -0.01,1.26 -1.67,-0.12 -0.15,0.96 1.53,-0.52 4.16,0.98 -0.02,1.05 1.67,1.11 -2.21,1.4 0.9,1.71 -2.32,1.22 1.2,1.56
            -1.58,1.07 0.92,1.92 -1.05,0.54 0.07,1.88 0,0 -0.57,0.54 -2.56,-0.88 -1.8,1.13 -1.03,-0.75 -3.42,2.11 -0.58,-0.65 1.08,-1 -1.15,-2.08 -3.93,2.55 0.99,-4.94
            -2.86,-0.85 0.22,1.22 -0.75,0.47 0.96,0.97 -0.64,0.41 -3.14,-1.83 -2.18,-0.25 0.47,-1.57 -1.24,-0.35 -0.99,1.87 -0.77,-1.75 -2.3,0.63 -1.67,2.83 -1.77,-1.43
            0.49,-2.84 1.33,-1.47 3.11,-1.18 -1.81,-2.32 -1.87,-0.27 -1.98,1.82 -1.09,-2.1 -0.72,0.29 0.5,1.39 -1.16,0.11 -1.21,-1.13 -1,1.19 -1.38,-0.52 -2.63,1.82 -0.83,-1.25
            -1.84,0.58 -0.88,-2.21 0.51,-1.12 -5.41,-2.31 -0.6,-0.75 1.39,-1.43 -0.75,-0.62 -1.61,1.27 -4.3,-3.19 -1.78,-0.07 -4.01,2.07 -0.97,-1.44 0,0 -3.64,-3.14 1.34,-8.96
            2.01,1.63 -2.44,-3.65 -0.7,-8.41 1.93,-5.02 -1.03,-6.31 3.31,-1.14 2.74,-3.2 2.4,-1.2399999 12.33,-3.51 z m 39.83,55.9099999 -1.57,0.09 0.33,2.45 1.09,-1.13 1.51,-0.09
            -0.2,-1.08 1.13,-0.95 -0.71,-0.52 0,0 -0.84,0.23 -0.74,1 z" />
        <path
            title="Puy-de-Dôme"
            id="FR-63"
            onClick={() => props.setDepartment('63 - Puy-de-Dôme')}
            stroke="blue" fill={props.department.substr(5) === 'Puy-de-Dôme' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 321.84408,310.33159 2.97,1.46 1.47,-3.18 2.04,-0.59 0.38,-2.43 3.2,1.57 0.93,-3.04 3.64,-0.23 1.1,0.59 -1.34,1.61 -0.01,2.16 1.6,0.44 0.68,2.78 1.9,1.28
            3.2,-0.73 0.29,1.86 1.28,0.81 7.19,0.25 1.22,1.24 3.61,-1.74 0.79,0.87 0,0 0.99,0 0,0 0.1,3.1 5.27,-0.93 1.71,3.68 1.84,0.23 0.7,1.65 0,0 2.56,2.62 -1.62,0.94
            0.29,3.36 -1.39,1.33 1.76,0.98 0.05,1.44 2,1.5 -0.15,1.99 1.94,3.54 4.57,3.16 1.13,4.23 0.99,0.63 -0.89,3.53 -2.98,2.14 0.24,2.49 0,0 -2.51,-0.49 -0.07,-1.01
            -1.39,-0.51 -1.12,2.54 -3.09,-0.63 0.31,-0.93 -1.59,-0.52 -0.25,1.29 -1.92,1.39 -1.98,-3.78 -1.68,0.21 -1.2,-1.76 -1.6,1.58 -1.75,0.2 -1.72,-0.17 -0.98,-1.25
            -1.19,1.48 -1.1,-1.37 -0.47,1.21 -2.18,0.38 -2.35,2.51 -2.81,-0.03 0,0 -2.15,1.22 -0.35,1.69 -2.15,0.72 -2.13,-1.08 -1.08,-3.52 -1.6,-0.9 -0.69,0.44 -2.27,-1.44
            -1.18,0.94 -1.76,-0.52 -1.14,0.68 -1.42,-1.5 0.33,-1.8 -2.66,-0.38 -0.26,-1.52 -1.26,1.22 -0.92,-0.95 -1.19,0.52 0.29,-1.31 -1.46,0.01 0,0 0.38,-4.47 -1.18,-0.39
            -1.16,-2.08 1.03,-2.65 1.41,0 0.41,-1.06 -0.71,-0.47 0.45,-2.58 -1.33,-1.67 0,0 -2,-1.23 -2.29,-4.04 2.42,-1.05 -0.07,-1.28 1.98,0.15 0.31,-1.35 2.35,-1.61 -0.51,-1.1
            0.83,-1.46 1.86,-0.62 -0.67,-1.43 0.36,-2.61 -1.25,-0.73 -0.89,-2.32 z" />
        <path
            title="Pyrénées-Atlantiques"
            id="FR-64"
            onClick={() => props.setDepartment('64 - Pyrénées-Atlantiques')}
            stroke="blue" fill={props.department.substr(5) === 'Pyrénées-Atlantiques' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 151.98408,462.87159 1.93,-0.55 0.44,1.3 1.53,0.14 0.53,0.97 5.19,-0.07 4.51,-2.8 2.15,1.61 -1.65,0.76 1.13,1.04 2.59,-2.03 0.53,0.8 3.13,0.26 0.14,-2.18
            1.69,0.6 1.82,-1.06 2.47,0.49 3.2,-2.13 2.13,2.21 0.45,-1.13 2.71,-0.82 -0.66,1.06 2.56,1.03 1.19,-1.15 0.86,0.74 4.08,-2.93 0.74,0.63 -1.28,1.28 0.54,0.75
            2.27,-1.1 2.43,1.04 2.18,-2 1.69,0.07 0,0 2.72,-0.66 0.69,0.91 2.66,-0.09 0,0 0.12,2.2 1.13,-0.27 1.08,2.03 -0.41,1.18 1.31,1.12 -1.97,0.51 -0.22,1.59 0.85,0.93
            -0.69,0.46 1.01,0.04 1.75,-2 -0.12,4.16 1.38,1.48 -0.81,1.19 -1.53,-0.22 -0.81,1.58 1.2,1.76 -1.33,2.95 -0.99,-0.24 0.22,2.61 -1.59,0.59 -0.46,-0.76 -1.19,2.9
            -1.95,0.83 -0.25,2.68 0.78,0.86 -3.35,0.83 0.05,1.28 -1.19,0.52 0.28,4.03 -1.92,0.94 0.59,3.83 0,0 -3.38,2.85 -2,0.2 -2.64,-1.8 -1.99,2.68 -5.16,-5.93 -1.64,-0.13
            -1.51,-4.58 -2.26,0.96 -4.04,-0.71 -1.56,0.59 -7.1,-4 -1.16,0.91 -1.25,-1.7 -2.33,-0.98 -1.4,0.66 -1.93,-1.41 1.59,-2.83 -2.57,0.66 -0.88,4.44 -3.57,-1 -1.37,-2.38
            2.44,-2.23 1.35,-3.49 -0.27,-3.9 -6.08,-2.04 -1.15,0.32 -0.28,1.88 -1.61,0.19 -0.66,-3.13 -1.87,-0.47 -2.49,1.07 -0.34,-1.94 -2.05,-1.16 0.69,-0.82 -0.61,-0.42
            4.87,-0.93 1.57,-1.38 4.38,-6.63 z m 58.97,9.74 0,0 0.69,1.31 -0.9,-0.12 -0.29,0.86 0.98,0.7 0,0 0.94,-0.13 0,0 -0.09,-2.78 0,0 -1,-0.76 0,0 -0.49,0.35 0.16,0.57
            z m -0.77,3.22 0,0 -0.75,1.72 0.78,1.75 1.73,-1.24 -0.82,-2.31 0,0 -0.46,-0.37 -0.48,0.45 z" />
        <path
            title="Hautes-Pyrénées"
            id="FR-65"
            onClick={() => props.setDepartment('65 - Hautes-Pyrénées')}
            stroke="blue" fill={props.department.substr(5) === 'Hautes-Pyrénées' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 210.66408,475.38159 0.46,0.37 0,0 0.82,2.31 -1.73,1.24 -0.78,-1.75 0.75,-1.72 0,0 0.48,-0.45 z m 1.51,-2.98 0.11,0.07 0,0 0.09,2.78 0,0 -0.94,0.13 0,0
            -0.98,-0.7 0.29,-0.86 0.9,0.12 -0.69,-1.31 0,0 -0.16,-0.55 0,0 0.49,-0.35 0,0 0.89,0.67 z m -0.91,-12.53 0.95,-1.38 2.46,0.04 0.49,2.29 2.29,2.71 2.67,0.18
            1.95,4.02 -1.35,1.2 0.88,1.41 0.86,-0.22 0.36,1.85 4.75,-1.04 0.49,1.18 0.66,-0.43 0.29,1.8 2.39,-0.74 0.91,1.55 1.28,0.04 0.09,-0.71 6.78,1.72 0,0 1.15,0.82
            -3.5,3.37 0.92,0.91 -2.32,0.43 0.18,1.2 -3.37,3.46 0.92,0.51 -0.35,0.63 1.49,-0.25 0.48,1.09 2.67,1.18 -0.05,2.25 -1.13,-0.34 -0.38,2.07 3.3,-1.67 0.82,2.03
            -0.57,0.91 1.35,1.21 -1.92,1.85 -1.58,3.78 -3.54,-0.96 -1.19,8.14 1.48,1.31 -0.36,0.57 0,0 -2.21,0.51 -2.59,-1.87 -2.73,2.85 -1.44,-2.38 -3.46,-1.19 -7.4,2.93
            -2.27,-0.41 -0.49,-1.42 -1.81,-0.47 -1.99,-4.06 -1.14,0.62 -5.19,-3.52 0,0 -0.59,-3.83 1.92,-0.94 -0.28,-4.03 1.19,-0.52 -0.05,-1.28 3.35,-0.83 -0.78,-0.86 0.25,-2.68
            1.95,-0.83 1.19,-2.9 0.46,0.76 1.59,-0.59 -0.22,-2.61 0.99,0.24 1.33,-2.95 -1.2,-1.76 0.81,-1.58 1.53,0.22 0.81,-1.19 -1.38,-1.48 0.12,-4.16 -1.75,2 -1.01,-0.04
            0.69,-0.46 -0.85,-0.93 0.22,-1.59 1.97,-0.51 -1.31,-1.12 0.41,-1.18 -1.08,-2.03 -1.13,0.27 -0.13,-2.17 z" />
        <path
            title="Pyrénées-Orientales"
            id="FR-66"
            onClick={() => props.setDepartment('66 - Pyrénées-Orientales')}
            stroke="blue" fill={props.department.substr(5) === 'Pyrénées-Orientales' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 305.08408,511.95159 1.26,0.7 2.96,-3.22 1.39,0.41 2.3,-1.48 -0.46,-4.13 -0.95,-1.27 0.73,-0.99 6.78,-0.55 9.84,0.85 2.11,-3.43 3.13,-1.24 7.46,4.46 0,0
            -0.4,9.76 0.74,6.87 3.72,1.55 -0.75,0.59 2.13,3.96 -3.67,0.52 -1.89,-2.67 -3.06,0.43 -0.83,-0.94 -1.22,1.45 -3.25,-0.14 -2.54,2.63 -1.86,-0.68 -2.44,1.09 -0.95,1.03
            0.83,2.57 -3.95,-0.99 -1.93,1.43 -2,-0.41 -2.04,-2.98 -7.31,-2.5 -2.3,1.25 -1.91,-0.44 -2.91,3.43 -3.02,0.58 -1.76,-1.25 -1.49,-4.41 -2.22,0.21 -2.4,-2.13 -3.92,-0.45
            0.39,-3.41 2,-1.04 0,0 3.43,-0.35 1.31,-1.97 1.38,0.59 1.66,-0.69 1.01,-2.49 6.87,-0.11 z" />
        <path
            title="Bas-Rhin"
            id="FR-67"
            onClick={() => props.setDepartment('67 - Bas-Rhin')}
            stroke="blue" fill={props.department.substr(5) === 'Bas-Rhin' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 509.02408,164.19159 3.69,0.49 4.15,-3.94 0.59,-2.38 -0.63,-0.13 1.68,-1.84 -1.62,-0.15 -0.96,-1.86 0.94,-0.41 2.06,-4.31 -1.11,-1.94 -1.62,-0.61 0.45,-0.9
            -1.53,-0.45 -0.07,0.7 -1.52,-1.94 -1.75,0.51 -0.68,2.45 -2,1.1 -0.47,-0.96 0.92,-0.73 -1.54,-0.28 -0.04,-1.77 1.87,-0.43 -0.04,-0.73 -1.85,0.22 -0.08,-0.98 -3.9,-1.19
            -0.45,-2.51 1.44,0.01 0.49,-1.77 1.74,0.32 -0.07,-1.94 1.94,-4.01 -0.41,-0.94 1.28,-0.76 0.78,0.5 -0.65,0.45 1.04,3.56 1.95,0.01 1.58,1.64 1.11,-0.53 2.34,0.9
            -0.14,1.15 1.46,0.77 5.24,-1.58 3.52,2.27 1.73,-1.55 2.42,-6.09 0,0 1.52,0.57 2.44,-0.76 0.12,0.82 2.44,-1.41 3,2.04 0.92,-0.96 1.3,0.44 0.58,-1.02 1.78,1.91
            5.02,2.36 5.6,1.53 -1.45,0.56 -2.01,3.32 -2.47,6.42 -2.48,1.03 -0.58,1.6 -1.82,0.21 -0.33,2.21 -5.18,5.07 -1.62,3.57 0.25,4.34 -1.56,1.53 -1.49,5.98 0.51,4.19
            -2.14,1.58 -1.11,4.98 -3.72,6.27 0,0 -2.4,-0.38 0.01,-1.42 -2.07,-0.65 0.29,-2.69 -6.7,-2.99 0.69,-1.2 -1.62,0.07 0.35,-0.78 -1.05,-1.41 -3.2,-0.33 0,0 -0.8,-1.83
            -2.62,0.4 -1.7,-1.19 1.34,-1.57 -0.97,-1.29 1.38,-5.33 -1.18,-1.24 1.84,0.36 -0.66,-1 0,0 -1.6,0.07 z" />
        <path
            title="Haut-Rhin"
            id="FR-68"
            onClick={() => props.setDepartment('68 - Haut-Rhin')}
            stroke="blue" fill={props.department.substr(5) === 'Haut-Rhin' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 514.12408,178.23159 3.2,0.33 1.05,1.41 -0.35,0.78 1.62,-0.07 -0.69,1.2 6.7,2.99 -0.29,2.69 2.07,0.65 -0.01,1.42 2.4,0.38 0,0 -0.3,5.37 2.19,3.85 -2.73,5.74
            0.23,2.34 -1.35,3.82 0.76,2.8 -1.51,2.24 0.48,2.22 2.83,3.49 -0.27,1.31 -3.57,2.67 1.31,0.71 -1.75,2.78 -2.37,-0.79 -0.44,0.91 1.44,0.57 -2.87,2.38 -2.4,-0.56
            -3.54,1.22 -0.46,-1.1 -2.58,-0.28 1.32,-3.07 -2.88,-0.55 0,0 0.44,-1.33 -2.37,-4.18 -1.86,-0.47 -0.86,0.73 -0.63,-0.83 0.24,-2.26 1.44,-2.01 -0.8,-1.76 0.41,-1.38
            -4.01,-2.96 -3.07,-0.71 -1.25,-2.43 0,0 3.46,-1.71 -0.96,-2.53 1.36,-1.17 -0.72,-0.59 1.33,-4.97 2.35,-1.35 3.18,-5.46 0.32,-1.28 -1.04,-0.94 4.69,-9.48 z" />
        <path
            title="Rhône"
            id="FR-69"
            onClick={() => props.setDepartment('69 - Rhône')}
            stroke="blue" fill={props.department.substr(5) === 'Rhône' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 397.42408,305.73159 0.38,-3.76 1.26,-1.17 2.54,0.84 0.64,1.26 1.77,-0.39 0.62,-1.24 0.91,1.53 1.45,0.22 0.79,-2.12 1.77,-0.31 1.27,1.65 -1.29,1.18 2.35,1.53
            -0.7,0.35 0.66,0.9 -0.39,1.94 2.25,0.22 0,0 0.94,1.49 -2.33,3.73 0.32,6.96 -1,1.81 3.27,1.31 -0.17,1.4 1.8,-0.99 1.39,1 1.54,3.26 0,2.26 7.62,-0.63 0,0 -0.53,1.8
            -1.21,-0.48 0.25,1.36 1.31,-0.1 -0.15,1.8 1.77,0.46 0.77,1.76 -1.82,-0.03 -2.16,2.21 -0.91,3.03 -1.37,-0.56 -0.65,0.26 0.37,0.88 -4.04,-0.11 -1.64,0.84 -0.1,0.92
            -3.42,-0.62 4.01,3.39 -3.78,3.4 -0.03,0.95 -0.97,0.03 0,0 -1.44,-2.31 -1.08,1.03 -1.81,-0.62 -0.1,-2.61 1.21,-1.73 -1.58,1.04 -1.11,-1.89 -6.03,-0.61 -1.19,-2.24
            -3.15,-2.78 1.53,-4.36 -1.69,-0.41 -0.54,-1.1 1.21,-0.82 0.77,-4.49 -2.23,-1.1 -0.84,-1.52 0.95,-1.56 -4.19,-3.86 2.3,0.35 0.55,-1 -2.63,-2.61 2.24,-1.24 0.78,-3.59
            2.41,-1.15 1.48,0.8 0.82,-1.48 -1.64,-1.68 0.99,-1.31 z" />
        <path
            title="Haute-Saône"
            id="FR-70"
            onClick={() => props.setDepartment('70 - Haute-Saône')}
            stroke="blue" fill={props.department.substr(5) === 'Haute-Saône' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 459.57408,202.06159 2.01,-3.18 1.13,0.57 -1.34,1.86 0.9,0.14 0.89,-1.32 1.32,0.05 1.54,-2.91 3.8,-1.26 1.01,1.05 0.35,1.92 -0.64,0.58 2.44,1.18 -0.14,0.95
            3.45,-1.46 4.23,-0.31 1.91,1.17 1.78,3.47 2.71,-1.09 0.84,-1.82 1.64,-0.82 1.78,2.6 5.8,3.3 1.54,2.2 0,0 -2.71,4.14 1.77,6.77 -0.91,1.42 1.75,2.48 -0.6,0.69 0,0 
            -1.6,1.51 -4.48,-2.62 0.42,1.41 -0.89,1.29 -1.58,0.35 -1.23,-0.87 -0.55,0.83 0.66,1.3 -1.12,1.21 -2.35,-1.27 -1.18,1.49 -0.33,-1.54 -2.15,-0.66 -0.89,1.05 -2.29,-0.06
            -0.54,1.3 -0.81,-0.36 -0.52,2.68 -1.06,0.04 -0.34,1.35 -0.95,-0.35 0.31,1.06 -1.45,-1.11 -1.48,1.17 1,0.52 -1.04,0.87 -2.15,-0.43 -0.18,2.18 -1.14,-0.75 -0.45,1.32
            -3.96,1.59 -2.12,-1.23 0.16,1.05 -1.16,-0.35 -0.24,1.42 -0.64,-0.67 -1.58,1.31 -2.79,0.54 -1.45,1.78 -1.76,-0.18 0,0 -1.03,-0.64 -1.43,1.26 -2.66,0.12 -2.44,-3.06
            0,0 -2.07,-0.79 1.03,-1.07 0.23,-3.37 -2.77,-0.2 0.27,-3.54 -1.34,-0.67 -1.13,0.64 -0.51,-0.83 1.71,-0.78 -0.85,-0.24 0.48,-1.09 1.59,0.5 2.08,-2.24 -0.37,-4.85
            -2.11,-1.54 -1.37,2.26 -0.93,-0.64 0,0 1.58,-4.29 2.15,0.16 0.86,-0.74 1.77,0.67 1.74,-2.12 1.22,2.17 3.9,-0.77 -0.26,-2.32 1.01,-1.5 -0.13,-1.29 -1.23,-0.7 0.62,-2.57
            2.1,0.05 0.79,-2.43 2.79,0.45 -0.25,-1.16 1.19,-2.18 1.4,0.39 z" />
        <path
            title="Saône-et-Loire"
            id="FR-71"
            onClick={() => props.setDepartment('71 - Saône-et-Loire')}
            stroke="blue" fill={props.department.substr(5) === 'Saône-et-Loire' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 390.05408,249.29159 0.1,0.69 1.34,-0.17 1.2,2.4 3.02,0.39 0.34,2.31 1.98,-1.17 0.57,0.3 -0.62,1.5 1.08,0.93 2.54,0.25 0.81,1.26 1.92,-0.46 -0.28,1.72 0.79,-0.05
            1.97,3.14 2.71,0.66 -0.59,0.91 0.94,0.93 1.44,-1.32 2.43,0.22 0.37,-0.87 4.3,-1.14 0.06,-0.94 4.29,0.49 0.22,-0.83 1.91,-0.55 2.21,1.45 -0.21,0.79 0.96,-1.11 3.08,-0.43
            0.38,-0.92 2.15,0.31 0,0 -0.17,2.18 0.5,0.93 1.46,-0.45 1.24,2.95 2.9,-0.3 0.42,1.71 2.16,0.61 0.08,1.6 -2.28,-0.34 -3.37,1.08 0.55,1.5 2.1,1.22 -1.27,2.45 1.33,0.08
            0.65,1.85 -0.54,0.77 1.92,2.85 -1.89,1.64 0.72,0.62 -0.37,1.11 0,0 -0.85,0.01 0,0 -0.9,0.21 -0.11,3.51 2.56,1.04 -0.16,1.53 -3.87,0.83 -0.39,1.01 0,0 -3.26,-0.7
            -1.23,-2.86 -1.58,-0.81 -0.05,0.91 -1.3,-0.28 -3.26,1.47 -3.02,-1.84 -1.39,0.84 -0.4,-1.07 -6.69,20.52 0,0 -2.25,-0.22 0.39,-1.94 -0.66,-0.9 0.7,-0.35 -2.35,-1.53
            1.29,-1.18 -1.27,-1.65 -1.77,0.31 -0.79,2.12 -1.45,-0.22 -0.91,-1.53 -0.62,1.24 -1.77,0.39 -0.64,-1.26 -2.54,-0.84 -1.26,1.17 -0.38,3.76 0,0 -1.07,1.25 0.37,0.88
            -1.8,-0.03 -2.17,1.72 -1.06,-0.13 0.19,-1.74 -1.19,0.59 -0.75,-1 -1.22,1.23 -1.88,-0.2 -1.25,-1.29 -3.5,1.59 0,0 -1.04,0 0,0 -0.86,-1.82 -3.42,-0.7 0.79,-1.92
            -0.43,-1.76 0,0 -0.07,-1.04 1.95,-0.44 0.09,-1.26 1.7,0.41 0.71,-0.76 -0.82,-0.08 -0.36,-4.18 0.98,-3.8 -1.95,-0.95 0.02,-0.89 -3.59,0.21 -1.15,-2.55 -1.39,0.76
            -2.64,-1.2 -0.23,-3.82 -0.95,-0.4 0.34,-1.1 -1,-1.93 -2.47,-2.9 -0.39,-2.55 0,0 1.28,0.69 3.23,-0.89 1.89,1 0.58,2.08 5.43,-2.37 2.88,-2.32 1.53,-0.54 0.86,0.61
            -0.46,-3.56 2.34,-1.85 -2.59,-2.55 0.74,-2.88 -0.7,-1.71 -1.21,0.86 -0.64,-0.86 2.63,-1.72 0.69,-2.55 -1.46,-1.4 0.49,-2.07 2.79,-0.71 0,0 2.49,0.67 0.45,-2.27 0,0 z" />
        <path
            title="Sarthe"
            id="FR-72"
            onClick={() => props.setDepartment('72 - Sarthe')}
            stroke="blue" fill={props.department.substr(5) === 'Sarthe' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 212.92408,173.81159 2.29,-1.01 0.64,1.03 1.81,0.19 0.56,-1.92 3.55,-2.05 -0.51,-1 1.22,0.43 -0.06,-0.91 5.33,-0.97 2.28,1.35 1.1,2.14 -0.51,0.82 0.91,5.61
            3.62,1.45 0.43,-0.72 0.14,1.58 1.76,2.24 3.67,0.17 0.62,0.8 -0.3,-1.43 0.92,-0.48 1.25,0.58 2.25,4.12 0.93,0.48 1.55,-0.85 0,0 4.9,3.44 -2.69,0.34 -0.41,1.83 0,0
            -1.18,0.63 1.35,-0.07 -0.09,1.37 -2.05,0.15 0.01,1.38 0.57,1.01 1.44,0.14 -0.98,2.7 1.26,2.84 -1.39,0.49 -0.17,2.7 -0.89,-1.45 -1.21,0.69 0.61,3.64 -3.43,3.59 0.38,1
            -3.13,0.95 -1.86,2.34 0.83,1.77 0.61,-0.66 0,0 -0.83,1.44 -2.41,0.92 0,0 -0.87,0.03 0,0 -2.2,0.71 -0.61,1.58 -1.04,0.17 -1.7,-1.67 -0.78,1.47 1.6,2.52 -1.02,0.62
            -4.95,-2.62 -1.29,0.13 0,0 -0.98,0.42 -0.83,-0.86 -0.06,1.12 -1.62,1.02 -1.25,-1.47 -2.19,0.13 -2.03,-1.18 -0.61,-1.45 -0.78,0.58 -2.14,-1.36 -0.82,0.46 -0.05,-0.71
            -0.75,1.7 -1.1,0.11 -2.47,-1.03 -0.85,-1.98 1.78,-2.11 -1.08,-0.49 -1.41,1.29 -2.12,-0.99 -3.21,0.32 0.3,-1.07 -1.09,-1.74 0,0 -0.17,-2.62 -1.74,-0.25 -0.71,-1.54
            1.47,-1.67 1.6,0.03 -0.11,-1.78 -1.4,-0.45 -0.2,-1.32 5.64,-2.59 -1.09,-0.5 -0.22,-2.47 -1.38,-0.37 0,-1.89 2.9,-1.43 1.67,0.4 -0.28,-2.38 0.9,-0.91 -1.6,-1.54 0.7,-0.67
            -0.64,-0.63 4.49,-2.93 -0.91,-3.65 1.17,-1.83 -0.78,-2.93 1.47,0.11 -0.61,-1.58 z" />
        <path
            title="Savoie"
            id="FR-73"
            onClick={() => props.setDepartment('73 - Savoie')}
            stroke="blue" fill={props.department.substr(5) === 'Savoie' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 457.20408,322.53159 1.47,0.61 0.39,5.58 1.27,0.42 0.41,1.38 2.05,-0.52 1.3,3.88 2.05,0.51 0.48,-0.7 1.93,0.95 0.03,-1.62 0.95,0.72 1.69,-0.34 1.24,1.37
            -0.16,2.25 1.27,-0.7 0.23,1.44 4.2,-0.65 1.78,-3.61 -0.38,-0.86 2.59,-2.07 1.07,-3.51 2.44,-2.75 2.58,1.67 -0.12,1.1 -1.42,0.02 1.06,2.52 1.57,1.6 2.61,-0.43
            1.24,1.74 -0.05,2.55 0.98,0.25 1.78,-2.57 1.84,-0.77 0,0 1.14,4.57 3.18,1.83 0.45,1.19 3.53,0.74 -0.97,3.04 0.77,0.8 0.23,4.17 2.22,0.55 -0.23,1.32 2.11,0.15
            0.61,2.15 3.02,1.63 -1.01,2.64 -2.19,1.96 1.03,4.2 -2.21,2.31 -3.34,-0.2 -2.32,2.96 -2.18,0.09 -0.01,1.86 -1.84,0.56 -3.37,-1.9 -1.06,1.26 -2.57,-0.15 -2.04,1.8
            0,0 -2.19,-0.87 -1.51,1.41 -1.3,-0.56 -1.25,0.96 0.26,2.22 -1.47,0.18 -3.58,-1.06 -0.03,-1.9 -1.28,-1.21 -1.63,0.89 -1.39,-1.08 0,0 -0.45,-1.45 -1.13,0.64 -1.76,-1.46
            -1.52,0.63 0.74,-2.02 -1.65,-3.24 0.77,-1.06 -0.41,-1.41 2.31,-1.96 0.37,-1.99 -1.04,-0.3 0.94,-0.66 -2.55,-3.83 -3.4,-0.14 -3.45,-3.25 -2.15,0.9 -0.51,4.04
            1.04,-0.35 -0.77,1.43 -0.77,-0.09 -4.16,-2.92 -2.1,0.05 -1.1,-3.96 -1.64,-1.49 0,0 0,-0.65 0,0 -2,-4.14 0,0 2.58,-1.67 0.61,-4.09 1.97,0.3 1.08,-1.12 z" />
        <path
            title="Haute-Savoie"
            id="FR-74"
            onClick={() => props.setDepartment('74 - Haute-Savoie')}
            stroke="blue" fill={props.department.substr(5) === 'Haute-Savoie' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 497.26408,296.93159 -0.9,0.54 0.57,1.43 3.32,3.23 -2.52,4.58 -0.28,3.95 4.2,0.77 -1.05,4.35 0.71,0.53 1.93,-1.22 3.65,5.09 -0.63,0.48 1.16,0.93 0.28,1.79
            -1.74,1.45 -0.25,1.67 -2.31,1.49 -4.97,0.57 -0.86,3.43 0,0 -1.84,0.77 -1.78,2.57 -0.98,-0.25 0.05,-2.55 -1.24,-1.74 -2.61,0.43 -1.57,-1.6 -1.06,-2.52 1.42,-0.02
            0.12,-1.1 -2.58,-1.67 -2.44,2.75 -1.07,3.51 -2.59,2.07 0.38,0.86 -1.78,3.61 -4.2,0.65 -0.23,-1.44 -1.27,0.7 0.16,-2.25 -1.24,-1.37 -1.69,0.34 -0.95,-0.72 -0.03,1.62
            -1.93,-0.95 -0.48,0.7 -2.05,-0.51 -1.3,-3.88 -2.05,0.52 -0.41,-1.38 -1.27,-0.42 -0.39,-5.58 -1.47,-0.61 0,0 0.24,-2.05 -1.17,-2.49 0.59,-5.3 2.77,1.09 0.26,-1.99
            2.64,-0.81 0,0 1.56,-0.72 1.81,0.44 0.6,-0.86 3.17,0.72 4.31,-3.95 2.62,-1.15 0.59,-1.85 -2.02,0.28 -0.96,-1.55 0.43,-1.57 -1.14,-0.5 1.33,-2.89 11.07,-5.82 6.78,0.15
            5.73,1.65 z" />
        <path
            title="Ville de Paris"
            id="FR-75"
            onClick={() => props.setDepartment('75 - Ville de Paris')}
            stroke="blue" fill={props.department.substr(5) === 'Ville de Paris' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 311.66408,141.36159 2.92,0.04 0.78,2.62 0,0 0.04,1.49 2.23,-0.38 -0.34,1.18 -2.46,-0.74 -2.8,0.87 0,0 -4.41,-2.24 0.83,-1.32 1.08,0.63 z" />
        <path
            title="Seine-Maritime"
            id="FR-76"
            onClick={() => props.setDepartment('76 - Seine-Maritime')}
            stroke="blue" fill={props.department.substr(5) === 'Seine-Maritime' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 272.46408,67.141588 1.27,0.47 1.68,-0.84 0.15,1.97 2.56,1.69 3.74,4.53 3.06,1.64 4.45,10.26 0,0 -1.49,-0.38 -0.13,1.56 -1.36,0.29 0.05,1.76 -1.07,0.78 0.71,0.9
            1.58,-1.37 0.31,0.47 -2.25,3.24 0.99,1.34 -1.13,1.42 1.13,0.79 -1.29,0.57 1.01,0.17 0.4,2.090002 1.02,0.41 -1.32,2.12 1.17,0.99 0.61,-1.42 1.28,0.5 -3.17,6.14 0,0
            -0.83,0.81 -0.59,-0.76 -1.02,0.47 -2.1,-0.8 -1.22,-1.75 -1.56,0.59 -2.5,-1.38 -3.85,0.18 0.1,-0.91 -1.75,0.93 -0.32,1.28 -1.12,-0.05 -1.52,5.27 -3.08,-0.57 0.17,1.2
            -1.86,-0.36 -0.98,2.04 -3.63,0.7 0.62,2.54 -2.34,0.48 -1.43,-1.9 -0.7,0.61 0.33,-1.52 -0.98,-1.73 -1.55,1.56 -0.15,-1.16 -2.25,-0.51 0.64,-1.45 2.46,0.53 -0.07,-2.96
            -1.61,0.82 -0.07,-1.55 -2.35,0.01 -0.79,-1.97 -0.68,1.12 -0.81,-0.58 -4.31,1.08 -0.41,-1.23 -0.98,-0.01 0.56,-1.44 -2.32,0.81 -3.57,-3.42 -4.82,2.59 -3.59,0.74 0,0 0,0 0,0
            -0.52,0.02 0,0 -0.17,-1.19 -0.41,0.82 -6.53,-2.01 -1.98,-2.54 4.06,-11.310002 1.23,-1.25 5.94,-2.61 10.37,-7.04 7.75,-1.14 7.77,-3.29 2.45,0.71 3.61,-1.43 11.3,-8.18 z" />
        <path
            title="Seine-et-Marne"
            id="FR-77"
            onClick={() => props.setDepartment('77 - Seine-et-Marne')}
            stroke="blue" fill={props.department.substr(5) === 'Seine-et-Marne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 342.79408,127.66159 3.83,1.13 -0.47,1.02 1.55,2.05 -0.97,2.39 3.48,2.51 0.55,2.12 1.67,-0.62 1,2.56 1.6,-1.28 0.64,3.55 2.6,0.73 0.64,1.43 1,-0.92 0,0 0.08,2.4
            -3.48,0.33 1.58,0.35 0.08,0.8 -1.43,0.4 -0.6,1.6 3.06,1.27 0.35,2.49 -1.52,2.16 0.81,0.7 -0.35,1.09 3.45,-0.7 -0.58,0.84 1.63,1.02 0,0 -2.41,0.74 0.51,0.93 -2.04,1.21
            0.67,1.8 -3.09,0.86 1.3,2.34 -2.26,0.67 0.94,1.65 -0.59,1.79 1.27,0.57 -0.31,1.6 0,0 -1.6,-0.61 -0.46,1.72 -1.2,0.16 -2.22,-0.74 -1.16,1.06 -4.72,-0.5 -1.54,1.48
            -2.33,-0.65 -1.33,3.38 1.33,3.44 -1.78,2.66 -2.78,1.46 -0.01,1.26 0,0 -2.94,0.43 -1.94,1.63 -0.76,-0.11 -0.09,-2.3 -1.5,0.95 -1.1,-0.83 0.74,1.33 -2.02,1.36 -6.07,-1.09
            -1.57,1.04 -3.23,0.02 1.64,-2.53 0.97,0.51 0.68,-2.7 -0.71,-2.36 -3.41,-1.34 -0.81,-3.48 0,0 -0.48,-1.21 1.71,0.02 0.44,-1.41 -0.6,-0.3 2.27,-1.32 -0.43,-0.41
            3.01,-0.72 -1.8,-1.75 0.49,-4.73 -0.69,-2.52 1.88,-3.53 -1.26,-1.67 1.2,-0.3 -0.51,-1.71 2.26,-1.02 -0.53,-0.9 0,0 0.55,-3.11 1.19,-1.34 -1.12,-0.69 0.34,-2.04 0,0
            -0.91,-2.76 0.44,-1.26 -1.03,-1.05 1.13,-0.57 0.7,-2.43 -1.68,-2.83 0.81,-0.09 -0.56,-2.01 -0.74,0.06 0,0 -0.22,-0.74 2.01,-1.51 -1.03,-0.86 0.88,-1.2 0,0 1.77,-1.8
            2.64,2.78 0.99,-0.71 1.32,0.91 2.33,-2.25 1.81,1.67 2.02,-0.87 1.69,0.44 1.15,-0.91 0.78,1.29 0.89,-1.29 2.39,0.4 z" />
        <path
            title="Yvelines"
            id="FR-78"
            onClick={() => props.setDepartment('78 - Yvelines')}
            stroke="blue" fill={props.department.substr(5) === 'Yvelines' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 277.59408,138.79159 -0.36,-1.69 1.1,-0.72 -2.02,0.17 0.34,-2.41 -1.26,-2.49 0.6,-0.66 1.07,0.84 0.88,-2.2 0.53,1.11 3.56,-0.59 0,0 2.68,-0.04 2.08,2.11 4.45,-2
            1.47,1.22 -0.61,1.51 1.03,1 1.32,-1.93 1.24,1.65 2.26,0.01 1.29,1.49 4.04,-1.23 -0.02,1.61 1.86,0.49 0.39,1.93 1.27,0.14 -0.16,2.71 0,0 -1.36,0.88 -0.86,1.52 0.53,1.41
            -0.7,0.31 0.74,3.84 1.33,1 0,0 -3.78,1.77 -0.25,2.57 -0.67,0.8 -1.48,-0.18 -0.66,1.92 -0.73,-0.2 2.12,2.73 -1.22,0.63 -0.63,3.04 -3.4,-0.19 1.78,2.15 -1.35,0.54
            -0.99,3.7 0,0 -0.62,1.12 -1.55,-0.02 -1.09,-0.39 -0.32,-1.28 -1.42,0.03 -1.07,-2.91 0.47,-2.52 -1.24,-1.3 -2,-0.17 0.25,-2.34 -2.03,0.02 -0.98,-2.24 -1.58,-0.76
            0.33,-1.68 -1.46,-0.88 2.07,-2.76 -1.88,-0.9 -0.09,-4.39 0.79,-0.54 -0.89,-0.33 0.32,-0.96 -1.61,-0.65 0.72,-1.27 z" />
        <path
            title="Deux-Sèvres"
            id="FR-79"
            onClick={() => props.setDepartment('79 - Deux-Sèvres')}
            stroke="blue" fill={props.department.substr(5) === 'Deux-Sèvres' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 178.23408,260.22159 1.64,0.36 0.39,-1.21 1.44,0.15 0.87,-1.1 3.02,1.3 1.48,-0.22 0.35,-0.95 2.96,0.38 2.3,-2.16 -0.53,-1.92 2.56,-1.18 1.45,0.12 -0.9,1.71
            0.95,-0.94 2.11,0.13 0.45,-1.48 2.34,0.26 3.1,-1.15 2.54,0.7 0.84,-0.85 1.75,0.39 -1.37,2.12 1.07,-0.2 0.82,0.99 1.13,-0.62 0,0 1.05,3.36 -0.67,-0.25 0.28,1.64
            1.27,-0.52 0.93,0.74 -0.51,1.32 1.51,3.22 -1.08,1.99 2.87,1.25 -0.77,1.19 -1.19,-0.8 -1.43,1.63 2.32,0.37 -1.41,1.64 0.16,1.17 2.5,2.15 -1.7,1 0.19,0.98 -0.98,0.37
            0.56,0.62 -2.51,3.31 2.84,-0.88 -0.54,1.7 1.49,-0.02 0.01,1.19 0.79,0.19 -2.05,1.39 -0.2,1.06 0.83,0.57 -0.59,1.15 -0.98,-0.15 0.08,2.4 -0.72,0.14 0.16,0.83 1.34,0.1
            -0.56,4.07 2.36,1.62 -0.83,1.19 0.99,0.49 -0.91,1.1 2.57,1.26 1.96,-2.6 2.18,1.13 -1.07,1.58 0.87,1.46 -1.76,0.7 0.6,2.26 -1.27,0.99 -0.22,1.6 1.31,0.29 1.35,1.79
            1.92,-0.41 -1.08,3.78 0,0 -0.98,0.85 -1.37,-1.4 -1.57,-0.05 -0.08,0.96 -1.27,-0.1 -0.14,1.13 -1.82,1.19 -2.29,-0.11 0.65,1.46 -1.52,1.79 0.81,0.41 -2.69,1.37 0,0
            -1.42,-0.54 -0.07,-1.24 -1.03,-0.64 0.19,-1.6 -1.19,0.65 -0.2,-1.63 -0.83,0.6 -1.91,-0.49 -1.33,-2.1 -3.05,0.71 -0.34,-1.1 -1.22,0.59 -0.85,-0.55 0.06,-1.24 -0.85,1.05
            -2.68,-0.58 -0.31,-1.5 -1.65,-0.92 -2.77,0.55 -0.67,-0.83 1.27,-0.44 -1.97,-0.53 -0.68,-1.09 -0.94,0.27 0.1,-2.23 -2.64,-1.66 0.72,-1.35 -0.59,-2.17 0,0 1.3,0.09
            0.85,-1.36 2.09,0.51 0.36,-1.28 1.4,-0.42 0.18,-0.88 1.74,0.02 0.81,-1.53 -2.77,-1.74 -0.61,1.35 -0.87,-0.38 0.16,-2.06 1.2,-1.39 -1.62,-3.93 1.6,-0.31 0.25,-1.38
            -1.01,-1.62 0.54,-2.78 -1.84,-1.11 0.96,-1.4 -1.77,-1.38 0.97,-0.84 -2.95,-4.09 1.19,-2.51 -2.86,-1.49 -2.65,-3.03 0.95,-2.2 -2.14,-0.81 0.22,-0.82 -1,0.12 0.61,-0.98
            -1.46,-0.19 z" />
        <path
            title="Somme"
            id="FR-80"
            onClick={() => props.setDepartment('80 - Somme')}
            stroke="blue" fill={props.department.substr(5) === 'Somme' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 282.82408,48.061588 0.59,0.47 0,0 0.97,1.44 4.01,-2.07 1.78,0.07 4.3,3.19 1.61,-1.27 0.75,0.62 -1.39,1.43 0.6,0.75 5.41,2.31 -0.51,1.12 0.88,2.21 1.84,-0.58
            0.83,1.25 2.63,-1.82 1.38,0.52 1,-1.19 1.21,1.13 1.16,-0.11 -0.5,-1.39 0.72,-0.29 1.09,2.1 1.98,-1.82 1.87,0.27 1.81,2.32 -3.11,1.18 -1.33,1.47 -0.49,2.84 1.77,1.43
            1.67,-2.83 2.3,-0.63 0.77,1.75 0.99,-1.87 1.24,0.35 -0.47,1.57 2.18,0.25 3.14,1.83 0.64,-0.41 -0.96,-0.97 0.75,-0.47 -0.22,-1.22 2.86,0.85 -0.99,4.94 3.93,-2.55 1.15,2.08
            -1.08,1 0.58,0.65 3.42,-2.11 1.03,0.75 1.8,-1.13 2.56,0.88 0.57,-0.54 0,0 1.18,1.79 1.32,-0.06 0.93,1 0,0 -0.75,1.3 2.03,0.47 -3.35,3.8 -0.12,2.61 -1.34,1.18 -0.48,-0.86
            0.37,2.4 -1.25,0.64 1.3,1.75 -0.9,2.2 1.45,0.26 0.83,3.7 0,0 0.02,0.18 0,0 0,0 0,0 -1.54,0.98 0.02,-1.4 -1.3,-0.08 0.14,1.2 -1.17,0.97 -0.93,-1.48 -1.14,-0.19 -1.24,0.72
            0.27,1.86 -1.67,-2.85 -0.88,0.76 -0.4,-1.01 -0.72,0.74 1.09,1.91 -1.29,-0.81 -0.62,1.61 -2.04,-0.04 -0.44,3.11 -4.03,-0.86 -1.61,3.44 -0.86,-0.48 -0.17,-1.99 -1.35,-0.14
            -0.91,1.02 -0.18,-1.42 -1.05,0.21 -1.47,-1.5 -1.16,1.15 -1.35,-2.06 -3.09,-0.18 -2.28,-2.18 -0.54,0.6 -2.34,-1.32 -2.66,-0.2 -4.85,1.38 -1.33,-1.82 -2.59,-0.54
            -0.91,1.18 -0.68,-1.24 -1.84,1.43 -1.67,-0.22 -1.13,-1.24 0.78,-1.21 -2.27,-1.14 0,0 -4.45,-10.26 -3.06,-1.64 -3.74,-4.53 -2.56,-1.69 -0.15,-1.97 -1.68,0.84 -1.27,-0.47
            0,0 3.08,-2.85 1.3,-4.21 1.63,-2.15 1.91,-0.63 -0.48,0.5 1.28,1.42 0.98,-0.42 2.62,1.19 0.56,-0.85 -0.46,-1.35 -2.27,-0.34 -1.32,-2.7 -1.62,-0.14 -0.53,-1.04 0.68,-5.65
            2.99,0.16 z" />
        <path
            title="Tarn"
            id="FR-81"
            onClick={() => props.setDepartment('81 - Tarn')}
            stroke="blue" fill={props.department.substr(5) === 'Tarn' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 297.86408,427.32159 2.92,-1.34 -0.24,-0.96 1.73,0.04 0,0.81 0.35,-1.08 1.54,-0.45 2.1,2.23 1.83,0.03 -2.15,1.39 2.21,0.22 1.39,-0.81 1.45,1.65 1.08,-0.26
            2.36,1.69 0.87,2.21 2.14,0.33 1.67,3.67 1.24,0.52 -1.29,1.84 2.15,1.15 -0.07,1.88 1.21,0.91 -0.79,1.84 4.04,5.03 3.51,1.6 3.37,-1.87 1.93,1.28 1.78,-0.07 0.92,2.64
            0,0 -1.13,2.21 -3.94,0.86 -2.43,1.51 -1.45,-1.75 -3.09,-0.87 -1.25,5.35 2.44,2.89 -0.64,-0.09 -0.15,1.14 0.53,1.57 -3.98,2.84 0,0 -4.05,-0.99 -3.25,1.21 -3.75,-1.75
            -0.98,0.36 -1.01,-1.09 -1.52,1.61 -0.29,2.72 -1.83,-1.86 -4.06,1.19 -0.83,-1.99 -0.94,-0.27 0,0 -0.67,-0.82 1.42,-2.05 -0.32,-1.49 -0.13,0.71 -0.93,-0.52 0.08,1.2
            -1.09,0.56 -3.1,-1.34 -2.68,-2.73 -0.47,-1.64 -6.22,-2.77 0.43,-1.31 1.27,-0.49 -0.38,-1.76 -2.39,-0.29 -0.11,-0.65 1.87,-0.66 -2.33,-1.92 -0.25,-2.88 -2.3,-0.95
            0.18,-1.54 -1.57,-1.41 -0.02,-2.92 0,0 0.77,0.1 0.17,-1.18 -1.76,-1.28 3.58,-0.02 0.34,-1.68 1.21,-0.41 -0.35,-0.87 1.68,-0.52 0.56,-1.38 -1.61,-1.23 -0.19,-2.89
            3.54,0.05 1.34,1.15 1.11,-1.73 1.27,1.15 0.2,-2.23 2.89,0.79 -0.07,-1.82 1.45,0.92 z" />
        <path
            title="Tarn-et-Garonne"
            id="FR-82"
            onClick={() => props.setDepartment('82 - Tarn-et-Garonne')}
            stroke="blue" fill={props.department.substr(5) === 'Tarn-et-Garonne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 259.49408,414.10159 2.97,-0.83 -0.12,1.28 -1.82,-0.01 -0.33,1.07 1.74,2.14 2.39,0.37 -0.05,1.07 1.41,1.3 0.32,-0.76 1.14,0.83 0.28,-1.06 1.96,-0.56 -0.83,3.42
            2.23,0.37 0.97,1.49 3.9,-2.89 0.72,-2.51 0.5,1.42 1.75,0.72 0.61,1.82 1.89,-1.09 -0.91,-1.54 0.33,-1.62 1.85,0.28 -0.01,1.01 1.06,0.56 0.43,-0.93 -0.71,-0.76 1,0.99
            1.66,-1.78 1.79,-0.36 1.33,0.84 0.84,-1.74 1.34,0.61 2.3,-0.86 0,0 -0.97,1.07 0.96,0.96 -0.48,0.68 4.16,1.12 -0.65,1.72 -0.88,-0.11 -0.7,1.91 -1.07,0.27 0.85,1.28
            0.86,-0.2 0.26,1.17 0.29,-0.8 1.26,0.14 0.98,1.21 -0.43,0.51 0,0 -1.93,0.17 -1.45,-0.92 0.07,1.82 -2.89,-0.79 -0.2,2.23 -1.27,-1.15 -1.11,1.73 -1.34,-1.15 -3.54,-0.05
            0.19,2.89 1.61,1.23 -0.56,1.38 -1.68,0.52 0.35,0.87 -1.21,0.41 -0.34,1.68 -3.58,0.02 1.76,1.28 -0.17,1.18 -0.77,-0.1 0,0 -0.93,-0.2 -1.69,2.05 -0.61,-1.23 -1.15,2.18
            -3.5,-1.09 -0.71,1.15 0.45,1.03 -2.65,0.34 2.5,0.55 0.14,1.08 -3.79,1.07 0.27,0.77 -2.49,1 0.1,-1.46 -1.49,-0.13 -1.25,-1.55 -1.66,1.51 -1.1,-1.12 -1.1,0.99 -4.3,0.61
            0,0 -2.44,-0.22 1.24,-2.21 -1.26,-0.56 0.69,-0.76 -1.22,-1.31 0.92,-1.29 -1.7,-1.43 -3.81,0.07 -0.49,-0.81 2.79,-3.48 -0.51,-1.7 1.23,0.07 0.46,-1.35 -1.35,-0.46
            -2.35,1.47 -0.96,-2.05 0,0 0.65,-2.39 1.78,-0.33 -0.42,-1.76 3.02,0.98 1.33,-0.65 -0.36,-2.14 -1.07,0.23 -0.26,-0.75 2.21,-0.38 0.9,-4.38 0.93,-0.44 -1.44,-1.61
            -0.93,0.38 -1,-0.82 1.12,-4.11 1,-0.16 0.87,2.24 0.44,-0.95 4.07,-0.06 z" />
        <path
            title="Var"
            id="FR-83"
            onClick={() => props.setDepartment('83 - Var')}
            stroke="blue" fill={props.department.substr(5) === 'Var' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 454.39408,451.25159 0.86,-1.2 2.03,0.33 0.84,1.69 2.12,-0.78 0.08,-1.27 0.96,-0.21 0.48,1.54 2.06,0.93 1.45,2.6 3.51,-4.31 2.18,-0.07 2.33,-3.06 1.45,-0.11
            3.16,3.12 2.37,0.73 1.29,-1.52 0.03,-1.78 2.9,0.06 1.36,-1.03 1,1.94 1.93,-1.73 1.48,0.04 0.46,0.89 0,0 0.98,2.33 1.14,-0.51 1.13,1.03 1.68,-0.1 0.89,2.12
            -0.51,2.17 1.52,2.17 4.67,1.67 -1.4,3.96 0.71,0.14 -0.46,1.36 1.86,1.5 0,0 -0.39,1.58 -1.17,1.13 -1.38,-0.23 -0.14,1.19 -4.64,-0.23 -1.09,3.88 -5.45,3.87 0.34,0.99
            2.13,-0.67 0.82,0.53 0.57,-0.82 0.81,0.72 -1.33,1.35 0.66,2.46 -1.76,1.54 -0.87,0.57 -0.76,-1.19 -1.59,-0.33 -2.68,2.06 -4.65,0.48 -1,1.7 0.34,1.44 -1.54,-0.03
            -2.38,-1.78 -2.89,0.27 -1.84,1.58 -0.2,3.49 -2.49,-0.12 -0.07,-0.64 1.49,-0.23 -0.21,-1.98 -4.28,0.06 -0.82,-1.57 -3.13,0.12 -0.14,-1.18 -1.52,0.44 -0.14,0.82 1.2,0.07
            -0.63,1.12 1.79,-0.2 0.41,0.97 -2.35,-0.62 -1.32,1.83 -1.29,-0.18 -1.29,-1.12 0.45,-2.58 -1.34,-0.12 -0.16,-1.22 -3.2,-0.27 -0.01,-1.42 -1.02,-0.62 0,0 0.48,-3.15
            3.23,-1.82 0.1,-0.83 -1.49,-2 -2.32,-0.07 1.38,-2.12 -0.93,-2.46 2.81,-0.14 1.6,-1.09 -3.72,-3.54 1.06,-3.89 -2.86,-1.31 1.49,-0.58 -0.56,-1.55 0.87,-1.86 4.04,-0.93
            0.71,-1.7 -2.45,-1.95 0,0 0.17,-0.56 z" />
        <path
            title="Vaucluse"
            id="FR-84"
            onClick={() => props.setDepartment('84 - Vaucluse')}
            stroke="blue" fill={props.department.substr(5) === 'Vaucluse' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 408.32408,416.87159 5.73,0.97 1.26,4.96 2.53,-1.93 1.76,0.06 5.72,-2.76 0.69,1.37 1.33,0.37 2.46,-2.16 -0.84,2.1 0.33,2.79 3.09,1.09 0.81,-1.16 3.57,1.65
            0.83,-0.66 1.23,0.81 -0.08,2.6 2.18,0.2 0.68,1.8 1.94,0.27 0,0 0.25,3.02 1.65,-0.21 -1.49,6.68 2.46,0.56 0.56,1.52 1.15,-0.02 -2.54,3.67 -0.08,2 1.26,-0.79 3.27,0.27
            4.38,5.31 0,0 -0.22,0.52 0,0 -1.51,1.73 -1.95,0.04 -2.8,1.93 -3.15,-0.03 -4.32,-1.44 -4.76,-3.06 -3.38,-0.6 -2.13,0.74 -5.26,-2.9 -0.86,-2.32 -3.09,-2.89 -4.48,-2.2
            -4.44,-0.44 0,0 3.05,-2.32 -0.07,-1.37 1.19,0.09 0.12,-1.26 -2.39,-3.37 -2.74,-0.91 -0.64,-1.52 0.68,-4.58 -0.59,-1.55 -1.42,-0.07 -0.98,-3.18 0,0 0.05,-3.42 z m
            14.12,-5.28 0.49,0.5 0,0 0.67,-0.2 -0.12,1.33 2.32,0.57 0,0 -0.01,0.35 0,0 -1.98,0.93 -1.51,3.92 -4.05,-0.68 0.22,-1.98 -0.87,-0.64 1.51,-2.73 0,0 0.48,-0.7 0,0
            0.09,0.06 0,0 0.8,-0.34 0,0 0.68,-0.39 0,0 0.77,-0.2 0,0 0.43,0.05 0,0 0.08,0.15 z" />
        <path
            title="Vendée"
            id="FR-85"
            onClick={() => props.setDepartment('85 - Vendée')}
            stroke="blue" fill={props.department.substr(5) === 'Vendée' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 121.82408,257.06159 1.39,1.12 -0.53,2.12 3.05,1.5 0.3,3.31 -2.43,-3.85 -2.21,-0.09 -1.61,-1.93 0.4,-1.23 -0.69,-0.8 2.33,-0.15 z m 11.29,-0.08 1.56,2.18 1.06,0.18
            0.65,1.77 2.99,0.48 0.24,1.39 3.18,0.12 0.98,2.69 1.48,-0.38 1,0.97 4.75,0.84 0.14,-0.83 1.8,-0.39 -1.88,-2.72 0.69,0.17 0.42,-1.08 -1.56,-2.36 0.59,-1.34 -0.55,-0.76
            2.2,-1.52 1.3,0.47 0.37,6.31 4.07,-1.85 -0.56,-4.51 1.35,-0.81 1.88,0.67 1.24,-3.08 2.86,2.72 2.25,0.71 0,0 3.84,1.48 3.26,-0.86 0.62,1.4 1.29,-0.76 1.59,1.94 0,0
            -0.27,0.64 1.46,0.19 -0.61,0.98 1,-0.13 -0.22,0.82 2.15,0.81 -0.95,2.2 2.65,3.03 2.86,1.49 -1.19,2.51 2.94,4.09 -0.97,0.84 1.77,1.38 -0.95,1.4 1.83,1.11 -0.54,2.78
            1.01,1.62 -0.25,1.38 -1.6,0.31 1.63,3.94 -1.21,1.38 -0.15,2.06 0.87,0.38 0.61,-1.34 2.76,1.74 -0.81,1.52 -1.74,-0.02 -0.16,0.9 -1.4,0.42 -0.37,1.28 -2.09,-0.51
            -0.85,1.35 -1.29,-0.09 0,0 -2.24,-1.26 0.08,-1.02 -1.62,0.14 -0.39,1.36 -1.55,-0.55 -1.91,0.96 -1.17,-0.68 1.31,-2.94 -1.86,1.17 -3.28,0.1 -0.88,1.95 -1.7,0.04 0,0
            -3.56,0.1 -0.17,2.7 -4.55,-3.84 0.84,2.57 -1.99,-3.06 -5.16,-0.01 -1.94,-3.69 -5.58,-0.97 -5.37,-4.36 -1.46,-0.14 -1.85,-6.92 -3.78,-5.28 -0.75,0.24 -2.06,-3.24
            -5.25,-4.51 -0.41,-4.08 1.27,-0.06 0.08,-1.12 3.15,-3.57 0.64,-2.51 2.09,-1.22 z" />
        <path
            title="Vienne"
            id="FR-86"
            onClick={() => props.setDepartment('86 - Vienne')}
            stroke="blue" fill={props.department.substr(5) === 'Vienne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 210.99408,254.85159 0.66,-2.22 2.08,0.87 -0.17,-1.67 1.38,-2.82 1.1,-0.91 1.48,0.67 0,0 1.05,1.1 0.04,1.63 2.49,-0.36 -0.22,1.08 1.13,0.38 0.82,-0.86 0.76,1.44
            -0.9,1.98 1.44,0.31 1.33,-1.07 0.91,0.25 0.05,1.51 1.44,-0.52 0.4,3.21 -0.89,0.48 1.34,3.64 1.73,-1.14 3.04,1.21 2.78,-1.78 3.85,0.1 -1.27,-2.97 2.07,-0.09 0.68,1.31
            2.39,0.74 0.53,4.28 1.99,2.64 1.82,0.6 0.78,3.66 2.18,2.48 0,0 1.87,1.28 0.59,1.77 -1.44,4.11 0.75,1.7 2.2,1.8 2.11,0.26 0.22,1.85 3.04,-0.03 2.22,1.86 -0.49,0.48
            0.95,1.67 -0.94,-0.09 0.66,1.37 1.54,0.46 -0.2,0.76 1.2,-0.33 -1.4,2.93 0,0 -0.86,-0.29 -1.14,2.52 -1.16,-0.99 -2.22,0.09 -1.83,4.98 -4.23,-0.54 -2.26,2.37 0.05,1.12
            -1.64,-0.04 -0.7,1.1 1.78,1.99 0.46,2.49 -0.94,0.58 0,0 -0.57,-0.67 -1.04,0.57 -3.02,-0.58 -1.57,1.68 0.5,0.93 -2.85,0.2 -0.95,1.22 -2.34,-0.88 -1.97,-2.97 -2.01,2.09
            0.23,0.9 1.25,0.03 0.07,1.22 -2.25,1.03 -1.52,-1.2 -4.7,0.49 -0.83,-1.22 -2.65,-0.88 0,0 1.08,-3.78 -1.92,0.41 -1.35,-1.79 -1.31,-0.29 0.22,-1.6 1.27,-0.99 -0.6,-2.26
            1.76,-0.7 -0.87,-1.46 1.07,-1.58 -2.18,-1.13 -1.96,2.6 -2.57,-1.26 0.91,-1.1 -0.99,-0.49 0.83,-1.19 -2.36,-1.62 0.56,-4.07 -1.34,-0.1 -0.16,-0.83 0.72,-0.14 -0.08,-2.4
            0.98,0.15 0.59,-1.15 -0.83,-0.57 0.2,-1.06 2.05,-1.39 -0.79,-0.19 -0.01,-1.19 -1.49,0.02 0.54,-1.7 -2.84,0.88 2.51,-3.31 -0.56,-0.62 0.98,-0.37 -0.19,-0.98 1.7,-1
            -2.5,-2.15 -0.16,-1.17 1.41,-1.64 -2.32,-0.37 1.43,-1.63 1.19,0.8 0.77,-1.19 -2.87,-1.25 1.08,-1.99 -1.51,-3.22 0.51,-1.32 -0.93,-0.74 -1.27,0.52 -0.28,-1.64 0.67,0.25 z" />
        <path
            title="Haute-Vienne"
            id="FR-87"
            onClick={() => props.setDepartment('87 - Haute-Vienne')}
            stroke="blue" fill={props.department.substr(5) === 'Haute-Vienne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 264.16408,295.86159 0.51,0.5 0.78,-0.81 0.39,1.46 1.67,-0.75 1.86,0.41 0.4,-1.41 1.69,-0.29 2.55,3.22 0,0 1.2,0.65 -0.06,1.91 -1.21,1.33 0.99,0.57 -1.35,0.46
            0.56,1.31 -1.86,1.61 1.61,2.29 1.96,-0.11 -0.24,1.6 1.24,1 0.52,-0.41 0.45,1.17 -0.94,1.04 2.3,1.53 0.42,2.69 -0.74,1.48 1.83,1.84 -2.75,2.75 2.28,1.05 0.92,-1.01
            2.18,0.35 -0.66,0.55 0.83,1.49 -0.93,-0.45 -0.05,1.12 -0.2,-0.61 -0.97,0.55 0.51,1.43 2.35,1.33 2.84,-0.42 1.75,-1.48 0.06,2.04 2.24,1.36 0.36,-0.93 1.94,1.74 0.77,1.63
            -1.09,0.76 -0.09,1.72 1.06,1.67 0,0 0.5,1.09 -1.17,-0.1 -0.32,0.99 -1.72,0.13 -1.92,-1.09 -0.35,1.35 -2.81,1.06 -2.99,3.52 -1.76,0.19 -0.53,1.28 -3.74,0.39 -0.62,-0.93
            -1.05,0.66 -0.43,1.86 -1.44,-0.24 -2.39,3.5 -2.66,-1.36 -1.35,2.74 0,0 -0.78,0.21 0.07,-0.83 -1.12,-0.51 -1.32,0.37 -0.62,-1.25 -1.73,0.14 1.89,-2.95 -2.1,-1.3
            -1.26,0.99 -2.8,-4.71 -3.01,-0.29 -2.01,0.86 -1.18,-1.28 -0.99,2.34 -1.36,0.49 -0.28,-1.21 -1.25,0.27 -0.99,-1.59 0.97,-2.94 -2.24,-1.67 -2.81,0.43 -0.96,-1.29
            0,0 0.97,-1.79 2.54,-1.3 -0.13,-2.49 2.94,0.75 1.82,-5.42 -0.6,-2.93 1.99,0.89 3.37,-2.38 -0.61,-3.11 -1.76,-1.41 -1.08,0.83 -1.82,-1.91 0.85,-2.73 -1.04,-1.43
            0.62,-0.44 0,0 0.94,-0.58 -0.46,-2.49 -1.78,-1.99 0.7,-1.1 1.64,0.04 -0.05,-1.12 2.26,-2.37 4.23,0.54 1.83,-4.98 2.22,-0.09 1.16,0.99 1.14,-2.52 z" />
        <path
            title="Vosges"
            id="FR-88"
            onClick={() => props.setDepartment('88 - Vosges')}
            stroke="blue" fill={props.department.substr(5) === 'Vosges' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 510.75408,165.54159 0.66,1 -1.84,-0.36 1.18,1.24 -1.38,5.33 0.97,1.29 -1.34,1.57 1.7,1.19 2.62,-0.4 0.8,1.83 0,0 -1.11,0.78 -4.69,9.48 1.04,0.94 -0.32,1.28
            -3.18,5.46 -2.35,1.35 -1.33,4.97 0.72,0.59 -1.36,1.17 0.96,2.53 -3.46,1.71 0,0 -0.52,0.44 0,0 -1.54,-2.2 -5.8,-3.3 -1.78,-2.6 -1.64,0.82 -0.84,1.82 -2.71,1.09
            -1.78,-3.47 -1.91,-1.17 -4.23,0.31 -3.45,1.46 0.14,-0.95 -2.44,-1.18 0.64,-0.58 -0.35,-1.92 -1.01,-1.05 -3.8,1.26 -1.54,2.91 -1.32,-0.05 -0.89,1.32 -0.9,-0.14
            1.34,-1.86 -1.13,-0.57 -2.01,3.18 0,0 -1.57,-3.07 -1.55,1.7 -0.92,-0.27 0.32,-2.72 -0.77,-2.28 -1.43,-1 -0.84,0.36 -1.06,-2.03 -1.67,-0.57 0.03,1 -1.21,-0.9
            1.77,-1.42 -0.57,-0.78 1.13,-1.67 -0.15,-1.96 2.11,-0.95 -1.83,-2.46 -1.91,-0.48 0,-2.12 -1.24,-1 -0.94,1.11 -2.67,-4.6 -1.88,-0.52 -2.18,1.55 -0.39,-1.6 1.02,-1.39
            -2.01,-0.74 3.14,-1.84 0,0 1.54,0.73 0.5,-1.41 3.09,-0.68 1.19,1.05 -0.15,-1.03 1.52,-1.08 -0.51,-0.78 3.17,0.79 1.94,-2.33 0,0 0.92,0.88 2.93,-1.71 1.98,0.96
            -0.83,1.58 0.5,1.49 -1.71,2.22 1.59,0.33 0.15,-1.97 1.39,0.15 -1.12,2.08 1.89,0.26 0.49,2.88 1.7,-0.86 2.85,0.24 0.67,-1.49 0.18,1.33 1.02,0.55 1.47,-1.75 -0.05,-1.7
            3.43,0.66 2.58,-2.26 1.41,1.87 2.18,-0.63 0.29,1.46 6.01,-2.51 1.89,0.97 -0.5,-1.55 2.24,-1.99 0.98,2.79 4.96,1.09 0.08,0.73 0.89,-0.17 -0.23,0.84 1.5,-0.99 0,0
            0.42,-0.81 0,0 1.45,-1.24 1.17,1.2 3.6,-3.81 z" />
        <path
            title="Yonne"
            id="FR-89"
            onClick={() => props.setDepartment('89 - Yonne')}
            stroke="blue" fill={props.department.substr(5) === 'Yonne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 337.18408,187.36159 0.01,-1.26 2.78,-1.46 1.78,-2.66 -1.33,-3.44 1.33,-3.38 2.33,0.65 1.54,-1.48 4.72,0.5 1.16,-1.06 2.22,0.74 1.2,-0.16 0.46,-1.72 1.6,0.61
            0,0 0.58,1.97 1.59,-1.1 1.55,0.61 1.66,2.92 1.81,1.19 -0.41,1.03 1.73,0.95 -0.76,2.25 0.86,0.43 -1.76,2.55 2.14,-0.33 1.53,3.1 2.31,-1 -0.35,-1.12 1.41,0.52
            -0.36,2.07 2.14,0.91 1.29,4.57 2.06,1.8 -0.05,0.78 -1.31,-0.09 0.52,1.29 1.18,0.26 0.6,-1.41 0.78,1.35 -0.77,3.12 3.79,-0.07 0.76,-0.75 1.67,1.07 0.64,-1.27
            1.23,0.05 0.85,1.17 1.57,-1.35 0,0 0.8,-0.73 0,0 1.46,1.12 0.43,-1.26 -0.74,-0.34 1.31,-0.29 -0.18,1.41 0.94,1.18 2,0.32 0,0 0.78,1.23 -2.72,1.92 0.73,0.02
            0.28,2.13 2.48,-0.5 0.13,5.56 -1.72,1.44 -1.35,-0.53 -0.87,1.1 1.89,2.58 -2.35,0.21 0.92,1.15 -2.65,4.81 0,0 -0.48,1.82 0,0 -2.47,2.26 0.33,4.09 -1,1.1 -0.81,-0.59
            0.26,1.18 -0.83,0.81 1.05,0.87 -0.41,1.07 1.09,-0.06 -0.01,2.27 0,0 -2.62,-0.03 -0.66,1.58 -1.04,0.24 -1.25,-1.33 0.01,-2.69 -0.98,-1.12 -0.19,0.82 -2.98,0.93
            -0.53,-1.63 1.43,-1.34 -1.15,-1.25 -1.88,2.01 -0.16,1.41 -1.26,-1.6 -2.53,0.41 -2.15,-3.17 -2.43,-0.98 -1.2,0.62 -0.28,-2.52 -2.76,-1.84 -0.62,-2.06 -0.45,4.11
            -3.98,-0.73 -1.89,2.25 -2.59,-2.14 -0.44,0.81 -1.7,0.11 -1.24,-2.1 -1.61,0.28 -1.77,-1.3 0.1,-2.23 -0.92,-0.7 -3.35,1.9 -1.86,-0.77 0,0 -1.66,-1.81 -0.17,-1.7
            0.87,-1.14 -1.43,-1.44 0.3,-0.82 -1.96,-1.34 -0.02,-1.03 -1.27,0.21 0.16,-2.49 7.21,-1.82 -0.51,-2.88 0.81,-1 -1.53,-1.08 0.71,-0.39 -0.15,-2.01 1.7,-0.3 1.39,-1.95
            0,0 0.49,-0.34 0,0 1.35,-1.53 -0.12,-2.1 -1.07,-0.79 1.05,-0.64 -3.11,-2.69 -0.58,-2.71 -0.82,0.06 0.56,-1.07 z" />
        <path
            title="Territoire de Belfort"
            id="FR-90"
            onClick={() => props.setDepartment('90 - Territoire de Belfort')}
            stroke="blue" fill={props.department.substr(5) === 'Territoire de Belfort' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 499.04408,208.49159 1.25,2.43 3.07,0.71 4.01,2.96 -0.41,1.38 0.8,1.76 -1.44,2.01 -0.24,2.26 0.63,0.83 0.86,-0.73 1.86,0.47 2.37,4.18 -0.44,1.33 0,0 -2.35,0.91
            -2.17,-0.99 -1.69,0.63 0.78,2.48 -2.56,1.27 0,0 -1.39,-2.47 0.43,-1.57 1.3,0.81 -0.18,-1.71 -1.46,-0.74 -0.32,-1.49 -3.5,0.14 -0.43,-0.92 0,0 0.6,-0.69 -1.75,-2.48
            0.91,-1.42 -1.77,-6.77 2.71,-4.14 0,0 z" />
        <path
            title="Essonne"
            id="FR-91"
            onClick={() => props.setDepartment('91 - Essonne')}
            stroke="blue" fill={props.department.substr(5) === 'Essonne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 306.30408,149.78159 1.57,-0.42 1.66,0.76 0.52,1.6 0.91,-0.44 0,0 1.06,-0.39 1.6,0.66 0.14,1.14 1.72,0.11 4.17,-1.35 1.28,2.97 1.09,-0.01 0,0 0.53,0.9 -2.26,1.02
            0.51,1.71 -1.2,0.3 1.26,1.67 -1.88,3.53 0.69,2.52 -0.49,4.73 1.8,1.75 -3.01,0.72 0.43,0.41 -2.27,1.32 0.6,0.3 -0.44,1.41 -1.71,-0.02 0.48,1.21 0,0 -1.88,0.49
            -1.24,-1.55 -0.76,0.25 0,0 -0.46,1.06 0,0 -1.37,-0.22 -0.9,1.04 0.02,-2.08 -1.77,-0.76 -0.9,2.04 -1.23,-0.29 0.49,1.09 -2.44,-0.52 -2.65,1.4 -1.95,-0.13 0,0
            -1.5,-1.46 0.91,-0.47 -0.09,-5.2 -2.06,-0.28 0.6,-2.2 -0.84,-1.04 0,0 0.99,-3.7 1.35,-0.54 -1.78,-2.15 3.4,0.19 0.63,-3.04 1.22,-0.63 -2.12,-2.73 0.73,0.2 0.66,-1.92
            1.48,0.18 0.67,-0.8 0.25,-2.57 z" />
        <path
            title="Hauts-de-Seine"
            id="FR-92"
            onClick={() => props.setDepartment('92 - Hauts-de-Seine')}
            stroke="blue" fill={props.department.substr(5) === 'Hauts-de-Seine' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 309.98408,138.21159 2.23,0.6 -0.55,2.55 0,0 -2.13,2.15 -1.08,-0.63 -0.83,1.32 4.41,2.24 0,0 -1.07,4.84 0,0 -0.91,0.44 -0.52,-1.6 -1.66,-0.76 -1.57,0.42 0,0
            -1.33,-1 -0.74,-3.84 0.7,-0.31 -0.53,-1.41 0.86,-1.52 1.36,-0.88 0,0 z" />
        <path
            title="Seine-Saint-Denis"
            id="FR-93"
            onClick={() => props.setDepartment('93 - Seine-Saint-Denis')}
            stroke="blue" fill={props.department.substr(5) === 'Seine-Saint-Denis' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 321.14408,134.29159 0.74,-0.06 0.56,2.01 -0.81,0.09 1.68,2.83 -0.7,2.43 -1.13,0.57 1.03,1.05 -0.44,1.26 0.91,2.76 0,0 -2.58,-2.99 -5.04,-0.22 0,0 -0.78,-2.62
            -2.92,-0.04 0,0 0.55,-2.55 -2.23,-0.6 0,0 0.66,-1.02 1.55,0.55 1.29,-1.01 3.04,1.21 2.11,-0.92 z" />
        <path
            title="Val-de-Marne"
            id="FR-94"
            onClick={() => props.setDepartment('94 - Val-de-Marne')}
            stroke="blue" fill={props.department.substr(5) === 'Val-de-Marne' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 315.36408,144.02159 5.04,0.22 2.58,2.99 0,0 -0.34,2.04 1.12,0.69 -1.19,1.34 -0.55,3.11 0,0 -1.09,0.01 -1.28,-2.97 -4.17,1.35 -1.72,-0.11 -0.14,-1.14 -1.6,-0.66
            -1.06,0.39 0,0 1.07,-4.84 0,0 2.8,-0.87 2.46,0.74 0.34,-1.18 -2.23,0.38 z" />
        <path
            title="Val-d'Oise"
            id="FR-95"
            onClick={() => props.setDepartment('95 - Val-d\'Oise')}
            stroke="blue" fill={props.department.substr(5) === 'Val-d\'Oise' ? props.activeClr : props.passiveClr}
            className="regionZone"
            d="m 285.98408,120.40159 0.41,-0.58 1.09,1.06 -1.05,1.12 1.12,1.61 2.57,-0.27 0.36,0.75 1.02,-0.21 -0.06,0.8 4.8,-0.39 1.04,-0.84 1.08,0.49 3.28,-2.18 0.64,1.28
            1.35,-0.23 0.31,0.93 0.48,-0.6 0.94,1.58 1.99,-1 0.36,1.75 2.42,-0.51 1.17,-1.69 1.82,2.43 0.72,-0.74 2.7,0.83 -0.24,0.82 1.07,-0.47 1.82,1.46 -0.56,0.72 1.74,0.41
            0.91,-1.54 0.16,1.62 1.34,1.17 0,0 -0.88,1.2 1.03,0.86 -2.01,1.51 0.22,0.74 0,0 -2.51,2.73 -2.11,0.92 -3.04,-1.21 -1.29,1.01 -1.55,-0.55 -0.66,1.02 0,0 -3.36,2.61
            0,0 0.16,-2.71 -1.27,-0.14 -0.39,-1.93 -1.86,-0.49 0.02,-1.61 -4.04,1.23 -1.29,-1.49 -2.26,-0.01 -1.24,-1.65 -1.32,1.93 -1.03,-1 0.61,-1.51 -1.47,-1.22 -4.45,2
            -2.08,-2.11 -2.68,0.04 0,0 2.03,-3.42 0.69,-5.04 z" />
        </svg>
    </div>
  )
}
