import axios from 'axios';

const axios_instance = axios.create({
  baseUrl: '',
  timeout: 1000,
  headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer $argon2i$v=19$m=65536,t=4,p=1$Sm1IcHBRVmkuY2l6RXF3Qw$lTR8xasaVnQU79TOHCBnpoTUybckCF/rF6O77rcZPSI',
      // 'Authorization': 'Bearer ' + process.env.REACT_APP_ACCESS_TOKEN,
  },
});
export default axios_instance;