import React from 'react';
import './profileStyle.css';

export default function PageViewer() {
  return (
    <div>
      <div className="centerH2" style={{height: '60px'}}>
        <h2 className="h2Title">Réalisé avec la librairie Threejs <i>(Controls avec la souris)</i></h2>
      </div>
      <iframe
        id="threeViewer"
        title="Viewer 3D"
        style={{width: '80vw', margin: '2vh 10vw',  height: '60vh'}}
        src={'https://servtome.ddns.net/viewer/viewerindex.html'}>
        </iframe>
    </div>
  )
}
