import React from 'react';

export default function LoadinPage(props) {
  return (
    <>
      {!props.loadingPage && (
        <div className="disp noselect" id="loadingPage">
          <div>
            <h1 id="loadingTitleh1">DEVELOPPEUR WEB, LOGICIEL & APPLICATION MOBILE</h1>
            <h2 id="loadingTitleh2">Pascal COSTA</h2>
          </div>
          <div>
            <span className="lettre">C</span>
            <span className="lettre">H</span>
            <span className="lettre">A</span>
            <span className="lettre">R</span>
            <span className="lettre">G</span>
            <span className="lettre">E</span>
            <span className="lettre">M</span>
            <span className="lettre">E</span>
            <span className="lettre">N</span>
            <span className="lettre">T</span>
          </div>
        </div>
      )}
    </>
  )
}
