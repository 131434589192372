import React, {useState} from 'react';
import './profileStyle.css';
import sendNavigationInfo from '../functions/sendNavigationInfo';

export default function PageMobil(props) {
    const mobilImages = [
      [
        process.env.PUBLIC_URL + '/images/competence/mobile/mobilImg1.jpg',
        'Création d\'applications mobile ios & Android',
        'Création d\'applications mobile ios & Android',
      ],
      [
        process.env.PUBLIC_URL + '/images/competence/mobile/mobilImg2.png',
        'Pilotage d\'équipements techniques avec connexion Bluetooth',
        'Pilotage d\'équipements techniques avec connexion Bluetooth',
      ],
      [
        process.env.PUBLIC_URL + '/images/competence/mobile/mobilImg3.png',
        'Insertion des vues satellites avec Google maps',
        'Insertion des vues satellites avec Google maps',
      ],
    ];
    return (
      <div className="noselect">
        <div className="centerH2">
          <h2 className="h2Title">Applications mobile <i>(REACT-NATIVE)</i></h2>
        </div>
        <div className="selectorToolContainer">
          <div onClick={() => {
            props.getPageBefore(mobilImages.length);
            sendNavigationInfo('visit_skills_pagemobile_beforebutton');
            }}>
            {props.icons.arrow_to_left}
          </div>
          <div onClick={() => {
            props.getPageAfter(mobilImages.length);
            sendNavigationInfo('visit_skills_pagemobile_afterbutton');
            }}>
            {props.icons.arrow_to_right}
          </div>
        </div>
        <div className="imageContainer">
          <div className="pythonContent">
            <img
              src={mobilImages[props.staticSelector][0]}
              className="profilImage"
              alt={mobilImages[props.staticSelector][2]} />
          </div>
          <div className="pythonContent description">
            <p>{mobilImages[props.staticSelector][1]}</p>
          </div>
        </div>
      </div>
    )
  }