import React from 'react';
import './profileStyle.css';
import sendNavigationInfo from '../functions/sendNavigationInfo';

export default function WebStatique(props) {
    const staticImages = [
      [
          process.env.PUBLIC_URL + '/images/competence/webStatique/library_img1_800x408.png',
          'Librairie d\'articles avec viewer 3D',
          'Librairie d\'articles avec viewer 3D',
      ],
      [
          process.env.PUBLIC_URL + '/images/competence/webStatique/library_img2_800x408.png',
          'Filtres de recherche',
          'Filtres de recherche multi-critéres',
      ],
      [
          process.env.PUBLIC_URL + '/images/competence/webStatique/library_img3_800x403.png',
          'Gestion des commandes et expéditions',
          'Interface de gestion des commandes et expéditions',
      ],
    ];
    return (
      <div className="noselect">
        <div className="centerH2">
          <h2 className="h2Title">Applications web statique <i>(PHP - SYMFONY -TWIG - API-PLATFORM)</i></h2>
        </div>
        <div className="selectorToolContainer">
          <div onClick={() => {
            props.getPageBefore(staticImages.length);
            sendNavigationInfo('visit_skills_webstatique_beforebutton');
          }}>
            {props.icons.arrow_to_left}
          </div>
          <div onClick={() => {
            props.getPageAfter(staticImages.length);
            sendNavigationInfo('visit_skills_webstatique_afterbutton');
          }}>
            {props.icons.arrow_to_right}
          </div>
        </div>
        <div className="imageContainer">
          <div className="pythonContent">
            <img src={staticImages[props.staticSelector][0]}
              className="profilImage"
              alt={staticImages[props.staticSelector][2]} />
          </div>
          <div className="pythonContent description">
            <p>{staticImages[props.staticSelector][1]}</p>
          </div>
        </div>
      </div>
    )
  }