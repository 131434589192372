import React from 'react';
import './profileStyle.css';
import sendNavigationInfo from '../functions/sendNavigationInfo';

export default function PagePython(props) {
  const pythonImages = [
    [
      process.env.PUBLIC_URL + '/images/competence/logiciel/supervisionImg1_800x445.png',
      'Création de logiciels de gestion de stock',
      'Table de gestion de stock avec ses fonctionnalités en partie gauche.',
    ],
    [
      process.env.PUBLIC_URL + '/images/competence/logiciel/supervisionImg2_800x445.png',
      'Création de logiciels de suivi d\'équipements',
      'Gestion de production et maintenance, ERP, GMAO',
    ],
    [
      process.env.PUBLIC_URL + '/images/competence/logiciel/supervisionImg3_800x442.png',
      'Création de logiciels de contrôle industriel',
      'Monitoring d\'équipements techniques industriels',
    ],
    [
      process.env.PUBLIC_URL + '/images/competence/logiciel/simulateurPosition_800x369.png',
      'Création de simulateurs et d\'outils de pilotage',
      'Simulateur de positionnement sur un terrain avec les accéssoires de gestion GPS',
    ],
  ];
  return (
    <div className="noselect">
      <div className="centerH2">
        <h2 className="h2Title">Réalisation de logiciels de contrôle, management et gestion avec Python</h2>
      </div>
      <div className="selectorToolContainer">
        <div onClick={() => {
          props.getPageBefore(pythonImages.length);
          sendNavigationInfo('visit_skills_pagepython_beforebutton');
          }}>
          {props.icons.arrow_to_left}
        </div>
        <div onClick={() => {
          props.getPageAfter(pythonImages.length);
          sendNavigationInfo('visit_skills_pagepython_afterbutton');
          }}>
          {props.icons.arrow_to_right}
        </div>
      </div>
      <div className="imageContainer">
        <div className="pythonContent">
          <img
            src={pythonImages[props.staticSelector][0]}
            className="profilImage"
            alt={pythonImages[props.staticSelector][2]} />
        </div>
        <div className="pythonContent description">
          <p>{pythonImages[props.staticSelector][1]}</p>
        </div>
      </div>
    </div>
  )
}
