const changePage = (e, setActivePage) => {
  if (typeof e  === 'string' && e.substring(0, 4) === 'http') {
    if (e.indexOf('home') > -1) {
      setActivePage('home');
      document.querySelector('#menuSelector').selectedIndex = 0;
    }
    if (e.indexOf('livechat') > -1) {
      setActivePage('livechat');
      document.querySelector('#menuSelector').selectedIndex = 1;
    }
    if (e.indexOf('dynamicMap') > -1) {
      setActivePage('dynamicMap');
      document.querySelector('#menuSelector').selectedIndex = 2;
    }
    if (e.indexOf('AVR') > -1) {
      setActivePage('avr');
      document.querySelector('#menuSelector').selectedIndex = 3;
    }
    if (e.indexOf('ATcmd') > -1) {
      setActivePage('ATcmd');
      document.querySelector('#menuSelector').selectedIndex = 4;
    }
    if (e.indexOf('profile') > -1) {
      setActivePage('profile');
      document.querySelector('#menuSelector').selectedIndex = 5;
    }
    if (e.indexOf('contact') > -1) {
      setActivePage('contact');
      document.querySelector('#menuSelector').selectedIndex = 6;
    }
  }
}

export default changePage;