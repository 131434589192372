import React from 'react';
import sendNavigationInfo from '../functions/sendNavigationInfo';


export default function Menu1Button(props) {
  return (
    <span 
      className="menu_1ButShadow"
      style={{
            background: props.menuBut === props.pageName ? 'transparent' : 'rgb(110, 110, 110)',
            }}>
      <button
          className="menu_1But noselect"
          style={{
            background: props.menuBut === props.pageName ? 'linear-gradient(#31E15C, rgb(178, 245, 178), #31E15C)' : 'linear-gradient(rgb(167, 167, 167), lightgrey, rgb(167, 167, 167))',
            transform: props.menuBut === props.pageName ? 'scale(1.1)' : 'scale(1)',
            border: props.menuBut === props.pageName ? '1px solid white' : '1px solid grey',
            padding: props.menuBut === props.pageName ? '4px 12px' : '2px 6px',
            fontWeight: props.menuBut === props.pageName ? '700' : '500',
            }}
          onClick={() => {
            props.setMenuBut(props.pageName);
            sendNavigationInfo(props.visitLog);
            }}
          id={props.pageName}>
          {props.titleBut}
      </button>
    </span>
  )
}
