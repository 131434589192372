import React from 'react';
import './HomeStyle.css';

export default function CardComponent(props) {
  return (
    <div onClick={() => {
      props.sendNavigationInfo(props.localisation);
      props.changePage(props.redirection, props.setActivePage);
      localStorage.setItem('PC_activepage', props.pageLink);
      document.querySelector('#h1Title').style.animation = 'showH1Title 0.8s ease';
      setTimeout(() => document.querySelector('#h1Title').style.animation = '', 800);
      }}>
      <div className="cardContainer">
        <div>
            <h2 className="cardTitle">{props.title}</h2>
        </div>
        <div className="cardDescription">
            <span style={{paddingLeft: '15px'}}></span>{props.description}
        </div>
        <div className="cardImage">
            <img
              src={props.image}
              className={props.title === 'Le site de l\'artiste' ? "animClown" : ""}
              style={{transform: props.title === 'Projet AT-MANAGER'
                ? 'scale(1.1)'
                : props.title === 'Le site de l\'artiste'
                  ? 'rotate(3deg) scale(1)'
                  : 'scale(1)'}}
              alt={props.image_alt}/>
        </div>
      </div>
    </div>
  )
}
