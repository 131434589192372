import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import sendNavigationInfo from '../functions/sendNavigationInfo';

export default function TopPage(props) {
  const [screenWidth, setScreenWidth] = useState('larg');
  const animH1 = () => {
    document.querySelector('#h1Title').style.animation = 'showH1Title 0.8s ease';
    setTimeout(() => document.querySelector('#h1Title').style.animation = '', 1000);
  }
  const changeActivePage = pageName => {
    props.setActivePage(pageName);
    localStorage.setItem('PC_activepage', pageName);
    animH1();
  }
  const initNavigation = () => {
    sendNavigationInfo('visit_backtomenu');
    props.changePage(props.url + '?page=home', props.setActivePage);
    localStorage.setItem('PC_activepage', 'home');
    document.querySelector('#menuSelector').selectedIndex = 0;
    animH1();
  }
  useEffect(() => {
    document.querySelector('#h1Title').style.animation = 'showH1Title 0.8s ease';
    setScreenWidth(window.screen.width > 900 ? 'larg' : 'small');
    props.setActivePage(localStorage.getItem('PC_activepage'));
    return;
  }, [])
  return (
    <header className="title centrCenter">
            <div className="headerPhotoContainer">
              {(props.activePage === 'home' || props.activePage === 'profile' || props.activePage === 'contact') && (
                <Link to={'/'} className="headerPhotoLink">
                  <img
                    onClick={() => initNavigation()}
                    src={
                      screenWidth === 'larg'
                      ? process.env.PUBLIC_URL + '/images/competence/photoFace_88x88.png'
                      : process.env.PUBLIC_URL + '/images/competence/photoFace_60x60.png'
                    }
                    className="headerPhoto"
                    alt="Visage de Pascal Costa"/>
                </Link>
              )}
              {props.activePage === 'avr' && (
                <Link to={'/'} className="headerPhotoLink">
                  <img
                    onClick={() => initNavigation()}
                    src={
                      screenWidth === 'larg'
                      ? process.env.PUBLIC_URL + '/images/AVR-FLASHER/avrflasher_img_88x88.png'
                      : process.env.PUBLIC_URL + '/images/AVR-FLASHER/avrflasher_img_60x60.png'
                    }
                    className="headerPhoto"
                    alt="Logo de l'outil AVR-FLASHER"/>
                </Link>
                )}
              {props.activePage === 'ATcmd' && (
                <Link to={'/'} className="headerPhotoLink">
                <img
                    onClick={() => initNavigation()}
                  src={
                      screenWidth === 'larg'
                      ? process.env.PUBLIC_URL + '/images/AT-MANAGER/atmanager_img_88x88.png'
                      : process.env.PUBLIC_URL + '/images/AT-MANAGER/atmanager_img_60x60.png'
                    }
                  className="headerPhoto"
                  alt="Logo de l'outil AT-MANAGER"/>
                </Link>
              )}
              {props.activePage === 'livechat' && (
                <Link to={'/'} className="headerPhotoLink">
                  <img
                    onClick={() => initNavigation()}
                    src={
                      screenWidth === 'larg'
                      ? process.env.PUBLIC_URL + '/images/livechat/livechat_img_88x88.png'
                      : process.env.PUBLIC_URL + '/images/livechat/livechat_img_60x60.png'
                    }
                    className="headerPhoto"
                    alt="Logo du livechat"/>
                  </Link>
                )}
              {props.activePage === 'dynamicMap' && (
                <Link to={'/'} className="headerPhotoLink">
                  <img
                    onClick={() => initNavigation()}
                    src={
                      screenWidth === 'larg'
                      ? process.env.PUBLIC_URL + '/images/carteInteractive/dynamicMapLogo_88x88.png'
                      : process.env.PUBLIC_URL + '/images/carteInteractive/dynamicMapLogo_60x60.png'
                    }
                    className="headerPhoto"
                    alt="Carte interactive"/>
                  </Link>
                )}
                <span className="profilLink">
                    <span className="profilLinkTitle" id="profilLinkTitle">Pascal COSTA</span>
                    <Link to={'/profil'} style={{textDecoration: 'none', color: 'black'}}>
                      <span 
                        onClick={() => {
                            sendNavigationInfo('visit_skills')
                            props.setActivePage('profile');
                            document.querySelector('#menuSelector').selectedIndex = 4;
                            localStorage.setItem('PC_activepage', 'profile');
                            animH1();
                          }}
                        className="profilLinkText1"
                        style={{marginLeft: '4px', maxHeight: '10px'}}
                        id="profilLinkText1">Voir le profil</span>
                    </Link>
                    <Link to={'/contact'} style={{textDecoration: 'none', color: 'black'}}>
                      <span 
                        onClick={() => {
                            sendNavigationInfo('visit_contactpage')
                            props.setActivePage('contact');
                            document.querySelector('#menuSelector').selectedIndex = 5;
                            localStorage.setItem('PC_activepage', 'contact');
                            animH1();
                          }}
                        className="profilLinkText2" 
                        style={{marginLeft: '4px', overflow: 'hidden'}}
                        id="profilLinkText2">Page contact</span>
                    </Link>
                </span>
            </div>
            <h1 className="noselect" id="h1Title">
              {props.activePage === 'home' && 'DÉVELOPPEUR'}
              {props.activePage === 'contact' && 'Page contact'}
              {props.activePage === 'profile' && 'DÉVELOPPEUR'}
              {props.activePage === 'livechat' && 'Projet LIVE CHAT'}
              {props.activePage === 'dynamicMap' && 'Carte interactive'}
              {props.activePage === 'avr' && 'Projet AVR-FLASHER'}
              {props.activePage === 'ATcmd' && 'Projet AT-MANAGER'}
            </h1>
            <div>
              <div className="selectOther noselect" id="menuSelector">
                  <span className="link" style={{fontWeight: '800'}}>
                    {props.activePage === 'home' && 'Accueil'}
                    {props.activePage === 'contact' && 'Contact'}
                    {props.activePage === 'profile' && 'Compétences'}
                    {props.activePage === 'livechat' && 'LiveChat'}
                    {props.activePage === 'dynamicMap' && 'Carte interactive'}
                    {props.activePage === 'avr' && 'AVR-FLASHER'}
                    {props.activePage === 'ATcmd' && 'AT-MANAGER'}
                  </span>
                  <Link
                    to="/"
                    className="link"
                    onClick={() => {
                      changeActivePage('home');
                      sendNavigationInfo('visit_backtomenu');
                    }}>Accueil</Link>
                  <Link
                    to="/LiveChat"
                    className="link"
                    onClick={() => {
                      changeActivePage('livechat');
                      sendNavigationInfo('visit_livechat');
                    }}>LIVE CHAT</Link>
                  <Link
                    to="/dynamicMap"
                    className="link"
                    onClick={() => {
                      changeActivePage('dynamicMap');
                      sendNavigationInfo('visit_dynamicMap');
                    }}>CARTE DYNAMIQUE</Link>
                  <Link
                    to="/AVR-FLASHER"
                    className="link"
                    onClick={() => {
                      changeActivePage('avr');
                      sendNavigationInfo('visit_AVR_FLASHER');
                    }}>AVR-FASHER</Link>
                  <Link
                    to="/AT-MANAGER"
                    className="link"
                    onClick={() => {
                      changeActivePage('ATcmd');
                      sendNavigationInfo('visit_AT_MANAGER');
                    }}>AT-MANAGER</Link>
                  <Link
                    to="/profil"
                    className="link"
                    onClick={() => {
                      changeActivePage('profile');
                      sendNavigationInfo('visit_skills');
                    }}>Mes compétences</Link>
                  <Link
                    to="/contact"
                    className="link"
                    onClick={() => {
                      changeActivePage('contact');
                      sendNavigationInfo('visit_contactpage');
                    }}>Contact</Link>
              </div>
            </div>
        </header>
  )
}
