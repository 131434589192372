import React, {useEffect, useRef} from 'react';

export default function LiveChatWaitingMode(props) {
  const unread_message = useRef(false);
  useEffect(() =>{ 
    // unread_message.current = false;
  
    return;
  }, [])
  return (
    <div className="message_container">
          <div className="message_title_container">
            <div className="operator_image_container">
              <img
                src={process.env.PUBLIC_URL + '/images/livechat/operator_80x80.png'}
                rel="noreferrer"
                alt="Représentation de l'opérateur"/>
            </div>
            {props.com.communication && props.com.communication.map(mess => {
              if (mess.message_reading_at === 0
                && mess.user_emit_date === 0) {
                unread_message.current = true;
              }
            })}
            <p className="help_message">
                {unread_message.current === true
                  ? <span style={{color: 'magenta'}}>Nouveau message</span>
                  : 'Puis-je vous aider?'}
            </p>
          </div>
        </div>
  )
}
